import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Widgets from "./Widgets";
import { openSearch } from "../../state/modules/search";
import { getTotalQuantity, getUpdated } from "../../state/modules/bag";
import { isLoggedIn } from "../../state/modules/user";
import {
  hasSavedItems,
  getSavedItemsFromSDK
} from "../../state/modules/savedItems";
import { getViewport, addLastFocusElement } from "../../state/modules/layout";
import { getDropdownIsOpen } from "../../state/modules/dropdown";
import {
  getDefaultLanguage,
  getCountryCode,
  getStoreCode,
  getKeyStoreDataversion,
  getDefaultStoreUrl
} from "../../state/modules/regionalStore";
import { withCurrentEvent } from "@template/helpers/checkDateForEvent";

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openSearch,
      getSavedItemsFromSDK,
      addLastFocusElement
    },
    dispatch
  );

const mapStateToProps = state => ({
  countryCode: getCountryCode(state),
  defaultStoreUrl: getDefaultStoreUrl(state),
  hasSavedItems: hasSavedItems(state),
  isLoggedIn: isLoggedIn(state),
  isUpdated: getUpdated(state),
  keyStoreDataversion: getKeyStoreDataversion(state),
  lang: getDefaultLanguage(state),
  myAccountOpen: getDropdownIsOpen(state, { name: "myAccount" }),
  noOfBagItems: getTotalQuantity(state),
  storeCode: getStoreCode(state),
  viewport: getViewport(state),
  miniBagOpen: getDropdownIsOpen(state, { name: "miniBag" })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCurrentEvent(Widgets));
