import { PropTypes } from "prop-types";
import ResultList from "../ResultList";
import ResultItems from "../ResultItems";
import styles from "./index.css";

const RecentSearchesList = ({
  activeIndex,
  clearRecentSearches,
  focusSearchBox,
  formatTranslation,
  items,
  onSelect,
  term
}) => (
  <>
    <div className={styles.recentSearchesHeader}>
      <span id="recent-searches-heading">
        {formatTranslation("searchbar_recent_searches")}
      </span>
      <button
        className={styles.recentSearchesClearButton}
        data-testid="clear-recent-searches"
        aria-label={formatTranslation("searchbar_clear_recent_searches_label")}
        onClick={() => {
          clearRecentSearches();
          focusSearchBox();
        }}
      >
        {formatTranslation("searchbar_clear_recent_searches_text")}
      </button>
    </div>
    <ResultList>
      <div
        data-testid="recent-searches"
        role="group"
        aria-labelledby="recent-searches-heading"
      >
        <ResultItems
          activeIndex={activeIndex}
          items={items}
          onSelect={onSelect}
          term={term}
        />
      </div>
    </ResultList>
  </>
);

RecentSearchesList.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  clearRecentSearches: PropTypes.func.isRequired,
  focusSearchBox: PropTypes.func.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      searchTerm: PropTypes.string.isRequired,
      numberOfResults: PropTypes.number
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  term: PropTypes.string
};

export default RecentSearchesList;
