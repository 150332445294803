const affiliatedIdDomains = [
  "ahorroparaiso",
  "aol",
  "ask",
  "avg",
  "baidu",
  "biglobe",
  "bing",
  "bt",
  "cambridge",
  "centrum",
  "coccoc",
  "daum",
  "docomo",
  "dogpile",
  "duckduckgo",
  "earthlink",
  "ecosia",
  "excite",
  "fenrir-inc",
  "freebasics",
  "fresheye",
  "google",
  "googleadservices",
  "kvasir",
  "lycos",
  "m",
  "mail",
  "metakereso",
  "msn",
  "myway",
  "mywebsearch",
  "naver",
  "nifty",
  "quark",
  "rakuten",
  "rambler",
  "sapo",
  "search-results",
  "search",
  "searchalot",
  "seznam",
  "so-net",
  "so",
  "sogou",
  "soso",
  "sp",
  "startpagina",
  "suche",
  "t-online",
  "teoma",
  "thenexttask",
  "three",
  "urbandictionary",
  "virgilio",
  "walla",
  "yahoo",
  "yandex",
  "yz"
];

export default affiliatedIdDomains;
