import buildBreadcrumbs from "./buildBreadcrumbs";

import { NAV_ELEMENT_SELECTOR } from "./constants";

const setBreadcrumbs = breadcrumbs => {
  const navElement = document.querySelector(NAV_ELEMENT_SELECTOR);

  if (!navElement) {
    return;
  }

  const listElement = buildBreadcrumbs(breadcrumbs);

  navElement.innerHTML = "";

  if (listElement) {
    navElement.appendChild(listElement);
    return;
  }
};

export default setBreadcrumbs;
