import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  hideNotification,
  getInfoBannerNotification
} from "@template/state/modules/notifications";
import { getBagCheckoutPromptMVTStatus } from "@template/state/modules/infoBanner";

import InfoBanner from "./InfoBanner";

const mapStateToProps = state => ({
  showCTAS: getBagCheckoutPromptMVTStatus(state),
  infoBannerNotification: getInfoBannerNotification(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ hideNotification }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InfoBanner);
