import {
  asosGlobalNamespace,
  sdkGlobalNamespace
} from "../../template/constants";

export const initialise = () => {
  const {
    customer: { subscriptions, premierPropositionId }
  } = window[asosGlobalNamespace][sdkGlobalNamespace].identity;

  return {
    getVersion: premierPropositionId,
    get: subscriptions,
    version: "v2.3.0"
  };
};
