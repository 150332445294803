import Polyglot from "node-polyglot";

export const getPolyglot = (locale, phrases) =>
  new Polyglot({
    locale,
    phrases,
    onMissingKey: (key, args, locale) => {
      throw new Error(
        `No translations for "${key}" with arguments ${JSON.stringify(
          args
        )} in ${locale} locale.`
      );
    }
  });
