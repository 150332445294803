import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames/bind";

import { getContext, setCookies } from "@src/client/context";
import styles from "@template/components/CountrySelector/Form.css";
import { withTranslation } from "@template/components/translation";
import {
  trackPageInteraction,
  ANALYTICS_CONTEXT,
  ANALYTICS_INTERACTION_SAVED,
  ANALYTICS_POSITION_COUNTRY_SELECTOR
} from "@src/helpers/eventing/events";
import { LANGUAGE_MAP } from "@template/components/CountrySelector/constants";
import { getResolvedDeliveryCountry } from "@template/state/modules/welcomeMessage/getResolvedDeliveryCountry";
import {
  RSFT_DELIVER_TO_DELIVERY_COUNTRY_REFERRAL_NAME,
  RSFT_DELIVER_ELSEWHERE_REFERRAL_NAME
} from "@src/template/components/WelcomeMessage/RsftWelcomeMessage/constants";
import Icon from "../Icon";

const cx = classnames.bind(styles);

const cleanUrl = url => {
  if (!url) return url;
  return url.replace(/&amp;/gi, "&").replace(/&#x3D;/gi, "=");
};

class Form extends Component {
  static propTypes = {
    firstOpen: PropTypes.bool,
    countryCode: PropTypes.string.isRequired,
    storeCode: PropTypes.string.isRequired,
    siteId: PropTypes.number.isRequired,
    labels: PropTypes.shape({
      title: PropTypes.string.isRequired,
      countrySelect: PropTypes.string.isRequired,
      currencySelect: PropTypes.string.isRequired,
      update: PropTypes.string.isRequired,
      voucherErrorMessage: PropTypes.string,
      bagErrorMessage: PropTypes.string
    }),
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        countryCode: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      })
    ),
    currencies: PropTypes.arrayOf(
      PropTypes.shape({
        currencyId: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired
      })
    ),
    propositions: PropTypes.arrayOf(PropTypes.string),
    paymentProviders: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        logo: PropTypes.string.isRequired
      })
    ),
    noOfProductsInBag: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    onCountryChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    regionalStore: PropTypes.shape({
      countryCode: PropTypes.string.isRequired,
      storeCode: PropTypes.string.isRequired,
      siteId: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      language: PropTypes.string.isRequired,
      countryName: PropTypes.string.isRequired,
      sizeSchema: PropTypes.string.isRequired
    }),
    storeUrl: PropTypes.string,
    hasSetCurrencyError: PropTypes.bool,
    hasVoucherError: PropTypes.bool,
    formatTranslation: PropTypes.func.isRequired,
    sizeSchema: PropTypes.string,
    referrer: PropTypes.string,
    error: PropTypes.object
  };

  static defaultProps = {
    countries: [],
    currencies: [],
    propositions: [],
    paymentProviders: []
  };

  static contextTypes = {
    showWarning: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.getCloseButtonRef = this.getCloseButtonRef.bind(this);
    this.getSubmitButtonRef = this.getSubmitButtonRef.bind(this);
    this.getCountrySelectorRef = this.getCountrySelectorRef.bind(this);
    this.state = {
      currencyId: this.getCurrency(),
      isCurrencyUpdateManual: false
    };
  }

  handleCountryChange = e => {
    const { onCountryChange, countryCode, countries } = this.props;
    const country = countries.find(
      country => country.countryCode === e.target.value
    );

    /* istanbul ignore next */
    if (countryCode.toLowerCase() !== e.target.value.toLowerCase() && country) {
      onCountryChange(e.target.value, country.defaultLanguage);
    }
  };

  handleCurrencyChange = e => {
    if (this.state.currencyId !== Number(e.target.value)) {
      this.setState({
        currencyId: e.target.value,
        isCurrencyUpdateManual: true
      });
    }
  };

  handleCloseClick = async () => {
    const { onClose, referrer } = this.props;

    if (
      referrer === RSFT_DELIVER_TO_DELIVERY_COUNTRY_REFERRAL_NAME ||
      referrer === RSFT_DELIVER_ELSEWHERE_REFERRAL_NAME
    ) {
      await this.handlePageInteraction(
        ANALYTICS_CONTEXT.dismissCountrySelector
      );
    }

    onClose();
  };

  getAnalyticsContext = referrer => {
    if (referrer === RSFT_DELIVER_TO_DELIVERY_COUNTRY_REFERRAL_NAME) {
      return ANALYTICS_CONTEXT.deliverToDeliveryCountry;
    } else if (referrer === RSFT_DELIVER_ELSEWHERE_REFERRAL_NAME) {
      return ANALYTICS_CONTEXT.deliverElseWhere;
    }
    return ANALYTICS_CONTEXT.countrySelector;
  };

  getCountrySelectorCtaRef = referrer => {
    switch (referrer) {
      case RSFT_DELIVER_TO_DELIVERY_COUNTRY_REFERRAL_NAME: {
        return ANALYTICS_CONTEXT.deliverToDeliveryCountry;
      }
      case RSFT_DELIVER_ELSEWHERE_REFERRAL_NAME: {
        return ANALYTICS_CONTEXT.deliverElseWhere;
      }
      default: {
        return false;
      }
    }
  };

  getCurrentCurrency = () => {
    const { currencies } = this.props;
    const { currencyId } = this.state;

    const currentCurrencies = currencies
      .filter(currency => Number(currency.currencyId) === Number(currencyId))
      .map(currency => ({
        currencyId: currency.currencyId,
        currencyCode: currency.currency
      }));

    return currentCurrencies[0];
  };

  handlePageInteraction = async analyticsContext => {
    const {
      labels,
      siteId,
      referrer,
      regionalStore,
      countryCode,
      storeCode,
      lang
    } = this.props;
    const { isCurrencyUpdateManual } = this.state;

    const context = getContext();
    const geoCountry = context && context.getGeoCountry();
    const resolvedDeliveryCountry =
      referrer === RSFT_DELIVER_TO_DELIVERY_COUNTRY_REFERRAL_NAME ||
      referrer === RSFT_DELIVER_ELSEWHERE_REFERRAL_NAME
        ? await getResolvedDeliveryCountry(geoCountry)
        : undefined;
    const isoLanguage = lang.slice(0, 2).toLowerCase();
    const currentCurrency = this.getCurrentCurrency();

    trackPageInteraction({
      context: analyticsContext || this.getAnalyticsContext(referrer),
      interaction: ANALYTICS_INTERACTION_SAVED,
      elementText: labels.update,
      properties: {
        positionOnPage: ANALYTICS_POSITION_COUNTRY_SELECTOR,
        formFields: {
          country: {
            countryISOFrom: regionalStore.countryCode,
            countryISOTo: countryCode,
            storeFrom: regionalStore.storeCode,
            storeTo: storeCode,
            siteIDFrom: regionalStore.siteId,
            siteIDTo: siteId,
            isUpdateManual: regionalStore.countryCode !== countryCode
          },
          currency: {
            currencyISOFrom: regionalStore.currency,
            currencyISOTo: currentCurrency.currencyCode,
            isUpdateManual: isCurrencyUpdateManual
          },
          language: {
            languageISOFrom: regionalStore.language.slice(0, 2).toLowerCase(),
            languageISOTo: isoLanguage,
            languageTextFrom:
              LANGUAGE_MAP[regionalStore.language.slice(0, 2).toLowerCase()],
            languageTextTo: LANGUAGE_MAP[lang.slice(0, 2).toLowerCase()],
            isUpdateManual: false
          }
        },
        referrer,
        resolvedDeliveryCountry
      }
    });
  };

  handleSubmit = async e => {
    e.preventDefault();

    const {
      countryCode,
      lang,
      onSubmit,
      regionalStore,
      storeCode,
      storeUrl,
      countries,
      sizeSchema,
      onClose,
      referrer
    } = this.props;

    const country = countries.find(
      country => country.countryCode === countryCode
    );
    const affiliateId = getContext() && getContext().getAffiliateId();
    const isoLanguage = lang.slice(0, 2).toLowerCase();
    const ctaRef = this.getCountrySelectorCtaRef(referrer);
    const currentCurrency = this.getCurrentCurrency();

    await this.handlePageInteraction();

    const submitted = await onSubmit(
      countryCode,
      currentCurrency.currencyCode,
      cleanUrl(storeUrl) || `//${country.url}`,
      regionalStore.countryCode,
      affiliateId,
      ctaRef
    );

    if (submitted === false) return;

    setCookies({
      browseCountry: countryCode,
      browseCurrency: currentCurrency.currencyCode,
      browseCurrencyId: currentCurrency.currencyId,
      browseLanguage: isoLanguage,
      browseSizeSchema: sizeSchema,
      storeId: storeCode
    });

    onClose();
  };

  shouldDisplayWarning() {
    const {
      hasSetCurrencyError,
      noOfProductsInBag,
      countryCode,
      regionalStore
    } = this.props;

    return (
      !hasSetCurrencyError &&
      noOfProductsInBag > 0 &&
      countryCode !== regionalStore.countryCode
    );
  }

  componentDidMount() {
    setTimeout(() => this.context.showWarning(this.shouldDisplayWarning()), 0);
    if (this.props.firstOpen) {
      this.closeButton && this.closeButton.focus();
    } else {
      this.countrySelect && this.countrySelect.focus();
    }
  }

  getCurrency() {
    const { currencies, regionalStore, countryCode } = this.props;

    const currentCurrency = currencies.find(
      currency => currency.currency === regionalStore.currency
    );
    const defaultCurrency = currencies[0];

    return countryCode === regionalStore.countryCode && currentCurrency
      ? currentCurrency.currencyId
      : defaultCurrency.currencyId;
  }
  /* istanbul ignore next */
  getCloseButtonRef(c) {
    this.closeButton = c;
  }

  /* istanbul ignore next */
  getCountrySelectorRef(c) {
    this.countrySelect = c;
  }

  getSubmitButtonRef(c) {
    this.submitButton = c;
  }

  render() {
    const {
      countries,
      countryCode,
      currencies,
      error,
      formatTranslation,
      hasSetCurrencyError,
      hasVoucherError,
      labels,
      paymentProviders,
      propositions
    } = this.props;
    const { currencyId } = this.state;
    const displayWarning = this.shouldDisplayWarning();

    return (
      <section
        className={styles.container}
        data-testid="country-selector-form"
        role="dialog"
        aria-modal="true"
        aria-labelledby="country-title"
      >
        <button
          type="button"
          data-testid="close-button"
          onClick={this.handleCloseClick}
          ref={this.getCloseButtonRef}
          aria-label={formatTranslation("icon_close")}
          className={styles.closeButton}
        >
          <Icon icon={styles.closeIcon} />
        </button>
        <h1 id="country-title" className={styles.header}>
          {labels.title}
        </h1>
        <form className={styles.form} onSubmit={this.handleSubmit}>
          <div className={styles.field}>
            <label htmlFor="country" className={styles.label}>
              {labels.countrySelect}
            </label>
            <select
              id="country"
              className={styles.select}
              onChange={this.handleCountryChange}
              ref={this.getCountrySelectorRef}
              defaultValue={countryCode}
            >
              {countries.map(country => (
                <option key={country.countryCode} value={country.countryCode}>
                  {country.name}
                </option>
              ))}
            </select>
            <div className={styles.features}>
              {propositions.length > 0 && (
                <ol className={styles.propositions}>
                  {propositions.map((proposition, i) => (
                    <li key={i} className={styles.proposition}>
                      {proposition}
                    </li>
                  ))}
                </ol>
              )}
              {paymentProviders.length > 0 && (
                <ol className={styles.providers}>
                  {paymentProviders.map(provider => (
                    <li key={provider.name} className={styles.provider}>
                      <img src={provider.logo} alt={provider.label} />
                    </li>
                  ))}
                </ol>
              )}
            </div>
          </div>
          <div className={styles.field}>
            <label
              htmlFor="currency"
              className={cx([styles.label], {
                [styles.label__disabled]: hasSetCurrencyError
              })}
            >
              {labels.currencySelect}
            </label>
            {currencies.length > 1 ? (
              <select
                id="currency"
                className={styles.select}
                defaultValue={currencyId}
                onBlur={this.handleCurrencyChange}
                disabled={hasSetCurrencyError}
              >
                {currencies.map(currency => (
                  <option key={currency.currencyId} value={currency.currencyId}>
                    {currency.text}
                  </option>
                ))}
              </select>
            ) : (
              <span id="singleCurrency" className={styles.currency}>
                {currencies.length && currencies[0].text}
              </span>
            )}
          </div>
          {hasSetCurrencyError && hasVoucherError && (
            <div className={styles.error} data-testid="currency-message">
              <p>{labels.voucherErrorMessage}</p>
            </div>
          )}
          {hasSetCurrencyError && !hasVoucherError && (
            <div className={styles.error} data-testid="set-currency-message">
              <p>
                {error?.userMessage ||
                  formatTranslation("errors_something_doesnt_look_right")}
              </p>
            </div>
          )}
          {!hasSetCurrencyError && (
            <div className={styles.actions}>
              <button
                type="submit"
                className={styles.submitButton}
                data-testid="save-country-button"
                ref={this.getSubmitButtonRef}
              >
                {labels.update}
              </button>
            </div>
          )}
          {displayWarning && (
            <div className={styles.warning}>
              <p>{labels.bagErrorMessage}</p>
            </div>
          )}
        </form>
      </section>
    );
  }
}

export default withTranslation(Form);
