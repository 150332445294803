import { dedupeNlidQueryString } from "./dedupeNlidQueryString";

export const generateNlid = ({
  existingNlid,
  gender,
  section,
  container,
  label
}) => {
  const nlidTags = [gender, section, container, label];
  const newNlid = nlidTags
    .filter(tag => !!tag)
    .map(tag => tag.toLowerCase())
    .join("|");

  if (existingNlid) {
    return dedupeNlidQueryString([existingNlid, newNlid]);
  }

  return newNlid;
};
