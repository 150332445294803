import { getKeyedCookieValue } from "@src/client/context/cookies";
import {
  RSFT_WELCOME_MESSAGE_COOKIE_NAME,
  RSFT_USER_TOOK_ACTION_ON_WELCOME_MESSAGE_KEY
} from "@template/constants";

const getRsftWelcomeMessageAlreadyClosed = () => {
  const rsftWelcomeMessageClosed = getKeyedCookieValue(
    RSFT_WELCOME_MESSAGE_COOKIE_NAME,
    RSFT_USER_TOOK_ACTION_ON_WELCOME_MESSAGE_KEY
  );

  return rsftWelcomeMessageClosed === "true";
};

export default getRsftWelcomeMessageAlreadyClosed;
