// actions
export const UPDATE_VIEWPORT_CATEGORY = "update-viewport-category";
export const CLOSE_SIDE_PANEL = "CLOSE_SIDE_PANEL";
export const OPEN_SIDE_PANEL = "OPEN_SIDE_PANEL";
export const SET_GENDER = "SET_GENDER";
export const SET_GEO_COUNTRY = "SET_GEO_COUNTRY";
export const ADD_LAST_ACTIVE_ELEMENT = "ADD_LAST_ACTIVE_ELEMENT";
export const REMOVE_LAST_ACTIVE_ELEMENT = "REMOVE_LAST_ACTIVE_ELEMENT";
export const CLEAR_LAST_ACTIVE_ELEMENTS = "CLEAR_LAST_ACTIVE_ELEMENTS";
export const TOGGLE_MAIN_APP_ARIA_HIDDEN = "TOGGLE_MAIN_APP_ARIA_HIDDEN";

// viewports
export const SMALL_VIEWPORT = "small-viewport";
export const MEDIUM_VIEWPORT = "medium-viewport";
export const LARGE_VIEWPORT = "large-viewport";

// floor
export const MALE = "men";
export const FEMALE = "women";
export const DEFAULT = "default";

// other
export const SECURE = "secure";
