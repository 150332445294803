class DebounceTimer {
  constructor({ callback, debounceMs = 2000 } = { debounceMs: 2000 }) {
    this.debounceTimer;
    this.timerStopped = true;
    this.debounceMs = debounceMs;

    this.callback = () => {
      this.timerStopped = true;
      if (callback) callback();
    };
  }

  start() {
    this.timerStopped = false;
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(this.callback, this.debounceMs);
  }

  stop() {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = null;
    this.timerStopped = true;
  }

  isTimerRunning() {
    return !this.timerStopped;
  }
}

export default DebounceTimer;
