import stylesDeferred from "./deferred.css";

export const CONTACT_PREFERENCES = "ContactPreferences";
export const MY_ACCOUNT = "MyAccount";
export const MY_ORDERS = "MyOrders";
export const MY_RETURNS = "MyReturns";
export const RETURNS_INFORMATION = "ReturnsInformation";

export const MY_ACCOUNT_DEFAULT = stylesDeferred.MyAccount;

export const MY_ACCOUNT_CHRISTMAS_LOGGEDIN =
  stylesDeferred.MyAccountChristmasLoggedIn;

export const MY_ACCOUNT_CHRISTMAS_LOGGEDOUT =
  stylesDeferred.MyAccountChristmasLoggedOut;

export const MY_ACCOUNT_EASTER_LOGGEDIN =
  stylesDeferred.MyAccountEasterLoggedIn;

export const MY_ACCOUNT_EASTER_LOGGEDOUT =
  stylesDeferred.MyAccountEasterLoggedOut;

export const MY_ACCOUNT_HALLOWEEN_LOGGEDIN =
  stylesDeferred.MyAccountHalloweenLoggedIn;

export const MY_ACCOUNT_HALLOWEEN_LOGGEDOUT =
  stylesDeferred.MyAccountHalloweenLoggedOut;

export const MY_ACCOUNT_STPATRICKS_LOGGEDIN =
  stylesDeferred.MyAccountStPatricksLoggedIn;

export const MY_ACCOUNT_STPATRICKS_LOGGEDOUT =
  stylesDeferred.MyAccountStPatricksLoggedOut;
