import {
  getValueFromSessionStorage,
  removeValueFromSessionStorage,
  setSessionStorage
} from "@src/helpers/webStorage";

import {
  asosGlobalNamespace,
  siteChromeGlobalNamespace
} from "@src/template/constants";

const getSessionStorageKey = attributeName =>
  `${asosGlobalNamespace}.${siteChromeGlobalNamespace}.${attributeName}`;

export const noticeError = (error, customAttributes) => {
  if (!__SERVER__ && window.NREUM && window.NREUM.noticeError && error) {
    if (customAttributes) {
      window.NREUM.noticeError(error, customAttributes);
    } else {
      window.NREUM.noticeError(error);
    }
  }
};

export const addPageAction = (name, customAttributes) => {
  if (window.NREUM && window.NREUM.addPageAction && name) {
    if (customAttributes) {
      window.NREUM.addPageAction(name, customAttributes);
    } else {
      window.NREUM.addPageAction(name);
    }
  }
};

export const setCustomAttribute = (name, value) => {
  if (window.NREUM && window.NREUM.setCustomAttribute && name) {
    window.NREUM.setCustomAttribute(name, value);
  }
};

export const setCustomAttributeOnNextRequest = (
  attributeName,
  attributeValue
) => {
  setSessionStorage(getSessionStorageKey(attributeName), attributeValue);
};

export const finishSettingCustomAttributeFromPreviousRequest =
  attributeName => {
    const sessionStorageKey = getSessionStorageKey(attributeName);

    const valueFromSessionStorage =
      getValueFromSessionStorage(sessionStorageKey);

    if (valueFromSessionStorage) {
      removeValueFromSessionStorage(sessionStorageKey);
      setCustomAttribute(attributeName, valueFromSessionStorage);
    } else {
      setCustomAttribute(attributeName, "false");
    }
  };
