import PropTypes from "prop-types";
import classnames from "classnames";
import ListContainer from "./ListContainer";
import {
  navigationPropType,
  PRIMARY
} from "@template/state/modules/navigation";
import CarouselContainer from "./CarouselContainer";
import styles from "./index.css";

const cx = classnames.bind(this);

const renderTitle = (label, styleType, sideNavLevel) => {
  const HeaderTag = sideNavLevel === PRIMARY ? "h3" : "h4";

  return label && styleType !== "noTitle" ? (
    <HeaderTag className={cx(styles.title)}>{label}</HeaderTag>
  ) : null;
};

const sideNavigationContainers = {
  list: ListContainer,
  carousel: CarouselContainer
};

const SideNavigationContainer = ({
  setNavActiveItem,
  saveNavigationItem,
  item,
  sideNavLevel,
  addLastFocusElement
}) => {
  const isValidLayout = Object.prototype.hasOwnProperty.call(
    sideNavigationContainers,
    item.smallAndMediumLayout
  );

  const Container = isValidLayout
    ? sideNavigationContainers[item.smallAndMediumLayout]
    : ListContainer;

  if (!item.items || !item.items.length) {
    return null;
  }

  return (
    <li className={styles.listContainer}>
      {renderTitle(item.label, item.smallAndMediumStyleType, sideNavLevel)}
      <Container
        setNavActiveItem={setNavActiveItem}
        saveNavigationItem={saveNavigationItem}
        item={item}
        sideNavLevel={sideNavLevel}
        addLastFocusElement={addLastFocusElement}
      />
    </li>
  );
};

SideNavigationContainer.propTypes = {
  setNavActiveItem: PropTypes.func.isRequired,
  item: navigationPropType.isRequired,
  saveNavigationItem: PropTypes.func,
  addLastFocusElement: PropTypes.func,
  sideNavLevel: PropTypes.string
};

SideNavigationContainer.defaultProps = {
  saveNavigationItem: /* istanbul ignore next */ () => {}
};

export default SideNavigationContainer;
