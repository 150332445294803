import { connect } from "react-redux";
import HomeLogo from "./HomeLogo";
import { getCurrentGender } from "../../../state/modules/layout";

function mapStateToProps(state) {
  return {
    storeUrls: state.regionalStore.storeUrls,
    gender: getCurrentGender(state)
  };
}

export default connect(mapStateToProps)(HomeLogo);
