import PropTypes from "prop-types";
import Links from "./Links";
import styles from "./MainContent.css";
import MyAccountLinks from "../MyAccountLinks";
import SocialLinks from "./SocialLinks";
import PaymentOptions from "./PaymentOptions";
import Greeter from "../Greeter";
import { SMALL_VIEWPORT } from "../../state/modules/layout";
import withViewport from "../Viewport/withViewport";
import classnames from "classnames";

const MainContent = ({ items, isAccordion }) => (
  <div
    className={classnames({
      [styles.pageFooter]: !isAccordion
    })}
  >
    <div className={styles.iconBar}>
      <SocialLinks />
      <PaymentOptions />
    </div>
    <div className={styles.greeterWrapper}>
      <Greeter viewable={[SMALL_VIEWPORT]} />
    </div>
    <MyAccountLinks
      viewable={[SMALL_VIEWPORT]}
      queryParams={{ nlid: "nav footer" }}
    />
    <Links items={items} isAccordion={isAccordion} />
  </div>
);

MainContent.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  isAccordion: PropTypes.bool.isRequired
};

export default withViewport(MainContent);
