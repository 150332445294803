import { START_REMOVE_BAG_ITEM, START_CHECKOUT } from "./constants";

export const deleteBagItem = bagItem => {
  return dispatch => {
    dispatch({
      type: START_REMOVE_BAG_ITEM,
      item: bagItem
    });
  };
};

export const startCheckout = () => {
  return dispatch => {
    dispatch({
      type: START_CHECKOUT
    });
  };
};
