import PropTypes from "prop-types";

const ResultList = ({ children }) => (
  <ul id="search-results" aria-labelledby="chrome-search-label" role="listbox">
    {children}
  </ul>
);

ResultList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default ResultList;
