import { EventEmitter } from "fbemitter";

const logToConsole = args => {
  const query = new URLSearchParams(window.location.search);

  if (query && query.get("analytics") === "true") {
    console.log(...args);
  }
};

export class ExtendedEmitter extends EventEmitter {
  constructor() {
    super();
    this._wildcardListeners = [];
  }

  addWildcardListener(fn) {
    this._wildcardListeners.push(fn);
  }

  emit(...args) {
    logToConsole(args);

    this._wildcardListeners.forEach(fn => fn(...args));
    return super.emit(...args);
  }
}
