import { connect } from "react-redux";
import Footer from "./Footer";
import { footerNavigation } from "../../state/modules/navigation";

function mapStateToProps(state) {
  return {
    items: footerNavigation(state)
  };
}

export default connect(mapStateToProps)(Footer);
