import { useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { truncateTitle } from "../../../../../../helpers/formatting";
import { getBackgroundImageUrl } from "../../../../../helpers/navigationBackgroundImageResolver";
import { addAnalyticsDataToLink } from "../../../../../helpers/addAnalyticsDataToLink";
import LazyImage from "../../../../LazyImage";
import { BACKGROUND_LOADING_COLORS } from "../../../../LazyImage/constants";
import { fullPageNavigationDecider } from "@src/client/navigationBroker";
import { NavigationContext } from "../../../NavigationContext";
import styles from "./CarouselLink.css";

const cx = classnames.bind(this);

const CarouselLink = ({ item, className }) => {
  const closeNavigationMenu = useContext(NavigationContext);
  const href = addAnalyticsDataToLink(item);

  const handleClick = event => {
    fullPageNavigationDecider(href, event);

    closeNavigationMenu();
  };

  return (
    <li className={cx(styles.container, className)}>
      <LazyImage
        href={href}
        onClick={handleClick}
        className={styles.carouselLink}
        src={getBackgroundImageUrl(item.smallAndMediumImageUrl, 320)}
        tag={"a"}
        loadingColor={
          item.smallAndMediumStyleType === "light"
            ? BACKGROUND_LOADING_COLORS.darkGreyForMobileLightText
            : null
        }
      >
        <p
          className={cx(styles.label, {
            [styles.label__sale]: item.smallAndMediumStyleType === "sale",
            [styles.label__light]: item.smallAndMediumStyleType === "light"
          })}
        >
          <span className={styles[item.smallAndMediumStyleType]}>
            {truncateTitle(item)}
          </span>
          {item.subtitle && (
            <span
              className={cx(
                styles.subtitle,
                styles[item.smallAndMediumStyleType]
              )}
            >
              {item.subtitle.substring(0, 24)}
            </span>
          )}
        </p>
      </LazyImage>
    </li>
  );
};

CarouselLink.propTypes = {
  item: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default CarouselLink;
