import React from "react";
import PropTypes from "prop-types";

import CTASContent from "./CTASContent";
import DefaultContent from "./DefaultContent";
import InfoBannerControl from "./InfoBannerControl";

import {
  ERROR_TYPE,
  ALERT_TYPE,
  INFO_BANNER,
  SUCCESS_TYPE
} from "@template/state/modules/notifications";

import styles from "./index.css";

class InfoBanner extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hide: false
    };

    this.lastActiveElement;
    this.closeButton;
  }

  componentDidMount() {
    this.lastActiveElement = document.activeElement;
  }

  hasShownCallback = () => {
    this.hideNotification();
    this.setState({ hide: false });
  };

  focusClose = refToMountedElement => {
    this.closeButton = refToMountedElement;
    this.closeButton && this.closeButton.focus();
  };

  handleClose = () => {
    this.lastActiveElement && this.lastActiveElement.focus();
    this.setState({
      hide: true
    });
  };

  hideNotification = () => {
    this.props.hideNotification({ notificationType: INFO_BANNER });
  };

  getContent = () => {
    const { infoBannerNotification, showCTAS } = this.props;
    const { type, message } = infoBannerNotification;
    const { hide } = this.state;
    const hasError = type === ERROR_TYPE;
    const icon = type === ALERT_TYPE || hasError ? ALERT_TYPE : SUCCESS_TYPE;

    if (showCTAS) {
      return (
        <CTASContent
          hide={hide}
          onClick={this.handleClose}
          refCallback={this.focusClose}
          bagBannerShown={this.hideNotification}
        />
      );
    }

    return (
      <DefaultContent
        icon={icon}
        hide={hide}
        message={message}
        onClick={this.handleClose}
        refCallback={this.focusClose}
        dismissible={hasError}
        bagBannerShown={this.hideNotification}
      />
    );
  };

  render() {
    const { isVisible, type } = this.props.infoBannerNotification;
    const { hide } = this.state;
    const hasError = type === ERROR_TYPE;

    if (!type) return null;

    return (
      <InfoBannerControl
        cosmeticStyles={{
          background: styles[type]
        }}
        hasShownCallback={this.hasShownCallback}
        shouldShow={isVisible}
        forceHide={hide}
        shouldAutoHide={!hasError}
      >
        {this.getContent()}
      </InfoBannerControl>
    );
  }
}

InfoBanner.propTypes = {
  hideNotification: PropTypes.func.isRequired,
  infoBannerNotification: PropTypes.shape({
    isVisible: PropTypes.bool,
    message: PropTypes.string,
    type: PropTypes.string
  }).isRequired,
  showCTAS: PropTypes.bool
};

export default InfoBanner;
