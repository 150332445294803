import { noticeError } from "@src/helpers/monitoring";
import { processLink } from "./processLink";

export const addAnalyticsDataToLink = ({ link, gender, labelPath, label }) => {
  if (!link) {
    return null;
  }

  if (!gender) {
    return link;
  }

  try {
    if (labelPath.length === 6) {
      const [, , , section, container] = labelPath;
      if (section && container) {
        return processLink({
          link,
          section,
          container,
          label,
          gender
        });
      }
    }

    if (labelPath.length === 4) {
      const [, , section] = labelPath;

      if (section) {
        return processLink({
          link,
          section,
          gender,
          label
        });
      }
    }

    return link;
  } catch (error) {
    noticeError(error);

    return link;
  }
};
