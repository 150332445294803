import { useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames/bind";
import { keys, focusPrimaryNavigationFor } from "../../globalEventHandler";
import { FEMALE, MALE } from "../../state/modules/layout";
import {
  FLOOR_NAV_DATA_TESTID,
  FLOOR_NAV_MEN_FLOOR_ID,
  FLOOR_NAV_WOMEN_FLOOR_ID
} from "./constants";
import styles from "./FloorNavigation.css";

const cx = classnames.bind(styles);
const keysArray = [keys.left, keys.right];

const SmallFloorNavigation = ({
  formatTranslation,
  isMensFloorSelected,
  isWomensFloorSelected,
  setFloor
}) => {
  const womenFloorBtnRef = useRef();
  const menFloorBtnRef = useRef();

  const setFloorAndFocus = floor => {
    setFloor(floor);
    focusPrimaryNavigationFor(floor);
  };

  const handleWomensFloorClick = () => {
    setFloorAndFocus(FEMALE);
  };

  const handleMensFloorClick = () => {
    setFloorAndFocus(MALE);
  };

  const handleWomensFloorKeyDown = event => {
    if (keysArray.includes(event.code)) {
      menFloorBtnRef.current.focus();
    }
  };
  const handleMensFloorKeyDown = event => {
    if (keysArray.includes(event.code)) {
      womenFloorBtnRef.current.focus();
    }
  };

  return (
    <div
      className={cx(styles.container, styles.container__small_and_medium)}
      data-testid={FLOOR_NAV_DATA_TESTID}
      role="tablist"
      tabIndex="0"
    >
      <button
        aria-label={formatTranslation("header_womens_products_aria_label")}
        aria-selected={isWomensFloorSelected}
        className={cx(styles.button, {
          [styles.selected]: isWomensFloorSelected
        })}
        data-testid={FLOOR_NAV_WOMEN_FLOOR_ID}
        id={FLOOR_NAV_WOMEN_FLOOR_ID}
        onClick={handleWomensFloorClick}
        onKeyDown={handleWomensFloorKeyDown}
        role="tab"
        tabIndex={isWomensFloorSelected ? null : -1}
        ref={womenFloorBtnRef}
      >
        {formatTranslation("header_women_floor")}
      </button>

      <button
        aria-label={formatTranslation("header_mens_products_aria_label")}
        aria-selected={isMensFloorSelected}
        className={cx(styles.button, {
          [styles.selected]: isMensFloorSelected
        })}
        data-testid={FLOOR_NAV_MEN_FLOOR_ID}
        id={FLOOR_NAV_MEN_FLOOR_ID}
        onClick={handleMensFloorClick}
        onKeyDown={handleMensFloorKeyDown}
        role="tab"
        tabIndex={isMensFloorSelected ? null : -1}
        ref={menFloorBtnRef}
      >
        {formatTranslation("header_men_floor")}
      </button>
    </div>
  );
};

SmallFloorNavigation.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  isMensFloorSelected: PropTypes.bool.isRequired,
  isWomensFloorSelected: PropTypes.bool.isRequired,
  setFloor: PropTypes.func.isRequired
};

export default SmallFloorNavigation;
