import sdkInstanceProvider from "@src/client/sdk/sdkInstanceProvider";
import logger from "@src/helpers/logger";

const OPTIMIZELY_ADD_TO_BAG_KEY = "Add To Bag";
const BAG_SDK_ADD_TO_BAG_EVENT_KEY = "addToBag:started";
const BAG_SDK_BAG_UPDATED_EVENT_KEY = "bagUpdated:completed";
const BAG_SDK_ADD_SUBSCRIPTION_EVENT_TYPE = "addSubscription";
const SUBSCRIPTION_VARIANT_ID = "premier";

const getVariantIds = variantIdsOrItems =>
  variantIdsOrItems.map(variantIdOrItem =>
    typeof variantIdOrItem === "object" && variantIdOrItem !== null
      ? variantIdOrItem.variantId
      : variantIdOrItem
  );

const handleAddToBag =
  bagSdk =>
  async (_event, ...variantIdsOrItems) => {
    const variantIds = getVariantIds(variantIdsOrItems);

    let bagID;

    try {
      ({
        bag: { id: bagID }
      } = await bagSdk.getOrCreateBag());
    } catch (_) {
      logger.warn(
        "Attempted to track MVT Add To Bag event without bag ID -- discarding"
      );

      return;
    }

    const featuresSdk = await sdkInstanceProvider("features");

    variantIds.forEach(variantID => {
      featuresSdk.track(OPTIMIZELY_ADD_TO_BAG_KEY, null, null, {
        bagID,
        variantID
      });
    });
  };

const handleBagUpdated = bagUpdatedHandlers => (_event, type) =>
  bagUpdatedHandlers[type]?.();

const trackAddToBag = async () => {
  const bagSdk = await sdkInstanceProvider("bag");
  const bagSdkTarget = $(bagSdk);
  const addToBagHandler = handleAddToBag(bagSdk);
  const bagUpdatedHandlers = {
    [BAG_SDK_ADD_SUBSCRIPTION_EVENT_TYPE]: () =>
      addToBagHandler(null, SUBSCRIPTION_VARIANT_ID)
  };

  bagSdkTarget.on(BAG_SDK_ADD_TO_BAG_EVENT_KEY, addToBagHandler);
  bagSdkTarget.on(
    BAG_SDK_BAG_UPDATED_EVENT_KEY,
    handleBagUpdated(bagUpdatedHandlers)
  );
};

export default trackAddToBag;
