import { createSelector } from "reselect";

const getSavedItems = state => state.savedItems;

export const getItems = createSelector(getSavedItems, saved => saved.items);

export const hasSavedItems = createSelector(
  getItems,
  items => items.length > 0
);
