import { getEasterEventDateRangeCurrentYear } from "./getEasterEventDateRange";
import {
  VALENTINES,
  STPATRICKS,
  PRIDE,
  HALLOWEEN,
  CHRISTMAS,
  FIRSTDAY,
  LASTDAY
} from "./eventConstants";

export const eventsActiveDates = [
  { name: VALENTINES, [FIRSTDAY]: [1, 2], [LASTDAY]: [14, 2] },
  {
    name: getEasterEventDateRangeCurrentYear.name,
    [FIRSTDAY]: getEasterEventDateRangeCurrentYear[FIRSTDAY],
    [LASTDAY]: getEasterEventDateRangeCurrentYear[LASTDAY]
  },
  { name: STPATRICKS, [FIRSTDAY]: [17, 3], [[LASTDAY]]: [17, 3] },
  { name: PRIDE, [FIRSTDAY]: [1, 6], [LASTDAY]: [30, 6] },
  { name: HALLOWEEN, [FIRSTDAY]: [24, 10], [LASTDAY]: [1, 11] },
  { name: CHRISTMAS, [FIRSTDAY]: [13, 12], [LASTDAY]: [27, 12] }
];
