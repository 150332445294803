import { getViewport, SMALL_VIEWPORT } from "@template/state/modules/layout";
import {
  INFO_BANNER,
  SET_NOTIFICATION,
  getNotificationData
} from "@template/state/modules/notifications";

const subscribeToUpdatesSmallViewportCallback =
  ({ oldQuantity, dispatch, state }) =>
  ({
    bag: {
      summary: { totalQuantity: newQuantity }
    },
    sellerChanged,
    containsHotProduct
  }) => {
    if (newQuantity > oldQuantity && getViewport(state) === SMALL_VIEWPORT) {
      const { type, message } = getNotificationData({
        sellerChanged,
        containsHotProduct
      });

      dispatch({
        type: SET_NOTIFICATION,
        payload: {
          notificationType: INFO_BANNER,
          notificationData: {
            isVisible: true,
            message,
            type
          }
        }
      });
    }
  };

export default subscribeToUpdatesSmallViewportCallback;
