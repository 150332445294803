import {
  OPTANON_ALERT_BOX_CLOSED_COOKIE,
  OPTANON_CONSENT_COOKIE
} from "./constants";
import getCookies from "./getCookies";
import handleOptanonAlertBoxClosedCookies from "./handleOptanonAlertBoxClosedCookies";
import handleOptanonConsentCookies from "./handleOptanonConsentCookies";

const handleCookies = () => {
  const optanonConsentCookies = getCookies(OPTANON_CONSENT_COOKIE);
  const optanonAlertBoxClosedCookies = getCookies(
    OPTANON_ALERT_BOX_CLOSED_COOKIE
  );

  if (optanonAlertBoxClosedCookies.length === 2) {
    handleOptanonAlertBoxClosedCookies(optanonAlertBoxClosedCookies);
  }

  if (optanonConsentCookies.length) {
    handleOptanonConsentCookies(
      optanonConsentCookies,
      optanonAlertBoxClosedCookies
    );
  }
};
export default handleCookies;
