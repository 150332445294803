import { useEffect, useRef } from "react";
import { toggleMainAppAriaHidden } from "@template/state/modules/layout";
import { useDispatch } from "react-redux";

export const useAriaHidingMainApp = isOpen => {
  const isOpenRef = useRef(isOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpenRef.current !== isOpen) {
      isOpenRef.current = isOpen;
      dispatch(toggleMainAppAriaHidden(isOpen));
    }
  }, [isOpen, dispatch]);
};
