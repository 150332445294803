import {
  LARGE_VIEWPORT,
  MEDIUM_VIEWPORT,
  viewportPropType
} from "@template/state/modules/layout/";
import MiscBar from "@template/components/MiscBar";
import Header from "@template/components/Header";
import LargeNavigation from "@template/components/Navigation/LargeNavigation";
import UnsupportedBrowser from "@template/components/UnsupportedBrowserMessage";
import LayoutManager from "@template/components/LayoutManager";
import NotQuiteStickyHeader from "@template/components/NotQuiteStickyHeader";
import GlobalBanner from "@template/components/GlobalBanner";
import SkipToContent from "@template/components/Header/SkipToContent";
import NativeAppBanner from "../NativeAppBanner";

const HtmlHeader = ({ viewport }) => (
  <header>
    <LayoutManager />
    <SkipToContent />
    <UnsupportedBrowser />
    <MiscBar viewable={[LARGE_VIEWPORT]} />
    <NotQuiteStickyHeader>
      <Header />
      {viewport === LARGE_VIEWPORT && <LargeNavigation />}
    </NotQuiteStickyHeader>
    <GlobalBanner viewable={[MEDIUM_VIEWPORT, LARGE_VIEWPORT]} />
    <NativeAppBanner />
  </header>
);

HtmlHeader.propTypes = {
  viewport: viewportPropType
};

export default HtmlHeader;
