import { eventsActiveDates } from "@template/helpers/eventActiveDateRanges";
import { FIRSTDAY, LASTDAY } from "@template/helpers/eventConstants";
import { handleEventDate } from "./handleEventDate";

export const getTodaysDate = () => handleEventDate(new Date());

const checkDateRange = ([currentDay, currentMonth], activeEventDateRange) => {
  const dateRangeTypes = Object.keys(activeEventDateRange);
  const controlDay = dateType => activeEventDateRange[dateType][0];
  const controlMonth = dateType => activeEventDateRange[dateType][1];

  const lessMonthCheck = dateType =>
    currentMonth < controlMonth(dateType) ||
    (currentMonth === controlMonth(dateType) &&
      currentDay <= controlDay(dateType));
  const moreMonthCheck = dateType =>
    currentMonth > controlMonth(dateType) ||
    (currentMonth === controlMonth(dateType) &&
      currentDay >= controlDay(dateType));

  return dateRangeTypes.every(dateType => {
    return (
      (dateType === FIRSTDAY && moreMonthCheck(dateType)) ||
      (dateType === LASTDAY && lessMonthCheck(dateType))
    );
  });
};

export const checkDateForEvent = (todaysDate, events) => {
  const eventsActiveDateRanges = events ? events : eventsActiveDates;
  const currentEvent = eventsActiveDateRanges.find(event =>
    checkDateRange(todaysDate, {
      [FIRSTDAY]: event[FIRSTDAY],
      [LASTDAY]: event[LASTDAY]
    })
  );
  return currentEvent?.name || null;
};

export function withCurrentEvent(BaseComponent) {
  const currentEvent = checkDateForEvent(getTodaysDate());

  return function ComponentWithCurrentEvent(props) {
    return <BaseComponent {...props} currentEvent={currentEvent} />;
  };
}
