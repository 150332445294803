import {
  asosGlobalNamespace,
  siteChromeGlobalNamespace
} from "@src/template/constants";

let cookieData;

export const getInitialCookieData = () => {
  if (!cookieData) {
    cookieData =
      window[asosGlobalNamespace][siteChromeGlobalNamespace]._cookieData;
    delete window[asosGlobalNamespace][siteChromeGlobalNamespace]._cookieData;
  }

  return cookieData;
};
