import {
  getBagSummary,
  subscribeToUpdates,
  subscribeToError
} from "@src/client/sdk/bag";

import subscribeToUpdatesSmallViewportCallback from "./subscribeToUpdatesSmallViewportCallback";
import subscribeToErrorCallback from "./subscribeToErrorCallback";
import { validPath } from "./validPath";

const infoBannerMiddleware = ({ getState, dispatch }) => {
  if (validPath()) {
    getBagSummary().then(summary => {
      const oldQuantity = summary?.totalQuantity;

      if (typeof oldQuantity === "number") {
        subscribeToUpdates(
          subscribeToUpdatesSmallViewportCallback({
            oldQuantity,
            dispatch,
            state: getState()
          })
        );
      }
    });

    subscribeToError(
      subscribeToErrorCallback({
        dispatch,
        state: getState()
      })
    );
  }

  return next => action => {
    next(action);
  };
};

export default infoBannerMiddleware;
