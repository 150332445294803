import { Component } from "react";
import PropTypes from "prop-types";
import getRsftWelcomeMessageCappedPageCount from "./getRsftWelcomeMessageCappedPageCount";
import setRsftWelcomeMessageCappedPageCount from "./setRsftWelcomeMessageCappedPageCount";
import getUserRsftWelcomeMessageMetadata from "./getUserRsftWelcomeMessageMetadata";
import RsftWelcomeMessage from "./RsftWelcomeMessage";

const SHOW_RSFT_WELCOME_MESSAGE_PAGE_COUNT_THRESHOLD = 2;

class WelcomeMessageContainer extends Component {
  async componentDidMount() {
    const {
      minorCountries,
      platform,
      requestWelcomeMessage,
      disableWelcomeMessage
    } = this.props;

    const currentCappedPageCount = getRsftWelcomeMessageCappedPageCount();

    if (
      currentCappedPageCount < SHOW_RSFT_WELCOME_MESSAGE_PAGE_COUNT_THRESHOLD
    ) {
      setRsftWelcomeMessageCappedPageCount(currentCappedPageCount + 1);
      disableWelcomeMessage();

      return;
    }

    const { shouldRequest, resolvedDeliveryCountry } =
      await getUserRsftWelcomeMessageMetadata(minorCountries);

    if (!shouldRequest) {
      disableWelcomeMessage();

      return;
    }

    requestWelcomeMessage(platform, resolvedDeliveryCountry);
  }

  render() {
    if (!this.props.showWelcomeMessage) {
      return null;
    }

    return <RsftWelcomeMessage />;
  }
}

WelcomeMessageContainer.propTypes = {
  minorCountries: PropTypes.object.isRequired,
  platform: PropTypes.string.isRequired,
  requestWelcomeMessage: PropTypes.func.isRequired,
  showWelcomeMessage: PropTypes.bool.isRequired,
  disableWelcomeMessage: PropTypes.func.isRequired
};

export default WelcomeMessageContainer;
