import { Component } from "react";
import { renderToStaticMarkup } from "react-dom/server";

const withTokenGroup = (name, data, WrappedComponent) =>
  class TokenGroup extends Component {
    render() {
      const template = JSON.stringify({
        Data: data,
        Template: renderToStaticMarkup(<WrappedComponent {...this.props} />)
      }).replace(/\\n/g, "\\r\\n");

      return (
        <div
          dangerouslySetInnerHTML={{ __html: `<!--%[${name}]${template}%-->` }}
        />
      );
    }
  };

export default withTokenGroup;
