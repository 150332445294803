import LargePrimaryNavigation from "../LargePrimaryNavigation";
import { LARGE_VIEWPORT, FEMALE, MALE } from "../../../state/modules/layout";
import withViewport from "../../Viewport/withViewport";
import styles from "./index.css";

const LargeNavigation = () => (
  <div className={styles.largeNavigation}>
    <LargePrimaryNavigation forGender={FEMALE} viewable={[LARGE_VIEWPORT]} />
    <LargePrimaryNavigation forGender={MALE} viewable={[LARGE_VIEWPORT]} />
  </div>
);

export default withViewport(LargeNavigation);
