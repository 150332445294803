import {
  asosGlobalNamespace,
  siteChromeGlobalNamespace
} from "../template/constants";

const navigationEvent = "Asos.SiteChrome.Navigation";

let navigationTarget;

const bootstrapNavigationBroker = () => {
  // Fallback for Safari < 14.
  // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/EventTarget#browser_compatibility
  try {
    navigationTarget = new EventTarget();
  } catch (_) {
    navigationTarget = new FileReader();
  }

  window[asosGlobalNamespace][siteChromeGlobalNamespace].navigation = {
    addEventListener(callback, options) {
      return navigationTarget.addEventListener(
        navigationEvent,
        callback,
        options
      );
    },
    removeEventListener(callback, options) {
      return navigationTarget.removeEventListener(
        navigationEvent,
        callback,
        options
      );
    }
  };
};

const triggerNavigation = href => {
  if (!navigationTarget) {
    return;
  }

  const isFullPageNavigationCancelled = !navigationTarget.dispatchEvent(
    new CustomEvent(navigationEvent, {
      cancelable: true,
      detail: { href }
    })
  );

  return isFullPageNavigationCancelled;
};

const fullPageNavigationDecider = (href, event) => {
  if (triggerNavigation(href)) {
    event.preventDefault();
  }
};

export {
  bootstrapNavigationBroker,
  triggerNavigation,
  fullPageNavigationDecider
};
