import { setCustomAttribute, addPageAction } from "@src/helpers/monitoring";

import { getInitialCookieData } from "./initialCookieState";

const cookiesOfInterest = [
  "browseCountry",
  "browseCurrency",
  "keyStoreDataversion"
];

const cookieDataKeys = {
  initialLength: "cookie.length.initial",
  lengthDiff: "cookie.length.diff",
  initialCount: "cookie.count.initial",
  countDiff: "cookie.count.diff",
  missingCookies: "cookie.missingCookies"
};

const adjustDiff = (cookieData, diff, diffPropertyName) => {
  if (typeof cookieData[diffPropertyName] === "undefined") {
    cookieData[diffPropertyName] = diff;
  } else {
    cookieData[diffPropertyName] = Math.min(cookieData[diffPropertyName], diff);
  }
};

const getMissingCookies = () =>
  cookiesOfInterest.filter(x => !window.document.cookie.includes(x + "="));

const getUpdatedCookieData = () => {
  const cookieData = getInitialCookieData();

  const lengthDiff = window.document.cookie.length - cookieData.initialLength;

  const countDiff =
    window.document.cookie.split(";").filter(Boolean).length -
    cookieData.initialCount;

  adjustDiff(cookieData, lengthDiff, "lengthDiff");
  adjustDiff(cookieData, countDiff, "countDiff");

  const missingCookies = getMissingCookies();

  if (missingCookies.length > 0) {
    cookieData.missingCookies = missingCookies;
  }

  return cookieData;
};

export const logCookieDataAsCustomAttributes = () => {
  const cookieData = getUpdatedCookieData();

  setCustomAttribute(cookieDataKeys.initialLength, cookieData.initialLength);
  setCustomAttribute(cookieDataKeys.lengthDiff, cookieData.lengthDiff);
  setCustomAttribute(cookieDataKeys.initialCount, cookieData.initialCount);
  setCustomAttribute(cookieDataKeys.countDiff, cookieData.countDiff);
  setCustomAttribute(cookieDataKeys.missingCookies, cookieData.missingCookies);
};

export const logCookieDataAsPageAction = () => {
  const cookieData = getUpdatedCookieData();

  addPageAction("cookieData", {
    [cookieDataKeys.initialLength]: cookieData.initialLength,
    [cookieDataKeys.lengthDiff]: cookieData.lengthDiff,
    [cookieDataKeys.initialCount]: cookieData.initialCount,
    [cookieDataKeys.countDiff]: cookieData.countDiff,
    [cookieDataKeys.missingCookies]: cookieData.missingCookies
  });
};
