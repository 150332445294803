import { connect } from "react-redux";
import { getViewport } from "../../../../../state/modules/layout";
import ContentLoadingOverlay from "./ContentLoadingOverlay";

function mapStateToProps(state) {
  return {
    viewport: getViewport(state)
  };
}

export default connect(mapStateToProps)(ContentLoadingOverlay);
