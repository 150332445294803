/* These ought to be env vars */
export const SEARCH_API_SUGGESTIONS_PATH = "/product/search/v2/suggestions";
export const COUNTRY_API_SELECTOR_PATH =
  "/web/countrymetadata/v1/countrySelector";
export const COUNTRY_API_WELCOME_MESSAGE_PATH =
  "/web/countrymetadata/v1/welcomeMessage";
export const PAYMENT_PROVIDER_API_PATH =
  "/web/countrymetadata/v1/paymentProviders";
export const ASOS_HEADERS_PLAT = "Web";
export const ASOS_HEADERS_NAME = "Asos.Web.Sitechrome";
export const X_SITE_ORIGIN = "x-site-origin";
export const CUSTOMER_PROFILE_API_PATH = "/customer/profile/v2/customers";
export const DELIVERY_OPTIONS_API_PATH =
  "/commerce/deliveryoptions/v2/countries";
