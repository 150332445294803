import { NAV_ELEMENT_SELECTOR } from "./constants";
import styles from "@template/components/Breadcrumbs/index.css";

const getListElement = () => {
  const navElement = document.querySelector(NAV_ELEMENT_SELECTOR);

  let listElement = navElement.querySelector("ol");

  if (!listElement) {
    listElement = document.createElement("ol");
    listElement.classList.add(styles.list);

    navElement.appendChild(listElement);
  }

  return listElement;
};

export default getListElement;
