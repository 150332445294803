import ReactDOM from "react-dom";
import { PropTypes } from "prop-types";
import clientConfig from "@template/clientConfig";
import { getAnnouncement } from "./getAnnouncement";
import styles from "./index.css";

const ItemAnnouncer = ({ activeIndex, items }) =>
  clientConfig.isServer()
    ? null
    : ReactDOM.createPortal(
        <div
          className={styles.log}
          role="log"
          aria-atomic="true"
          aria-live="polite"
          aria-relevant="additions text"
        >
          {getAnnouncement({ activeIndex, items })}
        </div>,
        document.body
      );

ItemAnnouncer.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      searchTerm: PropTypes.string.isRequired,
      numberOfResults: PropTypes.number
    })
  ).isRequired
};

export default ItemAnnouncer;
