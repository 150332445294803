import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setNavActiveItem,
  getNavActiveItem,
  clearNavActiveItem,
  primaryNavigationItems
} from "@template/state/modules/navigation";
import LargePrimaryNavigation from "./LargePrimaryNavigation";
import { getFeaturesLoaded } from "@template/state/modules/features";

const mapStateToProps = (state, { forGender }) => ({
  currentGender: state.layout.gender,
  items: primaryNavigationItems(state, forGender),
  largeNavActiveItemId: getNavActiveItem(state),
  featuresLoaded: getFeaturesLoaded(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setNavActiveItem, clearNavActiveItem }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LargePrimaryNavigation);
