import { trackLink } from "../trackLink";
import {
  ADOBE_EVENTS_KEY,
  ADOBE_EVENT_CART_REMOVALS,
  ADOBE_EVENT_CART_VIEW,
  ADOBE_PRODUCTS_KEY,
  ADOBE_LINK_NAME_MINI_BAG_PRODUCT_DELETE
} from "../constants";

export const trackMiniBagProductDeleteClick = ({
  context,
  itemId,
  priceValue,
  quantity
}) => {
  if (context === ADOBE_LINK_NAME_MINI_BAG_PRODUCT_DELETE) {
    const data = {
      [ADOBE_PRODUCTS_KEY]: `;${itemId};${quantity};${priceValue}`,
      [ADOBE_EVENTS_KEY]: `${ADOBE_EVENT_CART_REMOVALS},${ADOBE_EVENT_CART_VIEW}`
    };

    trackLink({ name: ADOBE_LINK_NAME_MINI_BAG_PRODUCT_DELETE, data });
  }
};
