const parser = new DOMParser();

const doc = input => parser.parseFromString(input, "text/html");

const htmlDecode = input => doc(input).documentElement.textContent;

export const decodeBreadcrumbs = breadcrumbs =>
  breadcrumbs.map(crumb => {
    const crumbKeys = Object.keys(crumb);
    const decodedCrumb = {};

    crumbKeys.forEach(key => {
      if (key === "Url" && !crumb[key]) return;
      decodedCrumb[key] = htmlDecode(crumb[key]);
    });

    return decodedCrumb;
  });
