import { connect } from "react-redux";
import { getMinibagNotification } from "@src/template/state/modules/notifications";

import ReservationMessage from "./ReservationMessage";

const mapStateToProps = state => ({
  notification: getMinibagNotification(state)
});

export default connect(mapStateToProps)(ReservationMessage);
