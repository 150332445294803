import styles from "./index.css";
import PropTypes from "prop-types";

const CountryFlag = ({ imageUrl, name }) => (
  <img src={imageUrl} alt={name} className={styles.countryFlag} />
);

CountryFlag.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default CountryFlag;
