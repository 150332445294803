import { handleSdkLoad } from "./handleSdkLoad";
import getOneTrustScriptId from "./getOneTrustScriptId";
import handleCookies from "./handleCookies";

export const loadSdk = async store => {
  window.OptanonWrapper = handleSdkLoad(store);
  handleCookies();

  const oneTrustScript = document.getElementById("cookie-banner-script");

  const { cdnSrc, assetsSrc, domainScriptId, domainScriptIdExperiment } =
    oneTrustScript.dataset;

  const scriptId = await getOneTrustScriptId(
    domainScriptId,
    domainScriptIdExperiment
  );

  oneTrustScript.setAttribute("data-domain-script", scriptId);

  const oneTrustSrc = window.location.search.includes("use-onetrust-cdn-link")
    ? cdnSrc
    : assetsSrc;
  oneTrustScript.setAttribute("src", oneTrustSrc);

  delete oneTrustScript.dataset.cdnSrc;
  delete oneTrustScript.dataset.assetsSrc;
  delete oneTrustScript.dataset.domainScriptIdExperiment;
  delete oneTrustScript.dataset.domainScriptId;
};
