import { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withTranslation } from "@template/components/translation";
import {
  navigationPropType,
  SECONDARY
} from "@template/state/modules/navigation";
import {
  genderPropType,
  FEMALE,
  DEFAULT
} from "@template/state/modules/layout";
import styles from "./index.css";
import SideNavigationContainer from "../SideNavigationContainer";
import {
  ProgressIndicator,
  SIZE_MEDIUM
} from "@asosteam/asos-web-component-library-progress-indicator";

const cx = classnames.bind(styles);

const SideSecondaryNavigationPanel = ({
  formatTranslation,
  handleClose,
  items,
  isVisible,
  label
}) => {
  const secondaryNav = useRef(null);

  useEffect(() => {
    if (isVisible) {
      secondaryNav.current.scrollTop = 0;
    }
  }, [isVisible]);

  return (
    <div
      className={cx(styles.item, {
        [styles.item__visible]: isVisible
      })}
      aria-hidden={isVisible ? null : true}
    >
      <section className={styles.header}>
        <button
          className={styles.closeButton}
          aria-label={formatTranslation("accessibility_label_back")}
          onClick={handleClose}
        />
        <h3 className={styles.sectionTitle}>{label}</h3>
      </section>
      <nav className={styles.secondary} ref={secondaryNav}>
        {items.length ? (
          <ul>
            {items.map(item => (
              <SideNavigationContainer
                key={item.id}
                item={item}
                sideNavLevel={SECONDARY}
              />
            ))}
          </ul>
        ) : (
          <div className={styles.progressIndicatorContainer}>
            <ProgressIndicator
              size={SIZE_MEDIUM}
              progressMessage={formatTranslation("accessibility_label_loading")}
            />
          </div>
        )}
      </nav>
    </div>
  );
};

SideSecondaryNavigationPanel.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(navigationPropType).isRequired,
  label: PropTypes.string.isRequired
};

const SideSecondaryNavigation = ({
  clearNavActiveItem,
  currentGender,
  forGender,
  formatTranslation,
  items: groupNavigationItems,
  navActiveItem
}) => {
  const [navAnimatingItem, setNavAnimatingItem] = useState(null);

  const handleTransitionEnd = ({ target, currentTarget }) => {
    if (target === currentTarget) {
      setNavAnimatingItem(null);
    }
  };

  const handleClose = () => {
    setNavAnimatingItem(navActiveItem);
    clearNavActiveItem();
  };

  const isActive =
    !!navActiveItem &&
    (forGender === currentGender ||
      (forGender === FEMALE && currentGender === DEFAULT));

  return (
    <div
      aria-hidden={isActive ? null : true}
      className={cx(styles.container, {
        [styles.container__active]: isActive,
        [styles.container__visible]: isActive || !!navAnimatingItem
      })}
      data-testid="secondary-nav"
      onTransitionEnd={handleTransitionEnd}
    >
      {groupNavigationItems.map(({ items }) =>
        items.map(({ id, label, items }) => (
          <SideSecondaryNavigationPanel
            formatTranslation={formatTranslation}
            handleClose={handleClose}
            key={id}
            isVisible={
              (isActive && navActiveItem === id) || navAnimatingItem === id
            }
            items={items}
            label={label}
          />
        ))
      )}
    </div>
  );
};

SideSecondaryNavigation.propTypes = {
  clearNavActiveItem: PropTypes.func.isRequired,
  currentGender: genderPropType.isRequired,
  forGender: genderPropType.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(navigationPropType).isRequired,
  navActiveItem: PropTypes.string
};

export default withTranslation(SideSecondaryNavigation);
