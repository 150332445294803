import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {
  trackPageInteraction,
  ANALYTICS_INTERACTION_EXPAND,
  ANALYTICS_POSITION_FOOTER,
  ANALYTICS_CONTEXT
} from "@src/helpers/eventing/events";
import { withParams } from "@src/helpers/queryString";
import styles from "./index.css";
import { SMALL_VIEWPORT } from "@template/state/modules/layout";

const cx = classnames.bind(styles);

class LinkGroup extends Component {
  static propTypes = {
    countryCode: PropTypes.string.isRequired,
    isAccordion: PropTypes.bool.isRequired,
    groupItem: PropTypes.shape({
      label: PropTypes.string.isRequired,
      items: PropTypes.array
    }),
    keyStoreDataversion: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    storeCode: PropTypes.string.isRequired,
    viewport: PropTypes.string.isRequired
  };

  state = {
    hideFocus: false
  };

  handleOpen = e => {
    const { isAccordion } = this.props;

    if (!isAccordion) {
      e.preventDefault();
    } else if (!this.details.open) {
      trackPageInteraction({
        context: ANALYTICS_CONTEXT.footerExpandable,
        elementText: this.props.groupItem.label,
        interaction: ANALYTICS_INTERACTION_EXPAND,
        properties: {
          positionOnPage: ANALYTICS_POSITION_FOOTER
        }
      });
    }
  };

  handleMouseDown = () => {
    this.setState({ hideFocus: true });
  };

  handleBlur = () => {
    this.setState({ hideFocus: false });
  };

  render() {
    const {
      countryCode: country,
      groupItem,
      isAccordion,
      keyStoreDataversion,
      lang,
      storeCode: store,
      viewport
    } = this.props;

    const withAdditionalParams = (link, alias) => {
      if (alias === "track_my_order") {
        return withParams(link, {
          country,
          keyStoreDataversion,
          lang,
          store
        });
      }

      if (link.includes("marketplace")) {
        return withParams(link, {
          ctaref: "Global footer"
        });
      }

      return link;
    };

    const isSmallViewport = viewport === SMALL_VIEWPORT;
    const ContainerTag = isSmallViewport ? "details" : "section";
    const TitleTag = isSmallViewport ? "summary" : "h3";

    return (
      <ContainerTag
        className={styles.container}
        open={!isAccordion}
        data-testid="footer-links"
        ref={e => (this.details = e)}
      >
        <TitleTag
          className={cx(styles.header, {
            [styles.header__focused]: this.state.hideFocus
          })}
          onMouseDown={this.handleMouseDown}
          onClick={this.handleOpen}
          onKeyPress={this.handleOpen}
          onBlur={this.handleBlur}
          role={isAccordion ? "button" : null}
        >
          {groupItem.label}
        </TitleTag>
        <ul>
          {groupItem.items.map(({ alias, id, label, link, linkType }) => {
            const target = linkType === "external" ? "_blank" : null;
            return (
              <li key={id}>
                <a
                  className={styles.link}
                  href={withAdditionalParams(link, alias)}
                  target={target}
                >
                  {label}
                </a>
              </li>
            );
          })}
        </ul>
      </ContainerTag>
    );
  }
}

export default LinkGroup;
