import { noticeError } from "@src/helpers/monitoring";

const newRelicCustomAttributeKey = "localStorageCacheError";

export const removeEntriesByCacheKeyPrefix = (
  cacheKeyPrefix,
  cacheKeyToExclude
) => {
  const keysToRemove = [];

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key !== cacheKeyToExclude && key.startsWith(cacheKeyPrefix)) {
      keysToRemove.push(key);
    }
  }

  keysToRemove.forEach(x => localStorage.removeItem(x));
};

export const getOrSetInLocalStorageWithExpiryAsync = async (
  options,
  ...funcParams
) => {
  const {
    cacheKeyPrefix,
    returnCachedOnDelegateFailure,
    removeOtherCacheItems,
    localStorageValueDelegate
  } = options;

  const cacheKey = `${cacheKeyPrefix}:${JSON.stringify(funcParams)}`;
  const cachedItem = JSON.parse(localStorage.getItem(cacheKey) || null);
  const now = new Date().getTime();

  if (!cachedItem || cachedItem.expiry < now) {
    let value, timeToLiveMs;

    try {
      ({ value, timeToLiveMs } = await localStorageValueDelegate(
        ...funcParams
      ));
    } catch (error) {
      if (returnCachedOnDelegateFailure && cachedItem) {
        const warnMessage = `Refresh of LocalStorage cache entry '${cacheKey}' failed due to '${
          error.message
        }'. Returning stale version from ${new Date(
          cachedItem.expiry
        ).toISOString()}`;

        noticeError(error, { [newRelicCustomAttributeKey]: warnMessage });

        return cachedItem.value;
      }
      const errorMessage = `Attempt to populate LocalStorage cache entry '${cacheKey}' failed. Error Message: '${error.message}'`;

      noticeError(error, { [newRelicCustomAttributeKey]: errorMessage });
      throw error;
    }

    const refreshedItem = {
      value: value,
      expiry: now + timeToLiveMs
    };

    localStorage.setItem(cacheKey, JSON.stringify(refreshedItem));
    removeOtherCacheItems &&
      removeEntriesByCacheKeyPrefix(cacheKeyPrefix, cacheKey);

    return value;
  }

  return cachedItem.value;
};
