const userAgent = typeof navigator === "undefined" ? "" : navigator.userAgent;

export function isIos() {
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
}

export function isAndroid() {
  return /android/i.test(userAgent);
}

export function isAndroidChrome() {
  return isAndroid() && /Chrome/.test(userAgent);
}
