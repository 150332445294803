import PropTypes from "prop-types";

import { withTranslation } from "@template/components/translation";
import Notification from "../../Notification";

const ReservationMessage = ({ notification, formatTranslation }) => {
  const { isVisible, message, type } = notification;

  return isVisible ? (
    <Notification type={type}>{formatTranslation(message)}</Notification>
  ) : null;
};

ReservationMessage.propTypes = {
  notification: PropTypes.shape({
    isVisible: PropTypes.bool,
    message: PropTypes.string,
    type: PropTypes.string
  }).isRequired,
  formatTranslation: PropTypes.func.isRequired
};

export default withTranslation(ReservationMessage);
