import clientConfig from "../../template/clientConfig";
import { isAndroid, isIos } from "../../helpers/deviceDetection";

export const resolveLink = (href, alias) => {
  if (alias === "app_download" && typeof navigator !== "undefined") {
    if (isAndroid()) {
      return clientConfig.get().APP_DOWNLOAD_PLAY_STORE_URI;
    }
    if (isIos()) {
      return clientConfig.get().APP_DOWNLOAD_APP_STORE_URI;
    }
  }
  return href;
};
