import { EventEmitter } from "fbemitter";

class ContentTargetingEvents {
  constructor() {
    this.cache = null;
    this.emitter = new EventEmitter();
  }

  subscribe(callback) {
    this.emitter.addListener("targetedContent", callback);
    if (this.cache) {
      this.emitter.emit("targetedContent", this.cache);
    }
  }

  emit(data) {
    this.emitter.emit("targetedContent", data);
    this.cache = data;
  }
}
export default new ContentTargetingEvents();
