import {
  clientEnvPrefix,
  asosGlobalNamespace,
  siteChromeGlobalNamespace,
  siteChromeClientConfig
} from "./constants";
import { isWindowDefined } from "@src/helpers/url";

const getClientConfigFromWindow = () => {
  if (
    isWindowDefined() &&
    window[asosGlobalNamespace] !== undefined &&
    window[asosGlobalNamespace][siteChromeGlobalNamespace] !== undefined &&
    window[asosGlobalNamespace][siteChromeGlobalNamespace][
      siteChromeClientConfig
    ] !== undefined
  ) {
    return window[asosGlobalNamespace][siteChromeGlobalNamespace][
      siteChromeClientConfig
    ];
  }
  return false;
};

const isLocal = () =>
  isWindowDefined() && window.location.hostname.includes("local");

const isServer = () => !isWindowDefined();

const getXSiteOrigin = () => {
  const cookies = document.cookie.split(";");
  const xSite = cookies.find(cookie =>
    cookie.toLowerCase().includes("x-site-origin=")
  );

  if (!xSite) return;

  return xSite.split("=")[1];
};

const getClientConfig = () =>
  getClientConfigFromWindow() ||
  Object.keys(process.env)
    .filter(key => key.startsWith(clientEnvPrefix))
    .reduce((acc, key) => {
      acc[key.replace(clientEnvPrefix, "")] = process.env[key];
      return acc;
    }, {});

export default {
  get: getClientConfig,
  isLocal,
  isServer,
  getXSiteOrigin
};
