export function getParamValue(urlParam) {
  const params = window.location.search.substring(1).split("&");
  const browseUrl =
    params.find(p =>
      p.toLowerCase().startsWith(`${urlParam.toLowerCase()}=`)
    ) || "";

  return browseUrl.split("=")[1];
}

export function getCurrentUrl() {
  const { host, pathname } = window.location;
  return host + pathname;
}

export function isProtocolHttps(url) {
  const delimiter = "://";
  return url.split(delimiter)[0] === "https";
}

export const getDomain = url => {
  try {
    const { hostname } = new URL(url);
    const parts = hostname.split(".");
    return parts.length === 2 ? parts[0] : parts[1];
  } catch (_) {
    return;
  }
};

export const stripSubdomain = hostname =>
  hostname.split(".").slice(1).join(".");

/* istanbul ignore next */
export const isWindowDefined = () => {
  return typeof window !== "undefined";
};
