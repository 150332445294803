import cx from "classnames";
import PropTypes from "prop-types";

import { withTranslation } from "@template/components/translation";
import IconButton from "@template/components/IconButton";
import { tabIndex } from "@src/helpers/accessibility";
import Icon from "@template/components/Icon";

import styles from "./index.css";

const DefaultContent = ({
  icon,
  hide,
  message,
  onClick,
  refCallback,
  dismissible,
  formatTranslation
}) => (
  <div className={styles.content}>
    <Icon icon={cx(styles.icon, styles[icon])} />
    <p role="status" className={styles.message}>
      {formatTranslation(message)}
    </p>
    {dismissible && (
      <IconButton
        aria-label={formatTranslation("icon_close")}
        icon={styles.close}
        size="small"
        className={styles.closeContainer}
        tabIndex={tabIndex(!hide)}
        onClick={onClick}
        refCallback={refCallback}
      />
    )}
  </div>
);

DefaultContent.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  dismissible: PropTypes.bool.isRequired,
  refCallback: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  hide: PropTypes.bool.isRequired
};

export default withTranslation(DefaultContent);
