import PropTypes from "prop-types";
import { withTranslation } from "@template/components/translation";
import {
  trackPageInteraction,
  ANALYTICS_INTERACTION_CLICK,
  ANALYTICS_POSITION_FOOTER,
  ANALYTICS_CONTEXT
} from "@src/helpers/eventing/events";
import styles from "./index.css";
import stylesDeferred from "./deferred.css";
import cx from "classnames";

const SocialLinks = ({ links }) =>
  links ? (
    <ul className={styles.socialLinks} data-testid="social-links-bar">
      {Object.keys(links).map((link, index) => (
        <li className={cx(styles.socialLink, stylesDeferred[link])} key={index}>
          <a
            className={styles.link}
            href={links[link]}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => trackClick(link)}
            data-testid="social-link"
          >
            <span className={styles.srOnly}>{link}</span>
          </a>
        </li>
      ))}
    </ul>
  ) : null;

function trackClick(name) {
  trackPageInteraction({
    context: ANALYTICS_CONTEXT.social,
    interaction: ANALYTICS_INTERACTION_CLICK,
    elementText: name.toLowerCase(),
    properties: {
      positionOnPage: ANALYTICS_POSITION_FOOTER
    }
  });
}

SocialLinks.propTypes = {
  links: PropTypes.object,
  formatTranslation: PropTypes.func.isRequired
};

export default withTranslation(SocialLinks);
