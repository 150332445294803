import { connect } from "react-redux";
import {
  requestWelcomeMessage,
  disableWelcomeMessage
} from "@template/state/modules/welcomeMessage/actions";
import { getPlatform } from "@template/state/modules/layout";
import { getMinorCountries } from "@template/state/modules/regionalStore";
import { isWelcomeMessageShown } from "@template/state/modules/welcomeMessage";
import WelcomeMessageContainer from "./WelcomeMessageContainer";

const mapStateToProps = state => ({
  minorCountries: getMinorCountries(state),
  platform: getPlatform(state),
  showWelcomeMessage: isWelcomeMessageShown(state)
});

const mapDispatchToProps = {
  requestWelcomeMessage,
  disableWelcomeMessage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WelcomeMessageContainer);
