import { getHasOneTrustLoaded } from "./getHasOneTrustLoaded";
import { getConsentCategoryIds } from "./getConsentCategoryIds";
import { trimAndSortConsentCategoryIds } from "./trimAndSortConsentCategoryIds";
import { getIsCookieBannerShowing } from "./getIsCookieBannerShowing";
import { getGeolocationData } from "./getGeolocationData";
import { getConsentModel } from "./getConsentModel";
import { setCustomAttribute } from "@src/helpers/monitoring";

const oneTrustPrefix = "oneTrust.";

export const logOneTrustData = () => {
  const oneTrustHasLoaded = getHasOneTrustLoaded();
  setCustomAttribute(oneTrustPrefix + "codeExecuted", oneTrustHasLoaded);

  if (oneTrustHasLoaded) {
    const consents = trimAndSortConsentCategoryIds(getConsentCategoryIds());
    setCustomAttribute(oneTrustPrefix + "cookieConsentCategoryIds", consents);

    const geolocationData = getGeolocationData();
    setCustomAttribute(oneTrustPrefix + "geoCountry", geolocationData.country);
    setCustomAttribute(oneTrustPrefix + "geoState", geolocationData.state);

    setCustomAttribute(oneTrustPrefix + "consentModel", getConsentModel());
    setCustomAttribute(
      oneTrustPrefix + "cookieBannerShown",
      getIsCookieBannerShowing()
    );
  }
};
