import { getVisitData } from "./getVisitData";

export function getCoreAttributes({
  geoCountry,
  browseCountry,
  browseLanguage: language,
  storeId: browseStore,
  platform
}) {
  const { notBouncedVisit, visitPageCount } = getVisitData();

  return {
    geoCountry,
    browseCountry,
    language,
    browseStore,
    platform,
    ...(typeof visitPageCount === "number" ? { visitPageCount } : {}),
    ...(notBouncedVisit === true ? { notBouncedVisit } : {})
  };
}
