import { combineReducers } from "redux";
import { reduce as layout } from "./layout";
import { reduce as navigation } from "./navigation";
import { reduce as regionalStore } from "./regionalStore";
import { reduce as search } from "./search";
import { reduce as savedItems } from "./savedItems";
import { reduce as subscriptions } from "./subscriptions";
import { reduce as audience } from "./audience";
import { reduce as bag } from "./bag";
import { reduce as countrySelector } from "./countrySelector";
import buildVersion from "./buildVersion";
import version from "./version";
import { reduce as welcomeMessage } from "./welcomeMessage";
import { reduce as user } from "./user";
import { reduce as dropdown } from "./dropdown";
import { reduce as features } from "./features";
import { reduce as contentTargeting } from "./contentTargeting";
import { reduce as notifications } from "./notifications";

export default combineReducers({
  audience,
  layout,
  navigation,
  regionalStore,
  search,
  savedItems,
  subscriptions,
  bag,
  countrySelector,
  version,
  buildVersion,
  welcomeMessage,
  user,
  dropdown,
  features,
  contentTargeting,
  notifications
});
