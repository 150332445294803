/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "@template/components/translation";
import { navigationPropType } from "@template/state/modules/navigation";
import {
  viewportPropType,
  LARGE_VIEWPORT
} from "@template/state/modules/layout";
import { getDesktopTemplate } from "@template/helpers/navigationTemplatesResolver";
import { globalEventHandler, events, keys } from "@template/globalEventHandler";
import {
  ProgressIndicator,
  SIZE_MEDIUM
} from "@asosteam/asos-web-component-library-progress-indicator";
import styles from "./index.css";
import classnames from "classnames/bind";

const cx = classnames.bind(styles);

const inferTemplateComponent = item => {
  const { largeScreenTemplateId } = item;
  const TemplateComponent = getDesktopTemplate(largeScreenTemplateId);
  return <TemplateComponent item={item} />;
};

const LargeSecondaryNavigation = ({
  item: primaryNavItem,
  item: { items: secondaryNavItems },
  item: { id },
  clearNavActiveItem,
  formatTranslation,
  hasClickedInside,
  viewport,
  largeNavActiveItemId
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const hasNavItems = !!secondaryNavItems.length;

  useEffect(() => {
    setIsLoading(!hasNavItems);
  }, [hasNavItems]);

  useEffect(() => {
    const globalClickListener = globalEventHandler.addListener(
      events.click,
      event => {
        if (isVisible() && !hasClickedInside(event)) {
          clearNavActiveItem();
        }
      }
    );
    return () => {
      globalClickListener.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isVisible = () => {
    return id === largeNavActiveItemId && viewport === LARGE_VIEWPORT;
  };

  const isLeftKey = event => event.key === keys.left;

  const isShiftTabKey = event => event.key === keys.tab && event.shiftKey;

  const isTabKey = event => event.key === keys.tab && !event.shiftKey;

  const isEscKey = event => event.key === keys.escape;

  const isFirstItemFocused = () => !!document.activeElement.dataset.first;

  const isLastItemFocused = () => !!document.activeElement.dataset.last;

  const handleKeyDown = event => {
    if (isVisible()) {
      if (isEscKey(event)) {
        clearNavActiveItem();
        document.querySelector(`button[data-id="${id}"]`).focus();
      } else if (
        isFirstItemFocused() &&
        (isShiftTabKey(event) || isLeftKey(event))
      ) {
        event.preventDefault();
        event.stopPropagation();
        clearNavActiveItem();
        document.querySelector(`button[data-id="${id}"]`).focus();
      } else if (isLastItemFocused() && isTabKey(event)) {
        clearNavActiveItem();
      }
    }
  };

  const renderHeading = (label, id, isHidden) => {
    if (!label) return null;

    return (
      <h2
        id={id}
        className={cx(styles.columnHeader, {
          [styles.hidden]: isHidden
        })}
      >
        <span>{label}</span>
      </h2>
    );
  };

  const renderContainer = item => {
    const { id, label, largeScreenStyleType, largeScreenColumnSpan } = item;
    const isHiddenTitle = largeScreenStyleType === "noTitle";

    return (
      <div
        key={id}
        className={cx(
          styles.column,
          styles[`colspan-${largeScreenColumnSpan || 1}`]
        )}
      >
        {renderHeading(label, id, isHiddenTitle)}
        {inferTemplateComponent(item)}
      </div>
    );
  };

  return (
    <div
      className={cx(styles.container, {
        [styles.visible]: isVisible()
      })}
      data-testid="secondarynav-container"
    >
      <div
        className={styles.backdrop}
        onClick={clearNavActiveItem}
        onMouseMove={clearNavActiveItem}
        aria-hidden="true"
        data-testid="backdrop"
      />
      <div className={styles.flyoutContainer}>
        <div
          className={styles.flyout}
          data-id={primaryNavItem.id}
          data-testid="secondarynav-flyout"
          onKeyDown={handleKeyDown}
        >
          {isLoading ? (
            <div className={styles.progressIndicatorContainer}>
              <ProgressIndicator
                size={SIZE_MEDIUM}
                progressMessage={formatTranslation(
                  "accessibility_label_loading"
                )}
              />
            </div>
          ) : (
            secondaryNavItems.map((secondaryNavItem, index) => {
              return renderContainer({
                ...secondaryNavItem,
                isFirstColumn: index === 0,
                isLastColumn: index === secondaryNavItems.length - 1
              });
            })
          )}
        </div>
      </div>
    </div>
  );
};

LargeSecondaryNavigation.propTypes = {
  item: navigationPropType.isRequired,
  largeNavActiveItemId: PropTypes.string,
  setNavActiveItem: PropTypes.func.isRequired,
  clearNavActiveItem: PropTypes.func.isRequired,
  buttonElement: PropTypes.object,
  viewport: viewportPropType.isRequired,
  hasClickedInside: PropTypes.func.isRequired,
  testIsLoaded: PropTypes.bool,
  formatTranslation: PropTypes.func.isRequired
};

export default withTranslation(LargeSecondaryNavigation);
