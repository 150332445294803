import { adobeSdkProvider } from "@src/client/analytics/adobe/adobeSdkProvider";
import { ADOBE_VISITS_PAGE_LOAD_COUNT_KEY } from "@src/client/analytics/adobe/constants";

const getVisitPageCount = () => {
  const adobeSdk = adobeSdkProvider();

  return parseInt(adobeSdk[ADOBE_VISITS_PAGE_LOAD_COUNT_KEY]);
};

export const getVisitData = () => {
  const visitPageCount = getVisitPageCount();

  if (isNaN(visitPageCount)) {
    return {};
  }

  return {
    visitPageCount,
    notBouncedVisit: visitPageCount > 1
  };
};
