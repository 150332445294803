import { ApiError } from "./apiError";
import logger from "../helpers/logger";

export const handleErrors = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const {
    config: { url },
    data,
    status,
    statusText
  } = response;

  const error = new ApiError(response.statusText, {
    status,
    statusText,
    url,
    responseText: JSON.stringify(data)
  });

  logger.error("Error response from API:", error);

  throw error;
};
