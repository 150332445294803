import { connect } from "react-redux";
import LegalBar from "./LegalBar";

import {
  getDefaultStoreUrl,
  getCountryCode,
  getStoreCode
} from "@src/template/state/modules/regionalStore";
import { getViewport } from "@src/template/state/modules/layout";

function mapStateToProps(state) {
  return {
    defaultStoreUrl: getDefaultStoreUrl(state),
    countryCode: getCountryCode(state),
    viewport: getViewport(state),
    storeCode: getStoreCode(state)
  };
}

export default connect(mapStateToProps)(LegalBar);
