import {
  FETCHING_TARGETED_CONTENT,
  FETCHED_TARGETED_CONTENT,
  GET_STORED_TARGETED_CONTENT,
  API_ERROR,
  STATUS_IDLE,
  STATUS_FETCHING,
  STATUS_SUCCESS,
  STATUS_ERROR
} from "./constants";

export { requestTargetedContent } from "./actions";

export { getGlobalBannerContent, getContentTargetingStatus } from "./selectors";

const initialState = {
  status: STATUS_IDLE,
  content: []
};

export const reduce = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_TARGETED_CONTENT:
      return {
        status: STATUS_FETCHING,
        content: []
      };
    case FETCHED_TARGETED_CONTENT:
      return {
        status: STATUS_SUCCESS,
        content: action.payload
      };
    case GET_STORED_TARGETED_CONTENT:
      return {
        status: STATUS_SUCCESS,
        content: action.payload
      };
    case API_ERROR:
      return {
        status: STATUS_ERROR,
        content: []
      };
    default:
      return state;
  }
};
