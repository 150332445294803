import {
  SET_NOTIFICATION,
  HIDE_NOTIFICATION,
  NOTIFICATION_TYPES
} from "./constants";
export { setNotification } from "./actions";

export { hideNotification } from "./actions";
export {
  MINIBAG,
  ALERT_TYPE,
  ERROR_TYPE,
  INFO_BANNER,
  SUCCESS_TYPE,
  ERROR_MESSAGE,
  PROMOTION_TYPE,
  DEFAULT_MESSAGE,
  SET_NOTIFICATION,
  HIDE_NOTIFICATION,
  HOT_PRODUCT_MESSAGE,
  SELLER_CHANGED_MESSAGE
} from "./constants";
export { getMinibagNotification, getInfoBannerNotification } from "./selectors";
export { getNotificationData } from "./getNotificationData";

const initNotifications = () => {
  const serializedNotifications = {};

  NOTIFICATION_TYPES.forEach(notification => {
    serializedNotifications[notification] = {
      isVisible: false
    };
  });

  return serializedNotifications;
};

const initialState = initNotifications();

export const reduce = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_NOTIFICATION: {
      return {
        ...state,
        [payload.notificationType]: {
          ...payload.notificationData
        }
      };
    }

    case HIDE_NOTIFICATION: {
      return {
        ...state,
        [payload.notificationType]: {
          ...state[payload.notificationType],
          isVisible: false
        }
      };
    }

    default:
      return state;
  }
};
