import { noticeError } from "@src/helpers/monitoring";

export const getGeolocationData = () => {
  const geolocationData = window.OneTrust.getGeolocationData();

  if (!geolocationData.country) {
    const geolocationDataMissingError = new Error(
      "OneTrust Geolocation Data is missing"
    );

    // Have to throw the error to get stack on Safari: https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/noticeerror-browser-agent-api/#browser-limits
    try {
      throw geolocationDataMissingError;
    } catch (err) {
      noticeError(err);
    }
  }

  return geolocationData;
};
