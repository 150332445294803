import { useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { getBackgroundImageUrl } from "../../../../helpers/navigationBackgroundImageResolver";
import LazyImage from "../../../LazyImage";
import { BACKGROUND_LOADING_COLORS } from "../../../LazyImage/constants";
import { NavigationContext } from "../../NavigationContext";
import { triggerNavigation } from "@src/client/navigationBroker";
import styles from "./index.css";

const cx = classnames.bind(styles);

const FlatItem = ({ item, saveNavigationItem }) => {
  const closeNavigationMenu = useContext(NavigationContext);
  const href = item.link;

  const handleClick = event => {
    const isFullPageNavigationCancelled = triggerNavigation(href);
    if (isFullPageNavigationCancelled) {
      event.preventDefault();
    }

    closeNavigationMenu();
  };

  return (
    <li className={cx(styles.container)}>
      <LazyImage
        href={href}
        onClick={handleClick}
        data-navid={item.id}
        className={cx(styles.menuItem, styles.flatMenuItem)}
        ref={saveNavigationItem}
        data-testid="menu-item"
        src={getBackgroundImageUrl(item.smallAndMediumImageUrl, 320)}
        tag={"a"}
        loadingColor={
          item.smallAndMediumStyleType === "light"
            ? BACKGROUND_LOADING_COLORS.darkGreyForMobileLightText
            : null
        }
      >
        <p
          className={cx(styles.label, {
            [styles.label__light]: item.smallAndMediumStyleType === "light"
          })}
        >
          {item.label.substring(0, 24)}
        </p>
      </LazyImage>
    </li>
  );
};

FlatItem.propTypes = {
  item: PropTypes.any,
  saveNavigationItem: PropTypes.func.isRequired
};

export default FlatItem;
