import { createSelector } from "reselect";
import {
  mapLargePrimaryNavigation,
  mapSidePrimaryNavigationForViewport
} from "./helpers/mapNavigationTree";
import { getViewport, MALE, FEMALE } from "../layout";
import { WOMEN_FLOOR_ALIAS, MEN_FLOOR_ALIAS } from "./constants";

const selectFemaleNavigationTree = state => {
  const femaleNav = state.navigation.tree.find(
    item => item.alias === WOMEN_FLOOR_ALIAS
  );
  if (femaleNav && femaleNav.items) {
    return femaleNav.items;
  }

  return [];
};

const selectMaleNavigationTree = state => {
  const maleNav = state.navigation.tree.find(
    item => item.alias === MEN_FLOOR_ALIAS
  );
  if (maleNav && maleNav.items) {
    return maleNav.items;
  }

  return [];
};

const selectNavActiveItem = state => state.navigation.activeNavItem;
const selectFooterLinks = state => state.navigation.footer;

export const femaleLargePrimaryNavigationItems = createSelector(
  selectFemaleNavigationTree,
  mapLargePrimaryNavigation
);

export const maleLargePrimaryNavigationItems = createSelector(
  selectMaleNavigationTree,
  mapLargePrimaryNavigation
);

export const femaleSidePrimaryNavigationItems = createSelector(
  selectFemaleNavigationTree,
  getViewport,
  mapSidePrimaryNavigationForViewport
);

export const maleSidePrimaryNavigationItems = createSelector(
  selectMaleNavigationTree,
  getViewport,
  mapSidePrimaryNavigationForViewport
);

export const getNavActiveItem = createSelector(selectNavActiveItem, id => id);

export const footerNavigation = createSelector(
  selectFooterLinks,
  footer => footer
);

export const primaryNavigationItems = createSelector(
  [
    maleLargePrimaryNavigationItems,
    femaleLargePrimaryNavigationItems,
    (_, forGender) => forGender
  ],
  (
    maleLargePrimaryNavigationItems,
    femaleLargePrimaryNavigationItems,
    forGender
  ) => {
    if (forGender === MALE) {
      return maleLargePrimaryNavigationItems;
    }
    if (forGender === FEMALE) {
      return femaleLargePrimaryNavigationItems;
    }

    return [];
  }
);
