import affiliateIdDomains from "./affiliateIdDomains";
import { getDomain, getParamValue } from "../../helpers/url";
import {
  setAffiliateId,
  getAffiliateId
} from "@asosteam/asos-web-store-presentation-node";

const FLOOR_PAGE = "17295";
const NON_FLOOR_PAGE = "17294";
const setByAsos = value => [FLOOR_PAGE, NON_FLOOR_PAGE, null].includes(value);

const referrerIsAnAffiliate = () => {
  const { referrer } = window.document;
  const domain = getDomain(referrer);
  return affiliateIdDomains.includes(domain);
};

export const handleAffiliateId = floorPageUrls => {
  const affiliateIdQueryParam =
    getParamValue("affid") || getParamValue("xaffid");

  if (affiliateIdQueryParam) {
    setAffiliateId(affiliateIdQueryParam);
    return affiliateIdQueryParam;
  }

  const affiliateIdFromCookie = getAffiliateId();
  if (!referrerIsAnAffiliate()) return affiliateIdFromCookie;

  if (affiliateIdFromCookie !== undefined && !setByAsos(affiliateIdFromCookie))
    return affiliateIdFromCookie;

  const { origin, pathname } = new URL(window.location.href);
  const baseUrl = `${origin}${pathname}`;
  const onFloorPage = floorPageUrls.includes(baseUrl);
  const newAffiliateId = onFloorPage ? FLOOR_PAGE : NON_FLOOR_PAGE;
  setAffiliateId(newAffiliateId);
  return newAffiliateId;
};
