import { useEffect } from "react";
import {
  asosGlobalNamespace,
  siteChromeGlobalNamespace
} from "@template/constants";
import { MODALS_NAMESPACE_KEY } from "./constants";

export const usePublicApiManagement = method => {
  useEffect(() => {
    const asos_siteChrome =
      window[asosGlobalNamespace][siteChromeGlobalNamespace];

    asos_siteChrome[MODALS_NAMESPACE_KEY] = {
      ...asos_siteChrome[MODALS_NAMESPACE_KEY],
      ...method
    };

    return () => {
      const [methodName] = Object.keys(method);
      delete asos_siteChrome[MODALS_NAMESPACE_KEY][methodName];
    };
  }, [method]);
};
