import { ExtendedEmitter } from "./extendedEmitter";
import { stripSubdomain } from "../url";
import { getCookieValue, removeCookie } from "../../client/context/cookies";
import {
  TRACKING_ONETRUST_INTERACTION,
  TRACKING_MINIBAG_INTERACTION
} from "../../client/analytics/adobe/constants";

export const eventBus = new ExtendedEmitter();

const emit = (eventName, details) => eventBus.emit(eventName, details);

const IDENTITY_STATUS_COOKIE = "asos-la676";

export const trackPageLoads = () => {
  // Read cookie asos-la676 which is set by Identity
  // This will give us information if the user has signed in/registered
  // We need to remove the cookie so we don't trigger the analytics event twice
  const identityCookieValue = getCookieValue(IDENTITY_STATUS_COOKIE);

  if (identityCookieValue) {
    emit("tracking.identity.userStatus", identityCookieValue);

    const domain = `.${stripSubdomain(window.location.hostname)}`;
    removeCookie(IDENTITY_STATUS_COOKIE, domain);
  }
};

trackPageLoads();

export const ANALYTICS_INTERACTION_CLICK = "click";
export const ANALYTICS_INTERACTION_EXPAND = "expand";
export const ANALYTICS_INTERACTION_HOVER = "hover";
export const ANALYTICS_INTERACTION_SAVED = "saved";
export const ANALYTICS_INTERACTION_CLOSE = "close";
export const ANALYTICS_INTERACTION_NAVIGATE = "navigate";
export const ANALYTICS_INTERACTION_IMPRESSION = "impression";
export const ANALYTICS_POSITION_HEADER = "header";
export const ANALYTICS_POSITION_FOOTER = "footer";
export const ANALYTICS_POSITION_COUNTRY_SELECTOR = "country selector modal";
export const ANALYTICS_CONTEXT = {
  navHover: "nav hover",
  openNav: "open nav section",
  social: "social icon",
  openCountryModal: "open country modal",
  footerExpandable: "concertina",
  countrySelector: "country preferences",
  minibagDeleteItem: "mini bag product delete",
  welcomeMessage: "welcome message",
  deliverToDeliveryCountry: "deliver to delivery country",
  deliverElseWhere: "deliver elsewhere",
  dismissCountrySelector: "dismiss country selector"
};

export const getGender = str => {
  if (str === "men" || str === "women") return str;
  if (str === "MW") return "men";
  if (str === "WW") return "women";
};

export const trackPageInteraction = details =>
  emit("tracking.navigation.pageInteraction", details);

export const trackMiniBagProductDelete = details =>
  emit(TRACKING_MINIBAG_INTERACTION, details);

export const trackNativeAppBannerGetApp = platform =>
  emit("tracking.appbanner.installTheApp", platform);

export const trackOneTrustEvents = details =>
  emit(TRACKING_ONETRUST_INTERACTION, details);
