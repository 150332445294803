import { toggleMainAppAriaHidden } from "@template/state/modules/layout";
import BlockScroll from "@src/helpers/blockScroll";

const blockScroll = new BlockScroll();

export const observePreferenceCenterMutations = store => {
  const oneTrustPreferenceCenter = document.getElementById("onetrust-pc-sdk");
  const oneTrustPreferenceCenterObserver = new MutationObserver(() => {
    const isOpeningPc =
      oneTrustPreferenceCenter.classList.contains("ot-slide-in-left");
    const isClosingPc =
      oneTrustPreferenceCenter.classList.contains("ot-slide-out-left");

    if (isClosingPc) {
      blockScroll.disable();
      store.dispatch(toggleMainAppAriaHidden(false));
    }

    if (isOpeningPc) {
      blockScroll.enable();
      store.dispatch(toggleMainAppAriaHidden(true));
    }
  });

  oneTrustPreferenceCenterObserver.observe(oneTrustPreferenceCenter, {
    attributes: true,
    attributeFilter: ["class"]
  });
};
