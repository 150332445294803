import { MODALS_NAMESPACE_KEY } from "../constants";
import { MODAL_TYPES } from "../../constants";
import { validateOpenModalParams } from "../../Modal/validateOpenModalParams";
import {
  asosGlobalNamespace,
  siteChromeGlobalNamespace
} from "@template/constants";

const openIFrameModalFunctionName = "openIFrameModal";
const modalsNamespace = `${asosGlobalNamespace}.${siteChromeGlobalNamespace}.${MODALS_NAMESPACE_KEY}`;
const prependModalsNamespace = namespace => `${modalsNamespace}.${namespace}`;

const mandatoryParametersMessage = `
'${openIFrameModalFunctionName}' must be called with an object that has the following properties:
  - 'url': non-empty url string matching the current ASOS origin.
  - 'focusOnCloseElement': DOM Element.
  - 'consumer': non-empty string.
  - 'modalType': one of the following(from '${prependModalsNamespace(
    "MODAL_TYPES"
  )}') : ${Object.keys(MODAL_TYPES).join(", ")}.
`;

const isNonEmptyString = str => {
  return typeof str === "string" && str.trim().length > 0;
};

const isValidUrl = str => {
  try {
    return new URL(str).origin === window.location.origin;
  } catch {
    return false;
  }
};

export const validateOpenIFrameModalParams = params => {
  if (typeof params !== "object" || !Object.keys(params).length) {
    throw new Error(mandatoryParametersMessage);
  }

  const errorParams = validateOpenModalParams(params);

  const { url, focusOnCloseElement, consumer } = params;

  if (!isValidUrl(url)) {
    errorParams.push("'url'");
  }

  if (!(focusOnCloseElement instanceof Element)) {
    errorParams.push("'focusOnCloseElement'");
  }

  if (!isNonEmptyString(consumer)) {
    errorParams.push("'consumer'");
  }

  if (errorParams.length > 0) {
    throw new Error(`"The following parameters were missing or invalid when calling '${prependModalsNamespace(
      openIFrameModalFunctionName
    )}': ${errorParams.join(", ")}.
  ${mandatoryParametersMessage}`);
  }
};
