import PropTypes from "prop-types";
import { withTranslation } from "@template/components/translation";
import styles from "./InternationalSites.css";
import withViewport from "../../Viewport/withViewport";
import CountryFlag from "../../CountryFlag";

const id = "chrome-international-sites";

const InternationalSites = ({
  otherSites,
  formatTranslation,
  onSiteClicked
}) => (
  <div className={styles.internationalSites}>
    <p id={id}>{formatTranslation("footer_international_sites_label")}</p>
    <ul aria-labelledby={id}>
      {otherSites.map(({ countryCode, imageUrl, name, url }) => (
        <li key={name}>
          <a
            href={`//${url}`}
            onClick={e => {
              onSiteClicked(countryCode);
              e.preventDefault();
            }}
          >
            <CountryFlag {...{ name, imageUrl }} />
          </a>
        </li>
      ))}
    </ul>
  </div>
);

InternationalSites.propTypes = {
  otherSites: PropTypes.arrayOf(
    PropTypes.shape({
      countryCode: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired,
  formatTranslation: PropTypes.func.isRequired,
  onSiteClicked: PropTypes.func.isRequired
};

export default withViewport(withTranslation(InternationalSites));
