import PropTypes from "prop-types";
import { withTranslation } from "@template/components/translation";
import Logo from "@asosteam/asos-web-component-library-svg-icons/lib/logo.svg";
import styles from "./index.css";

const HomeLogo = ({ storeUrls, gender = "default", formatTranslation }) => (
  <a className={styles.link} href={storeUrls[gender]} data-testid="asoslogo">
    <img
      alt={formatTranslation("header_home_alt")}
      src={Logo}
      className={styles.asosLogo}
    />
  </a>
);

HomeLogo.propTypes = {
  storeUrls: PropTypes.object.isRequired,
  gender: PropTypes.string
};

HomeLogo.propTypes = {
  formatTranslation: PropTypes.func.isRequired
};

export default withTranslation(HomeLogo);
