import { createSelector } from "reselect";
import { getStoreCode } from "../regionalStore";

const getFeaturesStore = state => state.features;

export const getFeature = (state, key) => {
  const features = getFeaturesStore(state);
  const feature = key in features ? features[key] : false;

  return feature;
};

export const isStoreSpecificFeatureActive = (state, key) => {
  const features = getFeaturesStore(state);
  const feature = key in features ? features[key] : false;
  const storeCode = getStoreCode(state);
  return feature && feature[storeCode];
};

export const getFeaturesLoaded = createSelector(
  getFeaturesStore,
  feature => feature.loadComplete
);
