import { initialise as initialiseBag } from "./bag";
import { initialise as initialiseSavedItems } from "./savedItems";
import { initialise as initialiseSubscriptions } from "./subscriptions";
import { getSubscriptionDetailsFactory } from "./customerProfile";
import { initialise as initialiseAudience } from "./audience";
import { initialise as initialiseAuth } from "./auth";
import { initialise as initialiseFeatures, startFeatures } from "./features";
import {
  asosGlobalNamespace,
  sdkGlobalNamespace
} from "../../template/constants";
import {
  getValueFromSessionStorage,
  removeValueFromSessionStorage
} from "@src/helpers/webStorage";

const getBagContext = ({
  storeId,
  browseCountry: country,
  geoCountry: originCountry,
  browseCurrency: currency,
  browseLanguage: language,
  browseSizeSchema: sizeSchema,
  keyStoreDataversion
}) => ({
  storeId,
  country,
  originCountry,
  currency,
  language,
  sizeSchema,
  keyStoreDataversion
});

const getCustomerProfileContext = ({
  browseCountry: country,
  browseLanguage: language
}) => ({
  country,
  lang: language
});

const getSavedItemsContext = ({
  storeId: storeCode,
  browseCurrency: currency,
  browseLanguage: language,
  browseSizeSchema: sizeSchema,
  keyStoreDataversion,
  browseCountry: country
}) => ({
  storeCode,
  currency,
  language,
  sizeSchema,
  keyStoreDataversion,
  country
});

const ready = name => sdk => {
  const sdkNamespace = window[asosGlobalNamespace][sdkGlobalNamespace];
  sdkNamespace[name] = sdk;
  window[asosGlobalNamespace].eventBus.emit(`${name}-sdk-ready`, sdk);
  return sdk;
};

export const initialise = async (store, context) => {
  const webContext = context.getWebContext();
  const bagSdkContext = getBagContext(webContext);
  const customerProfileContext = getCustomerProfileContext(webContext);
  const savedItemsSdkContext = getSavedItemsContext(webContext);
  const sdkNamespace = window[asosGlobalNamespace][sdkGlobalNamespace];

  return Promise.all([
    initialiseFeatures(webContext)
      .then(ready("features"))
      .then(startFeatures)
      .then(() => {
        window[asosGlobalNamespace].eventBus.emit(
          `features-mvt-ready`,
          sdkNamespace.features
        );
      }),
    new Promise(resolve => {
      const getSubscriptionDetails = getSubscriptionDetailsFactory(
        customerProfileContext
      );
      const authClient = initialiseAuth({ store, getSubscriptionDetails });

      ready("identity")(authClient);
      ready("savedItems")(initialiseSavedItems(savedItemsSdkContext));
      ready("subscriptions")(initialiseSubscriptions());
      ready("audience")(initialiseAudience());
      initialiseBag({ context: bagSdkContext, authClient })
        .then(bagSdk => {
          const signedOut = getValueFromSessionStorage("AsosAuth.SignedOut");

          if (signedOut) {
            window.dispatchEvent(new Event("AsosAuthClient.onLoggedOut"));
            removeValueFromSessionStorage("AsosAuth.SignedOut");
          }

          return bagSdk;
        })
        .then(ready("bag"))
        .then(resolve);
    })
  ]).then(() =>
    window[asosGlobalNamespace].eventBus.emit("sdk-ready", sdkNamespace)
  );
};
