import { PropTypes } from "prop-types";
import { withTranslation } from "@template/components/translation";
import SuggestionsList from "./SuggestionsList";
import RecentSearchesList from "./RecentSearchesList";

const SearchDropdown = props => {
  const { items, isSuggestions } = props;

  if (!items.length) {
    return null;
  }

  return isSuggestions ? (
    <SuggestionsList {...props} />
  ) : (
    <RecentSearchesList {...props} />
  );
};

SearchDropdown.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  clearRecentSearches: PropTypes.func.isRequired,
  focusSearchBox: PropTypes.func.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  isSuggestions: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      searchTerm: PropTypes.string.isRequired,
      numberOfResults: PropTypes.number
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  term: PropTypes.string
};

export default withTranslation(SearchDropdown);
