import context from "./context";
import PropTypes from "prop-types";
import useFormatter from "./useFormatter";

const LocaleProvider = ({ children }) => {
  const formatTranslation = useFormatter();

  return (
    <context.Provider value={formatTranslation}>{children}</context.Provider>
  );
};

LocaleProvider.propTypes = {
  children: PropTypes.node
};

export default LocaleProvider;
