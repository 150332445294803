const iFrameLoadedSuccessAttributeName = "data-page-loaded";
const iFrameLoadedSuccessAttributeValue = "true";

export const getIFrameLoadFailureHandler = setErrorDelegate => {
  return iframe => {
    const iFrameLoaded = iframe.target.contentWindow.document.querySelector(
      `[${iFrameLoadedSuccessAttributeName}='${iFrameLoadedSuccessAttributeValue}']`
    );

    if (!iFrameLoaded) {
      setErrorDelegate(
        new Error(
          `iFrame with src ${iframe.target.src} didn't load successfully, because the content did not contain an element with a '${iFrameLoadedSuccessAttributeName}' attribute of '${iFrameLoadedSuccessAttributeValue}'.`
        )
      );
    }
  };
};
