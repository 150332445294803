export const getAnnouncement = ({ activeIndex, items }) => {
  const item = items[activeIndex];

  if (!item) {
    return null;
  }

  let announcement = item.searchTerm;

  if (item.numberOfResults) {
    announcement += ` ${item.numberOfResults}`;
  }

  return announcement;
};
