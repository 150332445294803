import sdkInstanceProvider from "@src/client/sdk/sdkInstanceProvider.js";
import {
  getKeyedCookieValue,
  setKeyedCookieValue
} from "@src/client/context/cookies";
import { fetchDefaultDeliveryCountry } from "@src/api";
import {
  RSFT_WELCOME_MESSAGE_COOKIE_NAME,
  RSFT_RESOLVED_DELIVERY_COUNTRY_KEY
} from "@template/constants";

const correctCookiePreviouslySetWithMinorCountry =
  newResolvedDeliveryCountry => {
    setKeyedCookieValue(
      RSFT_WELCOME_MESSAGE_COOKIE_NAME,
      RSFT_RESOLVED_DELIVERY_COUNTRY_KEY,
      newResolvedDeliveryCountry
    );

    return newResolvedDeliveryCountry;
  };

export const getResolvedDeliveryCountry = async ({
  geoCountry,
  minorCountries = {}
}) => {
  const resolvedDeliveryCountry = getKeyedCookieValue(
    RSFT_WELCOME_MESSAGE_COOKIE_NAME,
    RSFT_RESOLVED_DELIVERY_COUNTRY_KEY
  );

  if (resolvedDeliveryCountry in minorCountries) {
    return correctCookiePreviouslySetWithMinorCountry(
      minorCountries[resolvedDeliveryCountry]
    );
  }

  if (resolvedDeliveryCountry) {
    return resolvedDeliveryCountry;
  }

  const {
    customer: { profile }
  } = await sdkInstanceProvider("identity");

  let customerId;
  try {
    ({ customerId } = await profile());
    // eslint-disable-next-line no-empty
  } catch (_) {}

  if (!customerId) {
    setKeyedCookieValue(
      RSFT_WELCOME_MESSAGE_COOKIE_NAME,
      RSFT_RESOLVED_DELIVERY_COUNTRY_KEY,
      geoCountry
    );

    return geoCountry;
  }

  let deliveryCountry;

  try {
    ({ deliveryCountry } = await fetchDefaultDeliveryCountry(customerId));
  } catch (_) {
    setKeyedCookieValue(
      RSFT_WELCOME_MESSAGE_COOKIE_NAME,
      RSFT_RESOLVED_DELIVERY_COUNTRY_KEY,
      geoCountry
    );

    return geoCountry;
  }

  setKeyedCookieValue(
    RSFT_WELCOME_MESSAGE_COOKIE_NAME,
    RSFT_RESOLVED_DELIVERY_COUNTRY_KEY,
    deliveryCountry
  );

  return deliveryCountry;
};
