import { setCookieValue, removeCookie } from "@src/client/context/cookies";
import { OPTANON_ALERT_BOX_CLOSED_COOKIE, oneYearInMs } from "./constants";
import getCookieDomains from "./getCookieDomains";

const handleOptanonAlertBoxClosedCookies = optanonAlertBoxClosedCookies => {
  const domains = getCookieDomains();

  optanonAlertBoxClosedCookies.sort(
    (cookieDate1, cookieDate2) => new Date(cookieDate2) - new Date(cookieDate1)
  );
  domains.forEach(domain => {
    removeCookie(OPTANON_ALERT_BOX_CLOSED_COOKIE, domain);
  });

  const optanonAlertBoxClosedCreationDate = new Date(
    optanonAlertBoxClosedCookies[0]
  );

  const optanonAlertBoxClosedExpirationDate = new Date(
    optanonAlertBoxClosedCreationDate.setTime(
      optanonAlertBoxClosedCreationDate.getTime() + oneYearInMs
    )
  );

  setCookieValue(
    OPTANON_ALERT_BOX_CLOSED_COOKIE,
    optanonAlertBoxClosedCookies[0],
    {
      expires: optanonAlertBoxClosedExpirationDate,
      domain: domains[0]
    }
  );
};

export default handleOptanonAlertBoxClosedCookies;
