import { ITEM_TYPE_VOUCHER, ITEM_TYPE_SUBSCRIPTION } from "./constants";

const getVoucherOrSubscriptionPriceLabel = ({
  price: { text },
  formatTranslation
}) => `${formatTranslation("minibag_price")}: ${text}`;

const getProductPriceLabel = ({
  price: {
    current: { text: currentText },
    previous: { text: previousText },
    rrp: { text: rrpText },
    isMarkedDown,
    isOutletPrice
  },
  formatTranslation
}) => {
  if (isOutletPrice) {
    return `${formatTranslation(
      "saved_items_accessibility_original_price"
    )}: ${rrpText}; ${formatTranslation(
      "generic_current_price"
    )}: ${currentText}`;
  }

  if (isMarkedDown) {
    return `${formatTranslation(
      "saved_items_accessibility_original_price"
    )}: ${previousText}; ${formatTranslation(
      "generic_current_price"
    )}: ${currentText}`;
  }

  return `${formatTranslation("minibag_price")}: ${currentText}`;
};

export const getPriceLabel = ({ price, itemType, formatTranslation }) => {
  if (itemType === ITEM_TYPE_VOUCHER || itemType === ITEM_TYPE_SUBSCRIPTION) {
    return getVoucherOrSubscriptionPriceLabel({ price, formatTranslation });
  }

  return getProductPriceLabel({ price, formatTranslation });
};
