/**
 * Until we're ES6 end to end we have to use this form because with Babel
 * subclasses must extend a class https://github.com/babel/babel/issues/3083
 */
export function ApiError(message, fetchMetadata = {}) {
  this.name = "ApiError";
  this.message = message || "Api Error";
  this.stack = new Error().stack;
  this.status = fetchMetadata.status;
  this.statusText = fetchMetadata.statusText;
  this.url = fetchMetadata.url;
  this.responseText = fetchMetadata.responseText;
}

ApiError.prototype = Object.create(Error.prototype);
ApiError.prototype.constructor = ApiError;
