import { connect } from "react-redux";
import NotQuiteStickyHeader from "./NotQuiteStickyHeader";
import { getViewport, isSidePanelOpen } from "../../state/modules/layout";

function mapStateToProps(state) {
  return {
    viewportCategory: getViewport(state),
    isSidePanelOpen: isSidePanelOpen(state)
  };
}

export default connect(mapStateToProps)(NotQuiteStickyHeader);
