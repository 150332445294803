import { getCommonAnalytics } from "./commonAnalytics";
import { ADOBE_EVENTS_KEY } from "./constants";

export const mapToOverrides = ({ [ADOBE_EVENTS_KEY]: events, ...vars }) => {
  const commons = getCommonAnalytics();

  return {
    linkTrackVars: commons.setLinkTrackVars(Object.keys(vars)),
    linkTrackEvents: commons.setLinkTrackEvents([events])
  };
};
