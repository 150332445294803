import { asosGlobalNamespace, sdkGlobalNamespace } from "@template/constants";

const sdkExists = sdkName =>
  !!window[asosGlobalNamespace][sdkGlobalNamespace][sdkName];

const sdkInstanceProvider = sdkName =>
  new Promise(resolve => {
    const resolveSdk = () => {
      const sdk = window[asosGlobalNamespace][sdkGlobalNamespace][sdkName];
      resolve(sdk);
    };

    if (sdkExists(sdkName)) {
      return resolveSdk();
    }

    window[asosGlobalNamespace].eventBus.once(
      `${sdkName}-sdk-ready`,
      resolveSdk
    );
  });

export default sdkInstanceProvider;
