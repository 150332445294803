import { createSelector } from "reselect";

const getStore = state => state.welcomeMessage;

export const getCountry = createSelector(getStore, store => store.country);

export const isWelcomeMessageShown = createSelector(
  getStore,
  store => store.browsingLocalStore === false
);
