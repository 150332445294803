import { setKeyedCookieValue } from "@src/client/context/cookies";
import {
  RSFT_WELCOME_MESSAGE_COOKIE_NAME,
  RSFT_CAPPED_PAGE_COUNT_KEY
} from "@template/constants";

const setRsftWelcomeMessageCappedPageCount = pageCount => {
  setKeyedCookieValue(
    RSFT_WELCOME_MESSAGE_COOKIE_NAME,
    RSFT_CAPPED_PAGE_COUNT_KEY,
    pageCount,
    null
  );
};

export default setRsftWelcomeMessageCappedPageCount;
