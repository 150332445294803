export const getBackgroundImageUrl = (imageUrl, width) => {
  if (!imageUrl) {
    return "";
  }

  const backgroundImageUrl = new URL(imageUrl);

  if (width) {
    backgroundImageUrl.search += `&$n_${width}w$`;
  }

  return backgroundImageUrl.toString();
};
