import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import HtmlHeader from "@template/components/HtmlHeader";
import Breadcrumbs from "@template/components/Breadcrumbs";
import Footer from "@template/components/Footer";
import WelcomeMessage from "@template/components/WelcomeMessage";
import InfoBanner from "@template/components/InfoBanner";
import SidePanel from "@template/components/Navigation/SidePanel";
import CountrySelector from "@template/components/CountrySelector";
import SecureScriptContainer from "@template/components/Footer/SecureScriptContainer";
import Modals from "@template/components/Modals";
import { LocaleProvider } from "@template/components/translation";
import { isMainAppAriaHidden } from "@template/state/modules/layout/selectors";
import {
  SMALL_VIEWPORT,
  MEDIUM_VIEWPORT
} from "@template/state/modules/layout";
import { BREADCRUMBS_CONTAINER_ID } from "@template/../client/breadcrumbs/constants";

export const MainContent = ({ webContext }) => {
  const ariaHidden = useSelector(isMainAppAriaHidden);
  return (
    <LocaleProvider>
      <div
        aria-hidden={ariaHidden ? true : null}
        className="chrome-main-content--accessible"
      >
        <div id="chrome-welcome-mat">
          <WelcomeMessage />
        </div>
        <div id="chrome-header">
          <HtmlHeader />
        </div>
        <div id={BREADCRUMBS_CONTAINER_ID}>
          <Breadcrumbs />
        </div>
        <main
          id="chrome-app-container"
          dangerouslySetInnerHTML={{ __html: "<!--%applicationContent%-->" }}
        />
        <div id="chrome-footer">
          <Footer />
          <SecureScriptContainer webContext={webContext} />
        </div>
        <div id="chrome-info-banner">
          <InfoBanner />
        </div>
      </div>
    </LocaleProvider>
  );
};

MainContent.propTypes = {
  webContext: PropTypes.object
};

export const ModalContent = () => {
  const ariaHidden = useSelector(isMainAppAriaHidden);
  return (
    <LocaleProvider>
      <div id="chrome-modal-container" aria-hidden={!ariaHidden ? true : null}>
        <SidePanel viewable={[SMALL_VIEWPORT, MEDIUM_VIEWPORT]} />
        <CountrySelector />
        <Modals />
      </div>
    </LocaleProvider>
  );
};
