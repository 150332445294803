import { getStore } from "../api/storePresentation";
import { getSocialLinks } from "../../i18n/utilities";

import {
  DEFAULT,
  FEMALE,
  MALE,
  SECURE
} from "../template/state/modules/layout/constants";

const TYPES = [DEFAULT, FEMALE, MALE, SECURE];

export { DEFAULT, FEMALE, MALE, SECURE };

const addTrailingSlash = str => `${str}/`;

export const storeUrlsForAllFloors = async (
  countryCode,
  keyStoreDataversion
) => {
  const storeUrls = {};
  for (const i in TYPES) {
    const type = TYPES[i];
    storeUrls[type] = addTrailingSlash(
      await getStore().getStoreUrlByCountryCode({
        countryCode,
        type,
        keyStoreDataversion,
        includeProtocol: true
      })
    );
  }
  return storeUrls;
};

export const storeLinksForAllFloors = storeCode =>
  TYPES.reduce(
    (links, type) => ({
      ...links,
      [type]: getSocialLinks({ storeCode, floor: type })
    }),
    {}
  );
