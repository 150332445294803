import { createSelector } from "reselect";
import { MINIBAG, INFO_BANNER } from "./constants";

export const getNotifications = state => state.notifications;

export const getMinibagNotification = createSelector(
  getNotifications,
  state => state[MINIBAG]
);

export const getInfoBannerNotification = createSelector(
  getNotifications,
  state => state[INFO_BANNER]
);
