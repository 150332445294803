import ImageCardWithDescription from "./ImageCardWithDescription";
import { navigationPropType } from "@template/state/modules/navigation";

import styles from "./index.css";

const ImageCardsWithDescription = ({ item }) => {
  const { id, items, isFirstColumn, isLastColumn } = item;

  return (
    <ul className={styles.cardList} aria-labelledby={id}>
      {items.slice(0, 8).map((imageItem, index, array) => (
        <ImageCardWithDescription
          item={imageItem}
          key={imageItem.id}
          data-first={isFirstColumn && index === 0 ? true : null}
          data-last={isLastColumn && index === array.length - 1 ? true : null}
        />
      ))}
    </ul>
  );
};

ImageCardsWithDescription.propTypes = {
  item: navigationPropType.isRequired
};

export default ImageCardsWithDescription;
