import PropTypes from "prop-types";
import ResultItem from "../ResultItem";
import { getResultItemId } from "../getResultItemId";

const ResultItems = ({ activeIndex, items, onSelect, term }) => (
  <>
    {items.map((item, i) => {
      const id = getResultItemId(i);
      const active = activeIndex === i;

      return (
        <ResultItem
          key={i}
          id={id}
          active={active}
          item={item}
          term={term}
          onSelect={onSelect}
        />
      );
    })}
  </>
);

ResultItems.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      searchTerm: PropTypes.string.isRequired,
      numberOfResults: PropTypes.number
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  term: PropTypes.string
};

export default ResultItems;
