import { configureRequest } from "@asosteam/asos-web-request";
import { handleErrors } from "./handleErrors";
import logger from "@src/helpers/logger";
import packageInfo from "@src/../package.json";
import env from "@src/env";

const { name, version } = packageInfo;

let httpsAgent, azureSubscriptionId, retries;

if (__SERVER__) {
  const agentkeepalive = require("agentkeepalive");

  httpsAgent = new agentkeepalive.HttpsAgent({
    maxSockets: 160,
    keepAliveMsecs: 3000
  });

  azureSubscriptionId = env("AZURE_SUBSCRIPTION_ID");
  retries = 3;
}

const webRequest = configureRequest({
  client: {
    name,
    version
  },
  validateStatus: null,
  httpsAgent,
  logger,
  ...(retries && { retries })
});

const request = (url, options = {}, fullResponse = false) => {
  if (__SERVER__) {
    options.headers = options.headers ?? {};
    options.headers["az-sub-id"] = azureSubscriptionId;
  }

  const method = options.method?.toLowerCase() ?? "get";

  const response = webRequest[method]({ url, ...options }).then(handleErrors);

  return fullResponse ? response : response.then(({ data }) => data);
};

export default request;
