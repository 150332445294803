export const getNlid = ({ url, hash }) => {
  if (hash?.has("nlid")) {
    return hash.get("nlid");
  }

  const querystring = url.searchParams;

  if (querystring.has("nlid")) {
    return querystring.get("nlid");
  }

  return "";
};
