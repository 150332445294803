import classnames from "classnames";
import { navigationItemPropType } from "../../../../state/modules/navigation";
import { getBackgroundImageUrl } from "../../../../helpers/navigationBackgroundImageResolver";
import NavAnchor from "../../NavAnchor";
import LazyImage from "../../../LazyImage";
import { BACKGROUND_LOADING_COLORS } from "../../../LazyImage/constants";
import styles from "./index.css";

const cx = classnames.bind(styles);

const ImageCardWithDescription = ({ item, ...anchorProps }) => {
  const {
    label,
    subtitle,
    largeScreenImageUrl,
    largeScreenStyleType = "light"
  } = item;

  return (
    <li className={styles.card}>
      <NavAnchor item={item} {...anchorProps}>
        <div className={styles.thumbnailWrapper}>
          <LazyImage
            aria-hidden="true"
            className={styles.image}
            role="presentation"
            src={getBackgroundImageUrl(largeScreenImageUrl, 320)}
            loadingColor={BACKGROUND_LOADING_COLORS.silverForDesktop}
            tag={"div"}
          />
          <div
            className={cx(styles.thumbnailCover, styles[largeScreenStyleType])}
          />
          <h4 className={cx(styles.cardTitle, styles[largeScreenStyleType])}>
            {label.substring(0, 24)}
          </h4>
        </div>
        <span className={styles.cardDescription}>
          {subtitle && subtitle.substring(0, 45)}
        </span>
      </NavAnchor>
    </li>
  );
};

ImageCardWithDescription.propTypes = {
  item: navigationItemPropType.isRequired
};
export default ImageCardWithDescription;
