import { useEffect } from "react";
import { noticeError } from "@src/helpers/monitoring";

export const useErrorLogging = error => {
  useEffect(() => {
    if (error !== null) {
      noticeError(error);
      console.error(error);
    }
  }, [error]);
};
