import { useRef, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames/bind";
import FocusLock, { InFocusGuard } from "react-focus-lock";
import FloorNavigation from "@template/components/FloorNavigation";
import SidePrimaryNavigation from "../SidePrimaryNavigation";
import SideSecondaryNavigation from "../SideSecondaryNavigation";
import Footer from "@template/components/Footer";
import { withTranslation } from "@template/components/translation";
import withViewport from "@template/components/Viewport/withViewport";
import {
  MALE,
  FEMALE,
  genderPropType,
  viewportPropType
} from "@template/state/modules/layout";
import { navigationPropType } from "@template/state/modules/navigation";
import { globalEventHandler, events, keys } from "@template/globalEventHandler";
import { BURGER_MENU_BUTTON_DATA_TEST_ID } from "@template/components/Burger/constants";
import { useBlockScroll } from "@template/components/Modals/Modal/useBlockScroll";
import styles from "./index.css";
import { NavigationContext } from "../NavigationContext";

const cx = classnames.bind(styles);

const SidePanel = ({
  clearNavActiveItem,
  closeSidePanel,
  currentGender,
  femaleItems,
  focusLastActiveElement,
  formatTranslation,
  isSidePanelOpen,
  lastActiveElements,
  maleItems,
  navActiveItem,
  setNavActiveItem,
  viewable
}) => {
  const isSecondaryNavigationOpen = !!navActiveItem;
  const [isSidePanelAnimating, setIsSidePanelAnimating] = useState(false);
  const isMounted = useRef(false);

  const close = useCallback(() => {
    clearNavActiveItem();
    closeSidePanel();
    focusLastActiveElement([], {
      forceElementFocus: `[data-testid="${BURGER_MENU_BUTTON_DATA_TEST_ID}"]`
    });
  }, [clearNavActiveItem, closeSidePanel, focusLastActiveElement]);

  const handleDialogTransitionEnd = useCallback(
    ({ target, currentTarget }) => {
      if (target === currentTarget) {
        clearNavActiveItem();
        setIsSidePanelAnimating(false);
      }
    },
    [clearNavActiveItem]
  );

  const handlePrimaryNavigationTransitionEnd = useCallback(
    ({ target, currentTarget }) => {
      if (target === currentTarget && !isSecondaryNavigationOpen) {
        focusLastActiveElement(lastActiveElements);
      }
    },
    [focusLastActiveElement, lastActiveElements, isSecondaryNavigationOpen]
  );

  useBlockScroll(isSidePanelOpen);

  useEffect(() => {
    const globalKeyDownListener = globalEventHandler.addListener(
      events.keyDown,
      event => {
        if (event.key === keys.escape && isSidePanelOpen) {
          close();
        }
      }
    );

    return () => globalKeyDownListener.remove();
  }, [close, isSidePanelOpen]);

  useEffect(() => {
    if (isMounted.current) {
      setIsSidePanelAnimating(true);
    } else {
      isMounted.current = true;
    }
  }, [isSidePanelOpen]);

  const navProps = {
    currentGender,
    navActiveItem,
    setNavActiveItem,
    clearNavActiveItem
  };

  return (
    <NavigationContext.Provider value={close}>
      <div
        role="dialog"
        aria-modal="true"
        aria-hidden={isSidePanelOpen ? null : true}
        onTransitionEnd={handleDialogTransitionEnd}
        className={cx(styles.dialog, {
          [styles.dialog__visible]: isSidePanelOpen,
          [styles.dialog__animating]: isSidePanelAnimating
        })}
        aria-labelledby="sidepanel_navigation_header"
        data-testid="sidepanel"
      >
        <h2 className={styles.srOnly} id="sidepanel_navigation_header">
          {formatTranslation("sidepanel_navigation_label")}
        </h2>
        <FocusLock
          disabled={!isSidePanelOpen || isSecondaryNavigationOpen}
          className={styles.container}
        >
          <button
            className={styles.close}
            onClick={close}
            aria-label={formatTranslation("sidepanel_close_navigation_label")}
            data-testid="close-button"
          />
          <div className={styles.overflowHiddenContainer}>
            <div
              aria-hidden={isSecondaryNavigationOpen ? true : null}
              className={cx(styles.primarySideContainer, {
                [styles.primarySideContainer__hidden]: isSecondaryNavigationOpen
              })}
              onTransitionEnd={handlePrimaryNavigationTransitionEnd}
            >
              <FloorNavigation viewable={viewable} />
              <div className={styles.content}>
                <SidePrimaryNavigation
                  {...navProps}
                  items={femaleItems}
                  forGender={FEMALE}
                />
                <SidePrimaryNavigation
                  {...navProps}
                  items={maleItems}
                  forGender={MALE}
                />
                <Footer isAccordion />
              </div>
            </div>
            <FocusLock
              disabled={!isSecondaryNavigationOpen}
              className={cx(styles.secondarySideContainer, {
                [styles.secondarySideContainer__visible]:
                  isSidePanelOpen || isSidePanelAnimating
              })}
              lockProps={{
                "aria-hidden": isSecondaryNavigationOpen ? null : true
              }}
              noFocusGuards
            >
              <InFocusGuard>
                <SideSecondaryNavigation
                  {...navProps}
                  items={femaleItems}
                  forGender={FEMALE}
                />
                <SideSecondaryNavigation
                  {...navProps}
                  items={maleItems}
                  forGender={MALE}
                />
              </InFocusGuard>
            </FocusLock>
          </div>
        </FocusLock>
      </div>
      <aside
        className={cx(styles.overlay, {
          [styles.overlay__show]: isSidePanelOpen
        })}
        onClick={close}
        aria-hidden="true"
      />
    </NavigationContext.Provider>
  );
};

SidePanel.propTypes = {
  clearNavActiveItem: PropTypes.func.isRequired,
  closeSidePanel: PropTypes.func.isRequired,
  currentGender: genderPropType.isRequired,
  femaleItems: PropTypes.arrayOf(navigationPropType).isRequired,
  focusLastActiveElement: PropTypes.func,
  formatTranslation: PropTypes.func.isRequired,
  isSidePanelOpen: PropTypes.bool.isRequired,
  lastActiveElements: PropTypes.arrayOf(PropTypes.string),
  maleItems: PropTypes.arrayOf(navigationPropType).isRequired,
  navActiveItem: PropTypes.string,
  setNavActiveItem: PropTypes.func.isRequired,
  viewable: PropTypes.arrayOf(viewportPropType).isRequired
};

export default withViewport(withTranslation(SidePanel));
