import PropTypes from "prop-types";
import classnames from "classnames/bind";
import { withTranslation } from "@template/components/translation";
import styles from "./deferred.css";

const cx = classnames.bind(styles);

const Price = ({
  text,
  current,
  previous,
  rrp,
  isMarkedDown,
  isOutletPrice,
  formatTranslation
}) => {
  const onSale = isOutletPrice || isMarkedDown;
  const showPreviousPrice = isMarkedDown && !isOutletPrice;

  return (
    <div className={styles.container}>
      <span
        className={cx([styles.current], {
          [styles.highlight]: onSale
        })}
      >
        {text || current.text}
      </span>
      {isOutletPrice && (
        <span className={styles.rrp}>
          <span className={styles.rrpLabel}>
            {formatTranslation("minibag_rrp")}
          </span>
          {rrp.text}
        </span>
      )}
      {showPreviousPrice && (
        <span className={styles.previous}>{previous.text}</span>
      )}
    </div>
  );
};

Price.propTypes = {
  current: PropTypes.shape({
    text: PropTypes.string
  }),
  isMarkedDown: PropTypes.bool,
  isOutletPrice: PropTypes.bool,
  previous: PropTypes.shape({
    text: PropTypes.string
  }),
  rrp: PropTypes.shape({
    text: PropTypes.string
  }),
  text: PropTypes.string,
  formatTranslation: PropTypes.func.isRequired
};

Price.defaultProps = {
  current: {}
};

export default withTranslation(Price);
