import {
  UPDATE_VIEWPORT_CATEGORY,
  SET_GENDER,
  OPEN_SIDE_PANEL,
  CLOSE_SIDE_PANEL,
  SET_GEO_COUNTRY,
  REMOVE_LAST_ACTIVE_ELEMENT,
  ADD_LAST_ACTIVE_ELEMENT,
  CLEAR_LAST_ACTIVE_ELEMENTS,
  TOGGLE_MAIN_APP_ARIA_HIDDEN
} from "./constants";

export const updateViewportCategory = viewportCategory => ({
  type: UPDATE_VIEWPORT_CATEGORY,
  payload: {
    viewportCategory
  }
});

export const addLastFocusElement = activeElement => {
  return {
    type: ADD_LAST_ACTIVE_ELEMENT,
    activeElement
  };
};
export const focusLastActiveElement = (
  lastActiveElements,
  { forceElementFocus } = {}
) => {
  if (forceElementFocus) {
    /* istanbul ignore next */
    setTimeout(() => {
      document.querySelector(forceElementFocus).focus();
    });

    return {
      type: CLEAR_LAST_ACTIVE_ELEMENTS,
      forceElementFocus
    };
  }

  const lastActiveElement = lastActiveElements.pop();
  const element =
    typeof lastActiveElement === "string"
      ? document.querySelector(lastActiveElement)
      : lastActiveElement;

  /* istanbul ignore next */
  if (element) {
    setTimeout(() => {
      window.scrollTo(0, 0);
      element.focus();
    });
  }

  return {
    type: REMOVE_LAST_ACTIVE_ELEMENT,
    lastActiveElement
  };
};

export const setGender = gender => ({
  type: SET_GENDER,
  payload: gender
});

export const openSidePanel = () => ({
  type: OPEN_SIDE_PANEL
});

export const closeSidePanel = () => ({
  type: CLOSE_SIDE_PANEL
});

export const publishGeoCountry = country => ({
  type: SET_GEO_COUNTRY,
  payload: country
});

export const toggleMainAppAriaHidden = isMainAppAriaHidden => ({
  type: TOGGLE_MAIN_APP_ARIA_HIDDEN,
  payload: { isMainAppAriaHidden }
});
