import { getPriceLabel } from "./getPriceLabel";

export const getAriaLabel = ({
  name,
  price,
  itemType,
  hasColour,
  colour,
  hasSize,
  size,
  quantity,
  formatTranslation
}) => {
  const sections = [
    `${formatTranslation("minibag_name")}: ${name}`,
    getPriceLabel({ price, itemType, formatTranslation })
  ];

  if (hasColour) {
    sections.push(`${formatTranslation("minibag_color")}: ${colour}`);
  }

  if (hasSize) {
    sections.push(`${formatTranslation("minibag_size")}: ${size}`);
  }

  sections.push(`${formatTranslation("minibag_quantity")}: ${quantity}`);

  return sections.join("; ");
};
