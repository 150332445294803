import { createSelector } from "reselect";

const targetedContentSelector = state => state.contentTargeting;

export const getAllTargetedContent = createSelector(
  targetedContentSelector,
  targetedContent => targetedContent.content
);

export const getContentTargetingStatus = createSelector(
  targetedContentSelector,
  targetedContent => targetedContent.status
);

export const getGlobalBannerContent = createSelector(
  targetedContentSelector,
  targetedContent => {
    const content = targetedContent.content;

    for (let i = 0; i < content.length; i++) {
      if (content[i].type === "GlobalBanner") {
        return content[i].data;
      }
    }

    return null;
  }
);
