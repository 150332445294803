import NavAnchor from "@template/components/Navigation/NavAnchor";
import LazyImage from "@template/components/LazyImage";
import { navigationItemPropType } from "@template/state/modules/navigation";
import { getBackgroundImageUrl } from "@template/helpers/navigationBackgroundImageResolver";
import { BACKGROUND_LOADING_COLORS } from "../../../LazyImage/constants";
import styles from "./index.css";
import classnames from "classnames";

const cx = classnames.bind(styles);

const MAX_ITEM_COUNT = 6;
const MAX_LETTER_COUNT = 26;

const GridCircleImage = ({ item }) => {
  const items = [].concat(item.items);
  const { id, isFirstColumn, isLastColumn } = item;

  return (
    <ul className={styles.container} aria-labelledby={id}>
      {items.splice(0, MAX_ITEM_COUNT).map((item, index, array) =>
        gridItem({
          item,
          "data-first": isFirstColumn && index === 0 ? true : null,
          "data-last": isLastColumn && index === array.length - 1 ? true : null
        })
      )}
    </ul>
  );
};

const gridItem = ({ item, ...anchorProps }) => (
  <li
    key={item.id}
    className={cx(styles.gridItem, {
      [styles.gridItem__sale]: item.largeScreenStyleType === "sale"
    })}
  >
    <NavAnchor item={item} {...anchorProps}>
      <div className={styles.gridItemImageBorder}>
        <LazyImage
          aria-hidden="true"
          className={styles.gridItemImage}
          role="presentation"
          src={getBackgroundImageUrl(item.largeScreenImageUrl, 240)}
          title={item.label}
          tag={"div"}
          loadingColor={BACKGROUND_LOADING_COLORS.silverForDesktop}
        />
      </div>
      <span className={styles.itemLabel}>
        {item.label.substr(0, MAX_LETTER_COUNT)}
      </span>
    </NavAnchor>
  </li>
);

gridItem.propTypes = {
  item: navigationItemPropType.isRequired
};

GridCircleImage.propTypes = {
  item: navigationItemPropType.isRequired
};

export default GridCircleImage;
