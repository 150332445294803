import { navigationPropType } from "@template/state/modules/navigation";
import CircleImageLink from "@template/components/Navigation/LargeSecondaryNavigation/CircleImageList/CircleImageLink";

import styles from "./index.css";

const CircleImageList = ({ item }) => {
  const { id, items, isFirstColumn, isLastColumn } = item;

  return (
    <ul className={styles.circleImageList} aria-labelledby={id}>
      {items.map((itemCircleImage, index) => (
        <CircleImageLink
          item={itemCircleImage}
          key={itemCircleImage.id}
          data-first={isFirstColumn && index === 0 ? true : null}
          data-last={isLastColumn && index === items.length - 1 ? true : null}
        />
      ))}
    </ul>
  );
};

CircleImageList.propTypes = {
  item: navigationPropType.isRequired
};

export default CircleImageList;
