import PropTypes from "prop-types";
import styles from "./index.css";

const GermanVATIncluded = ({ storeCode }) =>
  storeCode === "DE" ? (
    <small className={styles.germanVATInc}>{"inkl. MwSt."}</small>
  ) : null;

GermanVATIncluded.propTypes = {
  storeCode: PropTypes.string.isRequired
};

export default GermanVATIncluded;
