import PropTypes from "prop-types";
import classnames from "classnames";
import {
  trackPageInteraction,
  getGender,
  ANALYTICS_INTERACTION_CLICK,
  ANALYTICS_POSITION_HEADER,
  ANALYTICS_CONTEXT
} from "@src/helpers/eventing/events";
import { truncateTitle } from "@src/helpers/formatting";
import { getBackgroundImageUrl } from "@template/helpers/navigationBackgroundImageResolver";
import LazyImage from "@template/components/LazyImage";
import { BACKGROUND_LOADING_COLORS } from "../../../LazyImage/constants";
import styles from "./index.css";

const cx = classnames.bind(styles);

const MenuItem = ({
  className,
  item,
  setNavActiveItem,
  saveNavigationItem,
  addLastFocusElement
}) => {
  const clickItem = () => {
    setNavActiveItem(item.id);
    addLastFocusElement(`[data-navid='${item.id}']`);
    trackPageInteraction({
      context: ANALYTICS_CONTEXT.openNav,
      interaction: ANALYTICS_INTERACTION_CLICK,
      elementText: item.label,
      properties: {
        positionOnPage: ANALYTICS_POSITION_HEADER,
        gender: getGender(item.gender)
      }
    });
  };

  return (
    <li className={cx(styles.container, className)}>
      <LazyImage
        key={item.id}
        data-navid={item.id}
        className={styles.menuItem}
        type="button"
        onClick={clickItem}
        ref={saveNavigationItem}
        data-testid="menu-item"
        src={getBackgroundImageUrl(item.smallAndMediumImageUrl, 320)}
        tag={"button"}
        loadingColor={
          item.smallAndMediumStyleType === "light"
            ? BACKGROUND_LOADING_COLORS.darkGreyForMobileLightText
            : null
        }
      >
        <p
          className={cx(styles.label, {
            [styles.label__sale]: item.smallAndMediumStyleType === "sale",
            [styles.label__light]: item.smallAndMediumStyleType === "light"
          })}
        >
          <span className={cx(styles[item.smallAndMediumStyleType])}>
            {truncateTitle(item)}
          </span>
          {item.subtitle && (
            <span className={styles.subtitle}>
              {item.subtitle.substring(0, 24)}
            </span>
          )}
        </p>
      </LazyImage>
    </li>
  );
};

MenuItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.any,
  setNavActiveItem: PropTypes.func.isRequired,
  saveNavigationItem: PropTypes.func.isRequired,
  addLastFocusElement: PropTypes.func.isRequired
};

export default MenuItem;
