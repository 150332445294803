import { MODAL_TYPES } from "../constants";

export const validateOpenModalParams = params => {
  const { modalType, forceDOMRewrite } = params;

  const errorParams = [];

  if (!Object.values(MODAL_TYPES).includes(modalType)) {
    errorParams.push("'modalType'");
  }

  if (forceDOMRewrite && !(typeof forceDOMRewrite === "boolean")) {
    errorParams.push("'forceDOMRewrite'");
  }

  return errorParams;
};
