import Modal from "../../Modal";
import PropTypes from "prop-types";
import { useState, useRef, useEffect } from "react";
import { usePublicApiManagement } from "../usePublicApiManagement";
import { MODAL_TYPES } from "../../constants";
import { validateOpenDivModalParams } from "./validateOpenDivModalParams";
import styles from "./index.css";

const PublicDivModal = ({ isOpen, setIsOpen }) => {
  const [modalType, setModalType] = useState(MODAL_TYPES.SIDE_RIGHT);
  const [isDivRendered, setIsDivRendered] = useState(false);
  const onClosedFromConsumer = useRef(null);
  const promiseRef = useRef(null);
  const mountedRef = useRef(false);

  const close = () => setIsOpen(false);
  const onClosedDelegate = () => {
    setIsDivRendered(false);
    onClosedFromConsumer.current();
  };

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      setIsDivRendered(true);
    }
  }, [isOpen]);

  const openDivModal = ({ modalType, onClosed }) => {
    if (!mountedRef.current) {
      return Promise.reject("Modal is unmounted");
    }

    validateOpenDivModalParams({ modalType, onClosed });

    onClosedFromConsumer.current = onClosed;
    setModalType(modalType);
    setIsOpen(true);

    return new Promise(
      resolve => (promiseRef.current = div => resolve({ div, close }))
    );
  };

  usePublicApiManagement({ openDivModal });

  return (
    <Modal
      {...{
        isOpen,
        close,
        onClosedDelegate,
        modalType,
        forceDOMRewrite: true
      }}
    >
      {isDivRendered && (
        <div className={styles.publicDivModal} ref={promiseRef.current}></div>
      )}
    </Modal>
  );
};

PublicDivModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired
};

export default PublicDivModal;
