import { storePresentation } from "@asosteam/asos-web-store-presentation-node";
import logger from "../helpers/logger";
import env from "../env";

const oneMinute = 1000 * 60;
let services;

export const setupStorePresentation = () =>
  (services = storePresentation({
    storeApiEndpoint: env("ASOS_STORE_API_ENDPOINT"),
    storeExpiryMs: oneMinute,
    versionManifestEndpoint: env("VERSION_MANIFEST_ENDPOINT"),
    versionManifestExpiryMs: oneMinute,
    warn: logger.warn,
    environmentMode: env("CLIENT_ENVIRONMENT_MODE")
  }));

export const getStore = () => services.store;

export const getSites = async () => {
  const defaultCountries = (await getStore().getDefaultCountries()).filter(
    ({ storeCode }) => storeCode !== "RU"
  );

  const uniqueByUrl = new Map(
    defaultCountries.map(({ url, ...rest }) => [url, { ...rest }])
  );

  const sites = [...uniqueByUrl].map(
    ([url, { countryCode, imageUrl, name }]) => ({
      countryCode,
      url: `${url}/`,
      imageUrl,
      name
    })
  );

  return sites;
};
