import { SET_FEATURES } from "./constants";

export { setFeatures } from "./actions";

export {
  getFeature,
  isStoreSpecificFeatureActive,
  getFeaturesLoaded
} from "./selectors";

const initialState = {
  loadComplete: false
};

export const reduce = (state = initialState, action) => {
  switch (action.type) {
    case SET_FEATURES:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
