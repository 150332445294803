import { connect } from "react-redux";
import SubTotal from "./SubTotal";
import {
  getStoreCode,
  getCountryCode
} from "../../../../state/modules/regionalStore";
import { getTotalPriceText } from "../../../../state/modules/bag";

const mapStateToProps = state => ({
  text: getTotalPriceText(state),
  storeCode: getStoreCode(state),
  countryCode: getCountryCode(state)
});

export default connect(mapStateToProps)(SubTotal);
