import { connect } from "react-redux";
import WithTargetedContent from "./WithTargetedContent";
import {
  requestTargetedContent,
  getGlobalBannerContent,
  getContentTargetingStatus
} from "../../state/modules/contentTargeting";
import { isLoggedIn, getIdentityHasResponded } from "../../state/modules/user";
import {
  getSubscriptionsFromSDK,
  getSubscriptionsHasResponded,
  hasSubscriptions
} from "../../state/modules/subscriptions";

import {
  getAudiencesFromSDK,
  getAudienceHasResponded,
  getAudienceSegments
} from "../../state/modules/audience";

import {
  getStoreCode,
  getDefaultLanguage,
  getCountryCode,
  getKeyStoreDataversion
} from "../../state/modules/regionalStore";

const mapStateToProps = state => {
  return {
    content: getGlobalBannerContent(state),
    country: getCountryCode(state),
    store: getStoreCode(state),
    lang: getDefaultLanguage(state),
    keystoredataversion: getKeyStoreDataversion(state),
    isLoggedIn: isLoggedIn(state),
    identityHasResponded: getIdentityHasResponded(state),
    contentTargetingStatus: getContentTargetingStatus(state),
    hasSubscriptions: hasSubscriptions(state),
    audienceSegments: getAudienceSegments(state),
    subscriptionsHasResponded: getSubscriptionsHasResponded(state),
    audienceHasResponded: getAudienceHasResponded(state)
  };
};

const mapDispatchToProps = {
  requestTargetedContent,
  getSubscriptionsFromSDK,
  getAudiencesFromSDK
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithTargetedContent);
