import { fullPageNavigationDecider } from "../navigationBroker";

const buildListItemMainContent = ({ Url, Label }) => {
  if (Url) {
    const link = document.createElement("a");
    link.setAttribute("href", Url);
    link.textContent = Label;
    link.onclick = event => {
      fullPageNavigationDecider(event.target.href, event);
    };

    return link;
  }

  return document.createTextNode(Label);
};

export default buildListItemMainContent;
