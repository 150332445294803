import {
  OPEN_COUNTRY_SELECTOR,
  CLOSE_COUNTRY_SELECTOR,
  SET_COUNTRY,
  LOAD_COUNTRY,
  LOAD_CURRENCY,
  SET_CURRENCY
} from "./constants";
import { SET_BAG } from "../bag";
import clientConfig from "../../../clientConfig";
import { noticeError } from "../../../../helpers/monitoring";
import {
  asosGlobalNamespace,
  sdkGlobalNamespace
} from "../../../../template/constants";
import isFeatureEnabled from "@template/helpers/checkFeatureIsEnabled";
import removeGBP from "@template/helpers/removeGBP";

export const openCountrySelector = (country, referrer) => {
  return (dispatch, getState) => {
    dispatch({ type: OPEN_COUNTRY_SELECTOR, referrer });

    const { regionalStore } = getState();
    return dispatch(
      setCountry(
        country ? country : regionalStore.countryCode,
        regionalStore.defaultLanguage
      )
    );
  };
};

export const closeCountrySelector = () => ({
  type: CLOSE_COUNTRY_SELECTOR
});

export const setCountry = (country, defaultLanguage) => {
  return async (dispatch, getState, { api }) => {
    try {
      const state = getState();
      const platform = state.layout.platform;
      dispatch({ type: LOAD_COUNTRY });
      let response = await api.fetchCountry(country, defaultLanguage, platform);

      if (
        country === "IE" &&
        (await isFeatureEnabled("stc-force-currency-update"))
      ) {
        response = removeGBP(response);
      }
      return dispatch({ type: SET_COUNTRY, payload: response.data });
    } catch (err) {
      return dispatch({ type: SET_COUNTRY, error: true, payload: err });
    }
  };
};

export const setCurrency = async currencyCode => {
  const sdk = window[asosGlobalNamespace][sdkGlobalNamespace];

  if (sdk?.bag && currencyCode) {
    return await sdk.bag.updateCurrency(currencyCode);
  }
};

export const updatePreferences = (
  countryCode,
  currencyCode,
  storeUrl,
  initialCountryCode,
  affiliateId,
  ctaRef
) => {
  return async dispatch => {
    try {
      if (countryCode === initialCountryCode) {
        dispatch({ type: LOAD_CURRENCY });
        const res = await setCurrency(currencyCode);
        dispatch({ type: SET_CURRENCY });
        if (res && res.bag && res.bag.summary) {
          dispatch({ type: SET_BAG, payload: res.bag.summary });
        }
      }
      const parsedUrl = new URL(
        storeUrl || window.location.href,
        window.location.origin
      );
      const xSiteOrigin = clientConfig.getXSiteOrigin();

      const searchQueries = {
        ctaref: ctaRef,
        browseCountry: countryCode,
        browseCurrency: currencyCode,
        xaffid: affiliateId,
        "x-site-origin": xSiteOrigin,
        r: 1
      };

      Object.keys(searchQueries).forEach(key => {
        if (searchQueries[key])
          parsedUrl.searchParams.set(key, searchQueries[key]);
      });

      window.location.href = parsedUrl.toString();
    } catch (err) {
      noticeError(new Error(JSON.stringify(err.message)));
      dispatch({ type: SET_CURRENCY, payload: err.message, error: true });
      return false;
    }
  };
};
