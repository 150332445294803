import logger from "../helpers/logger";
import { getNavigationSubtreeUrl } from "./apiUrls";
import { mapNavigationState } from "./navigation/mapNavigationState";
import { validateMappedNavigation } from "./navigation/navigationUtils";
import request from "./request";

const fetchClientNavigation = async ({
  country,
  lang,
  dateTime,
  includeDraft,
  showTestContent,
  platform,
  storeUrl,
  keyStoreDataversion,
  variantKey
} = {}) => {
  const url = getNavigationSubtreeUrl({
    country,
    lang,
    keyStoreDataversion,
    dateTime,
    includeDraft,
    showTestContent,
    variantKey
  });

  logger.info("Fetching navigation API from", url);
  const navigation = await request(url);

  const mappedNavigation = {
    countryCode: country,
    tree: mapNavigationState(navigation, null, null, platform, storeUrl)
  };

  if (!validateMappedNavigation(mappedNavigation)) {
    throw new Error("Nav API response not valid");
  }

  return mappedNavigation;
};

export default fetchClientNavigation;
