import { useEffect, useRef } from "react";
import BlockScroll from "@src/helpers/blockScroll";

export const useBlockScroll = enabled => {
  const blockScroll = useRef();

  useEffect(() => {
    blockScroll.current = new BlockScroll();
  }, []);

  useEffect(() => {
    if (enabled) {
      blockScroll.current.enable();
    } else {
      blockScroll.current.disable();
    }
  }, [enabled]);
};
