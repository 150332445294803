// Tracking Events
export const TRACKING_ONETRUST_INTERACTION = "tracking.onetrust.interaction";
export const TRACKING_MINIBAG_INTERACTION =
  "tracking.miniBag.miniBagInteraction";

// Data Object Keys
export const ADOBE_PAGE_NAME_KEY = "pageName";
export const ADOBE_EVENTS_KEY = "events";
export const ADOBE_PRODUCTS_KEY = "products";

// eVar Keys
export const ADOBE_GENERIC_ACTION_KEY = "eVar106";
export const ADOBE_COOKIE_CONSENT_KEY = "eVar224";
export const ADOBE_VISITS_PAGE_LOAD_COUNT_KEY = "eVar225";

// Event Values
export const ADOBE_EVENT_COOKIE_CONSENT_CLICK = "event310";
export const ADOBE_EVENT_CART_REMOVALS = "scRemove";
export const ADOBE_EVENT_CART_VIEW = "scView";

// Link Types
export const ADOBE_LINK_TYPE_CUSTOM_LINK = "o";

// Link Names
export const ADOBE_LINK_NAME_COOKIE_CONSENT_SUBMISSION =
  "Cookie Consent Submission";
export const ADOBE_LINK_NAME_MINI_BAG_PRODUCT_DELETE =
  "mini bag product delete";

// Misc
export const ADOBE_LINK_OBJECT_DO_NOT_WAIT = true;
