import { connect } from "react-redux";
import NativeAppBanner from "./NativeAppBanner";
import { getStoreCode } from "../../state/modules/regionalStore";

function mapStateToProps(state) {
  return {
    welcomeMessage: state.welcomeMessage,
    storeCode: getStoreCode(state)
  };
}

export default connect(mapStateToProps)(NativeAppBanner);
