import isFeatureEnabled from "@template/helpers/checkFeatureIsEnabled";
import setNewBrowseCurrency from "@src/helpers/setNewBrowseCurrency";

const converteeCurrency = "GBP";
const newCurrency = "EUR";

const updateCountryCurrencyList = async webContext => {
  if (
    (await isFeatureEnabled("stc-force-currency-update")) &&
    webContext.getBrowseCurrency() === converteeCurrency
  ) {
    setNewBrowseCurrency(newCurrency);
  }
};

export default updateCountryCurrencyList;
