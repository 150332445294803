import { PureComponent } from "react";
import Headroom from "react-headroom";
import PropTypes from "prop-types";
import {
  viewportPropType,
  LARGE_VIEWPORT
} from "@template/state/modules/layout";
import classNames from "classnames/bind";
import styles from "./NotQuiteStickyHeader.css";
import { eventBus } from "@src/helpers/eventing/events";
import { asosGlobalNamespace } from "@src/template/constants";

const cx = classNames.bind(styles);
export default class NotQuiteStickyHeader extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    viewportCategory: viewportPropType,
    isSidePanelOpen: PropTypes.bool.isRequired,
    featuresLoaded: PropTypes.bool.isRequired
  };

  static defaultProps = { featuresLoaded: false };

  getPinStart = () => {
    const miscBarHeight =
      this.props.viewportCategory === LARGE_VIEWPORT ? 30 : 0;

    return miscBarHeight;
  };

  updateHeadRoom = () => {
    this.headroom && this.headroom.handleResize();
    eventBus.addListener("sc-sticky-header-update", this.updateHeadRoom);
  };

  componentDidMount() {
    this.updateHeadRoom();
    window[asosGlobalNamespace].eventBus.addListener(
      "sdk-ready",
      this.updateHeadRoom
    );
  }

  componentWillUnmount() {
    window[asosGlobalNamespace].eventBus.removeAllListeners(
      "sdk-ready",
      this.updateHeadRoom
    );
  }

  render() {
    return (
      <Headroom
        className={cx("stickyHeader", {
          stickyHeader__sidePanelOpen: this.props.isSidePanelOpen
        })}
        disableInlineStyles={true}
        pinStart={this.getPinStart}
        id="chrome-sticky-header"
        upTolerance={17}
        ref={d => (this.headroom = d)}
      >
        {this.props.children}
        <div id="sc-sticky-header-portal" />
      </Headroom>
    );
  }
}
