import {
  asosGlobalNamespace,
  siteChromeGlobalNamespace
} from "@template/constants";
import { MODAL_TYPES } from "../constants";
import { MODALS_NAMESPACE_KEY } from "./constants";

export default () => {
  global[asosGlobalNamespace][siteChromeGlobalNamespace][MODALS_NAMESPACE_KEY] =
    {
      modalTypes: MODAL_TYPES
    };
};
