import { connect } from "react-redux";
import DeliveryProposition from "./DeliveryProposition";
import {
  getDefaultStoreUrl,
  getDefaultLanguage,
  getCountryCode
} from "../../../../state/modules/regionalStore";

const mapStateToProps = state => ({
  defaultStoreUrl: getDefaultStoreUrl(state),
  lang: getDefaultLanguage(state),
  countryCode: getCountryCode(state)
});

export default connect(mapStateToProps)(DeliveryProposition);
