export const asosGlobalNamespace = "asos";
export const siteChromeGlobalNamespace = "siteChrome";
export const sdkGlobalNamespace = "sdk";
export const customerGlobalNamespace = "customer";
export const subscriptionsSdkGlobalNamespace = "subscriptions";
export const audiencesSdkGlobalNamespace = "audience";
export const savedItemsSdkGlobalNamespace = "savedItemsSdk";
export const manifestWindowKey = "_versionManifest";
export const featureManifestWindowKey = "featureManifest";
export const siteChromeInitialStore = "_initialStore";
export const siteChromeInitialBreadcrumbs = "_initialBreadcrumbs";
export const siteChromeTokenValues = "_tokenValues";
export const RECENT_SEARCHES_KEY = "_asosRecentSearches";
export const siteChromeClientConfig = "_config";
export const clientEnvPrefix = "CLIENT_";
export const siteChromeTokenValuePlatform = "platform";
export const siteChromeTokenValueGender = "gender";

export const APP_ROUTES = {
  BAG_LINK: "bag",
  MY_ACCOUNT_SIGNUP_LINK: "register",
  SAVED_ITEMS_LINK: "saved-lists/"
};

export const RSFT_WELCOME_MESSAGE_COOKIE_NAME = "stc-welcome-message";
export const RSFT_USER_TOOK_ACTION_ON_WELCOME_MESSAGE_KEY =
  "userTookActionOnWelcomeMessage";
export const RSFT_RESOLVED_DELIVERY_COUNTRY_KEY = "resolvedDeliveryCountry";
export const RSFT_CAPPED_PAGE_COUNT_KEY = "cappedPageCount";
export const RSFT_DELIVERY_OPTIONS_CACHE_TTL_MS = 1000 * 60 * 60 * 24; // 1 day
export const RSFT_DELIVERY_OPTIONS_LOCAL_STORAGE_PREFIX =
  "Asos.SiteChrome.DeliveryOptions";

export const MOBILE = "mobile";
export const DESKTOP = "desktop";
