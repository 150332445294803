const HARNESS_PATH_SEGMENT = ["harness"];

const PDP_PATH_SEGMENTS = ["prd", "grp"];

export const PDP_MVT_PATH_SEGMENTS = [
  ...PDP_PATH_SEGMENTS,
  ...HARNESS_PATH_SEGMENT
];

export const VALID_PATH_SEGMENTS = [
  "saved-lists",
  "shared-board",
  ...PDP_PATH_SEGMENTS,
  ...HARNESS_PATH_SEGMENT
];
