import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames/bind";
import styles from "./LazyImage.css";
import { BACKGROUND_LOADING_COLORS } from "./constants";

const cx = classnames.bind(styles);

export default class LazyImage extends Component {
  static propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    tag: PropTypes.string.isRequired,
    children: PropTypes.node,
    dispatch: PropTypes.func,
    loadingColor: PropTypes.oneOf(Object.values(BACKGROUND_LOADING_COLORS)),
    style: PropTypes.object
  };

  state = {};

  setRef = node => {
    this.node = node;
  };

  componentDidMount() {
    if (typeof IntersectionObserver === "undefined") {
      this.setImageSourceFromProps();
      return;
    }

    this.observer = new IntersectionObserver(
      this.updateSourceIfElementIsInView
    );
    this.observer.observe(this.node);
  }

  updateSourceIfElementIsInView = entries => {
    const [elementEntry] = entries;

    if (elementEntry.isIntersecting) {
      this.setImageSourceFromProps();
      this.observer.disconnect();
    }
  };

  setImageSourceFromProps() {
    const { src: source } = this.props;
    this.setState({ source });
  }

  renderImageEl() {
    /* eslint-disable no-unused-vars */
    const { alt, tag, children, src, dispatch, loadingColor, ...restProps } =
      this.props;
    /* eslint-enable no-unused-vars */

    const { source } = this.state;
    restProps.className = cx(
      restProps.className,
      styles.lazyImage,
      loadingColor
    );

    return <img {...restProps} alt={alt} src={source} ref={this.setRef} />;
  }

  renderBackgroundImageEl() {
    /* eslint-disable no-unused-vars */
    const {
      alt,
      tag,
      children,
      src,
      dispatch,
      loadingColor,
      style,
      ...restProps
    } = this.props;
    /* eslint-enable no-unused-vars */

    const { source } = this.state;
    restProps.className = cx(
      restProps.className,
      styles.lazyImage,
      loadingColor
    );
    const bgStyle = source
      ? {
          ...style,
          backgroundImage: `url(${source})`
        }
      : style;

    switch (tag) {
      case "div":
        return (
          <div {...restProps} style={bgStyle} ref={this.setRef}>
            {children}
          </div>
        );
      case "a":
        return (
          <a {...restProps} style={bgStyle} ref={this.setRef}>
            {children}
          </a>
        );
      case "button":
        return (
          <button {...restProps} style={bgStyle} ref={this.setRef}>
            {children}
          </button>
        );
    }
  }

  render() {
    const { tag } = this.props;

    if (tag === "img") {
      return this.renderImageEl();
    } else {
      return this.renderBackgroundImageEl();
    }
  }
}
