import { fetchSocialTags } from "@src/api/index";
import {
  removeEntriesByCacheKeyPrefix,
  getOrSetInLocalStorageWithExpiryAsync
} from "@src/helpers/webStorage";
import { noticeError } from "@src/helpers/monitoring";

const cacheKeyPrefix = "Asos.SiteChrome.SocialTags";
const oneDayInMs = 86400000;

const socialTagsGetter = async customerId => {
  const { data: value, headers } = await fetchSocialTags(customerId);
  const timeToLiveMs =
    headers?.["cache-control"]?.match(/max-age=(\d+)/)?.[1] * 1000 ||
    oneDayInMs;

  return { value, timeToLiveMs };
};

const getByCacheKeyPrefix = cacheKeyPrefix => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key.startsWith(cacheKeyPrefix)) {
      return localStorage.getItem(key);
    }
  }
};

const getSocialTags = async ({ isAnonymous, customerId }) => {
  let socialTags;

  try {
    if (isAnonymous) {
      const cachedSocialTags = getByCacheKeyPrefix(cacheKeyPrefix);
      if (cachedSocialTags) {
        const { expiry, value } = JSON.parse(cachedSocialTags);
        const cacheExpired = expiry < new Date().getTime();

        if (cacheExpired) {
          removeEntriesByCacheKeyPrefix(cacheKeyPrefix);
        } else {
          socialTags = value;
        }
      }
    }

    if (!isAnonymous) {
      socialTags = await getOrSetInLocalStorageWithExpiryAsync(
        {
          cacheKeyPrefix,
          returnCachedOnDelegateFailure: true,
          removeOtherCacheItems: true,
          localStorageValueDelegate: socialTagsGetter
        },
        customerId
      );
    }
  } catch (error) {
    noticeError(error);
  }

  return socialTags;
};

export default getSocialTags;
