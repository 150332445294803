import PropTypes from "prop-types";

import { withTranslation } from "@template/components/translation";
import BagLinks from "@template/components/MiniBag/Footer/BagLinks";
import IconButton from "@template/components/IconButton";
import { tabIndex } from "@src/helpers/accessibility";

import { DEFAULT_MESSAGE } from "@src/template/state/modules/notifications";

import styles from "./index.css";

const CTASContent = ({ hide, onClick, refCallback, formatTranslation }) => (
  <div className={styles.content}>
    <p role="status" className={styles.message}>
      {formatTranslation(DEFAULT_MESSAGE)}
    </p>
    <IconButton
      aria-label={formatTranslation("icon_close")}
      icon={styles.close}
      size="small"
      className={styles.closeContainer}
      tabIndex={tabIndex(!hide)}
      onClick={onClick}
      refCallback={refCallback}
    />
    <BagLinks useMvtStyles />
  </div>
);

CTASContent.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  refCallback: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  hide: PropTypes.bool.isRequired
};

export default withTranslation(CTASContent);
