import Cookies from "js-cookie";

const oneYearInDays = 365;

export const getCookieValue = cookieName =>
  Cookies.get(cookieName) || undefined;

export const setCookieValue = (cookieName, value, options = {}) => {
  if (!options.expires) {
    options.expires = oneYearInDays;
  }

  Cookies.set(cookieName, value, options);
};

export const removeCookie = (name, domain, path = "/") => {
  Cookies.remove(name, {
    domain,
    path
  });
};

const findKeyValuePair = (values, key) => {
  return values
    ? values.split("&").filter(keyValue => keyValue.includes(`${key}=`))[0]
    : null;
};

const concatenateKeyValuePairs = (cookieValue, newKey, newValue) => {
  if (cookieValue) {
    const keyValueInCookie = findKeyValuePair(cookieValue, newKey);
    if (keyValueInCookie) {
      return cookieValue.replace(keyValueInCookie, `${newKey}=${newValue}`);
    } else {
      return `${cookieValue}&${newKey}=${newValue}`;
    }
  }
  return `${newKey}=${newValue}`;
};

export const setKeyedCookieValue = (
  cookieName,
  key,
  value,
  expires = oneYearInDays
) => {
  const options = { expires };
  setCookieValue(
    cookieName,
    concatenateKeyValuePairs(getCookieValue(cookieName), key, value),
    options
  );
};

export const getKeyedCookieValue = (cookieName, key) => {
  const keyValuePair = findKeyValuePair(getCookieValue(cookieName), key);
  return keyValuePair ? keyValuePair.split("=")[1] : null;
};
