export const dedupeNlidQueryString = nlidParams => {
  /**
   * Created that function for merging duplicate "nlid" query string present in link from API
   * and the generated one
   *
   * - merging array of nlid strings and stringify array joining with |
   * - removing duplicate
   */
  return Array.from(new Set(nlidParams.join("|").split("|"))).join("|");
};
