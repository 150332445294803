/* eslint-disable no-case-declarations */
import get from "@src/helpers/get";
import concatValues from "./concatValues";
import {
  ANALYTICS_CONTEXT,
  ANALYTICS_INTERACTION_CLOSE,
  ANALYTICS_INTERACTION_NAVIGATE,
  ANALYTICS_INTERACTION_IMPRESSION
} from "@src/helpers/eventing/events";
import { trackCookieConsentBannerClick } from "./events/trackCookieConsentBannerClick";
import { trackMiniBagProductDeleteClick } from "./events/trackMiniBagProductDeleteClick";
import {
  TRACKING_ONETRUST_INTERACTION,
  TRACKING_MINIBAG_INTERACTION
} from "./constants";
import { createCommonAnalytics } from "./commonAnalytics";
import { adobeSdkProvider } from "./adobeSdkProvider";

let commons;

export const eventHandlers = {
  "tracking.appbanner.installTheApp": function (platform) {
    s = adobeSdkProvider();
    const overrides = {};
    overrides.linkTrackVars = commons.setLinkTrackVars(["prop41", "prop42"]);
    overrides.linkTrackEvents = commons.setLinkTrackEvents(["event73"]);

    s.events = "event73";
    s.prop41 = s.prop8;
    s.prop42 = platform; // iOS, Android
    s.tl(true, "o", "Download App via the Smart Banner", overrides);
  },
  "tracking.navigation.pageInteraction": function (payload) {
    s = adobeSdkProvider();
    s.linkTrackVars =
      "channel,server,hier1,eVar60,eVar61,prop1,prop2,prop3,prop5,prop8,prop11";
    const context = get(payload, "context");
    const interaction = get(payload, "interaction");
    const elementText = get(payload, "elementText");
    let linkType = "o";
    const overrides = {}; // Custom
    s.prop5 = s.getCustomTimeDate().toString();
    switch (context) {
      case ANALYTICS_CONTEXT.welcomeMessage:
        overrides.linkTrackVars = commons.setLinkTrackVars([
          "eVar10",
          "eVar12",
          "eVar17",
          "eVar19",
          "eVar60",
          "eVar61",
          "eVar81",
          "eVar112",
          "eVar113",
          "eVar121",
          "eVar122",
          "eVar125"
        ]);
        overrides.linkTrackEvents = commons.setLinkTrackEvents([
          "event2",
          "event249",
          "event250"
        ]);

        if (interaction === ANALYTICS_INTERACTION_IMPRESSION) {
          if (payload.properties) {
            const { deliveryCountry, currentBrowseCountry, currentCurrency } =
              payload.properties;

            s.eVar81 = deliveryCountry;
            s.eVar112 = currentBrowseCountry;
            s.eVar113 = currentCurrency;
          }
          s.events = "event249";
        }
        if (interaction === ANALYTICS_INTERACTION_CLOSE) {
          s.events = "event250";
        }
        if (interaction === ANALYTICS_INTERACTION_NAVIGATE) {
          const {
            countryCode: currentCountryCode,
            storeCode: currentStoreCode,
            siteId: currentSiteId
          } = payload.properties.current;
          const {
            countryCode: newCountryCode,
            storeCode: newStoreCode,
            siteId: newSiteId
          } = payload.properties.new;
          s.events = "event2";
          s.eVar121 = concatValues([currentCountryCode, newCountryCode]);
          s.eVar122 = concatValues([currentSiteId, newSiteId]);
          s.eVar125 = concatValues([currentStoreCode, newStoreCode]);
        }
        s.prop40 = concatValues([context, interaction, elementText]);
        break;
      case ANALYTICS_CONTEXT.social:
        overrides.linkTrackVars = commons.setLinkTrackVars(["prop40"]);
        overrides.linkTrackEvents = commons.setLinkTrackEvents(["event120"]);
        s.prop40 = concatValues([context, interaction, elementText]);
        s.events = "event120";
        linkType = "e"; // Exit link
        break;
      case ANALYTICS_CONTEXT.deliverElseWhere:
      case ANALYTICS_CONTEXT.deliverToDeliveryCountry:
      case ANALYTICS_CONTEXT.dismissCountrySelector:
      case ANALYTICS_CONTEXT.countrySelector:
        const isCountryUpdateManual = get(
          payload,
          "properties.formFields.country.isUpdateManual"
        );
        const isCurrencyUpdateManual = get(
          payload,
          "properties.formFields.currency.isUpdateManual"
        );
        const siteIDFrom = get(
          payload,
          "properties.formFields.country.siteIDFrom"
        );
        const siteIDTo = get(payload, "properties.formFields.country.siteIDTo");
        const countryISOFrom = get(
          payload,
          "properties.formFields.country.countryISOFrom"
        );
        const countryISOTo = get(
          payload,
          "properties.formFields.country.countryISOTo"
        );
        const languageTextFrom = get(
          payload,
          "properties.formFields.language.languageTextFrom"
        );
        const languageTextTo = get(
          payload,
          "properties.formFields.language.languageTextTo"
        );
        const currencyISOFrom = get(
          payload,
          "properties.formFields.currency.currencyISOFrom"
        );
        const currencyISOTo = get(
          payload,
          "properties.formFields.currency.currencyISOTo"
        );
        const storeFrom = get(
          payload,
          "properties.formFields.country.storeFrom"
        );
        const storeTo = get(payload, "properties.formFields.country.storeTo");
        const resolvedDeliveryCountry = get(
          payload,
          "properties.resolvedDeliveryCountry"
        );

        overrides.linkTrackVars = commons.setLinkTrackVars(
          "eVar81,eVar115,eVar112,eVar121,eVar122,eVar114,eVar123,eVar113,eVar124,eVar125".split(
            ","
          )
        );

        // change country
        if (countryISOFrom !== countryISOTo) {
          s.eVar81 = resolvedDeliveryCountry;
          s.eVar112 = countryISOTo;
          s.eVar115 = siteIDTo;
          s.eVar121 = concatValues([siteIDFrom, siteIDTo]);
          s.eVar122 = concatValues([countryISOFrom, countryISOTo]);
        }
        // change language
        if (languageTextFrom !== languageTextTo) {
          s.eVar114 = languageTextTo;
          s.eVar123 = concatValues([languageTextFrom, languageTextTo]);
        }
        // change currency
        if (currencyISOFrom !== currencyISOTo) {
          s.eVar113 = currencyISOTo;
          s.eVar124 = concatValues([currencyISOFrom, currencyISOTo]);
        }

        const events = [];
        if (isCountryUpdateManual) {
          events.push("event2");
        }
        if (isCurrencyUpdateManual) {
          events.push("event100");
        }
        if (context === ANALYTICS_CONTEXT.dismissCountrySelector) {
          events.push("event289");
        }
        if (events.length > 0) {
          const joinedEvents = events.join(",");
          overrides.linkTrackEvents = commons.setLinkTrackEvents([
            joinedEvents
          ]);
          s.events = joinedEvents;
        }

        if (interaction === "saved") {
          const platform = (s.server && s.server.split(" ")[0]) || "undefined";
          const server = [platform, storeTo].join(" ");
          s.server = server;
          s.eVar125 = concatValues([storeFrom, storeTo]);
        }
        break;
      default:
        return;
    }
    s.tl(true, linkType, context, overrides);
  },

  [TRACKING_MINIBAG_INTERACTION]: trackMiniBagProductDeleteClick,

  "tracking.identity.userStatus": function (identityStatus) {
    const overrides = {};
    overrides.linkTrackVars = commons.setLinkTrackVars(["prop31,eVar155"]);
    overrides.linkTrackEvents = commons.setLinkTrackEvents([
      "event17,event232,event253"
    ]);
    s = adobeSdkProvider();

    if (identityStatus == "1") {
      s.eVar155 = "social";
      s.events = "event17,event232";
      s.prop31 = "logged in";
    } else if (identityStatus == "2") {
      s.eVar155 = "email";
      s.events = "event17,event232";
      s.prop31 = "logged in";
    } else if (identityStatus == "3") {
      s.events = "event17,event253";
      s.prop31 = "logged in";
    } else {
      s.events = "event17";
      s.prop31 = "logged in";
    }

    s.tl(true, "o", "user login status", overrides);
  },
  [TRACKING_ONETRUST_INTERACTION]: trackCookieConsentBannerClick,
  "tracking.experiments": function (eventParams) {
    s = adobeSdkProvider();

    const experimentKey = eventParams.experimentKey;
    const experimentVariation = eventParams.experimentVariation;
    const trackingAlreadyFired = !!window["s_i_" + s.account];
    const forceTracking = trackingAlreadyFired
      ? eventParams.forceTracking
      : false;

    const overrides = {
      linkTrackVars: commons.setLinkTrackVars(["eVar9"])
    };

    let isNewExperiment = true;
    s.eVar9 = s.eVar9 || "";
    const experiments = s.eVar9.split("|");
    const updatedExperiments = experiments.map(function (experiment) {
      const key = experiment.split(":")[0];
      if (key === experimentKey) {
        isNewExperiment = false;
        return experimentKey + ":" + experimentVariation;
      }
      return experiment;
    });

    if (isNewExperiment) {
      s.eVar9 +=
        (s.eVar9 !== "" ? "|" : "") + experimentKey + ":" + experimentVariation;
    } else {
      s.eVar9 = updatedExperiments.join("|");
    }

    if (forceTracking) {
      s.tl(true, "o", "experiment tracking", overrides);
    }
  }
};

export const initialiseAdobeAnalytics = () => {
  commons = createCommonAnalytics(eventHandlers);
};
