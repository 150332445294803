import uuid from "uuid";
import { isIos } from "@src/helpers/deviceDetection";
import {
  addOverflowHiddenToBody,
  removeOverflowHiddenFromBody
} from "@src/helpers/toggleBodyLock";

const enableLocks = new Set();

class BlockScroll {
  constructor() {
    this.startY = 0;
    this.executionContextId = uuid.v4();
  }

  handleTouchmove(evt) {
    let el = evt.target;

    const zoom =
      window.innerWidth / window.document.documentElement.clientWidth;
    if (evt?.touches?.length > 1 || zoom !== 1) {
      return;
    }

    while (el !== document.body && el !== document) {
      const style = window.getComputedStyle(el);

      if (!style) {
        break;
      }

      const overflowX = style.getPropertyValue("overflow-x");
      if (overflowX === "scroll") {
        return;
      }

      if (el.nodeName === "INPUT" && el.getAttribute("type") === "range") {
        return;
      }

      const overflowY = style.getPropertyValue("overflow-y");
      const height = parseInt(style.getPropertyValue("height"), 10);

      const isScrollable = overflowY === "auto" || overflowY === "scroll";
      const canScroll = el.scrollHeight > el.offsetHeight;

      if (isScrollable && canScroll) {
        const curY = evt.touches[0].screenY;
        const isAtTop = this.startY <= curY && el.scrollTop === 0;
        const isAtBottom =
          this.startY >= curY && el.scrollHeight - el.scrollTop === height;

        if (isAtTop || isAtBottom) {
          evt.preventDefault();
        }

        return;
      }

      el = el.parentNode;
    }

    // Stop the bouncing -- no parents are scrollable
    evt.preventDefault();
  }

  handleTouchstart(evt) {
    this.startY = evt.touches ? evt.touches[0].screenY : evt.screenY;
  }

  enable() {
    addOverflowHiddenToBody();

    if (isIos()) {
      window.addEventListener("touchstart", this.handleTouchstart, {
        passive: false
      });
      window.addEventListener("touchmove", this.handleTouchmove, {
        passive: false
      });
    }

    enableLocks.add(this.executionContextId);
  }

  disable() {
    enableLocks.delete(this.executionContextId);
    if (enableLocks.size > 0) {
      return;
    }

    removeOverflowHiddenFromBody();

    if (isIos()) {
      window.removeEventListener("touchstart", this.handleTouchstart, false);
      window.removeEventListener("touchmove", this.handleTouchmove, false);
    }
  }

  isEnabled() {
    return enableLocks.size > 0;
  }
}

export default BlockScroll;
