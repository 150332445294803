import { connect } from "react-redux";
import { openCountrySelector } from "@template/state/modules/countrySelector";
import { getCountry } from "@template/state/modules/welcomeMessage";
import RsftWelcomeMessage from "./RsftWelcomeMessage";

const mapStateToProps = state => ({
  country: getCountry(state)
});

const mapDispatchToProps = {
  openCountrySelector
};

export default connect(mapStateToProps, mapDispatchToProps)(RsftWelcomeMessage);
