import {
  sdk,
  isUnauthorisedTokenError,
  isProfileNotFoundError
} from "@asosteam/asos-web-customer-profile-sdk";
import { noticeError } from "@src/helpers/monitoring";

export const getSubscriptionDetailsFactory =
  context =>
  async ({ getCustomerId, getAuthHeader }) => {
    const logger = {
      error: noticeError
    };

    const config = {
      ...context,
      logger: logger,
      customerId: await getCustomerId(),
      authHeader: await getAuthHeader(),
      fields: ["subscriptions"]
    };

    let subscriptions;
    try {
      ({ subscriptions } = await sdk(config));
    } catch (error) {
      if (!isUnauthorisedTokenError(error) && !isProfileNotFoundError(error)) {
        subscriptions = [];
      }
    }

    return subscriptions;
  };
