import { PropTypes } from "prop-types";
import ResultList from "../ResultList";
import ResultItems from "../ResultItems";

const SuggestionsList = ({ activeIndex, items, onSelect, term }) => (
  <ResultList>
    <ResultItems
      activeIndex={activeIndex}
      items={items}
      onSelect={onSelect}
      term={term}
    />
  </ResultList>
);

SuggestionsList.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      searchTerm: PropTypes.string.isRequired,
      numberOfResults: PropTypes.number
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  term: PropTypes.string
};

export default SuggestionsList;
