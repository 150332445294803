import { getContext } from "@src/client/context";
import { RECEIVE_WELCOME_MESSAGE } from "./constants";
import {
  correctInvalidKeyStoreDataversion,
  finishSettingCustomAttributeFromPreviousRequest
} from "./invalidKeyStoreDataversionHandling";

export const disableWelcomeMessage = () => ({
  type: RECEIVE_WELCOME_MESSAGE,
  payload: { display: false }
});

export const requestWelcomeMessage = (platform, resolvedDeliveryCountry) => {
  return (dispatch, getState, { api: { fetchWelcomeMessage } }) => {
    const {
      regionalStore: { countryCode: browseCountry }
    } = getState();

    const context = getContext();
    const geoCountry = context.getGeoCountry();

    fetchWelcomeMessage(
      geoCountry,
      browseCountry,
      platform,
      resolvedDeliveryCountry
    ).then(
      res => {
        finishSettingCustomAttributeFromPreviousRequest();
        dispatch({ type: RECEIVE_WELCOME_MESSAGE, payload: res });
      },
      error => {
        if (
          error.status === 400 &&
          error.responseText.includes('errorCode":"STR003"')
        ) {
          correctInvalidKeyStoreDataversion();
        }
      }
    );
  };
};
