import { useEffect, useRef, useState } from "react";
import { getPolyglot } from "./getPolyglot";
import { useSelector } from "react-redux";
import {
  getDefaultLanguage,
  getPhrases
} from "../../state/modules/regionalStore";
import useExperimentalTranslations from "./useExperimentalTranslations";

const useFormatter = () => {
  const language = useSelector(getDefaultLanguage);
  const [phrases, overridePhrases] = useState(useSelector(getPhrases));
  const { current: polyglot } = useRef(getPolyglot(language, phrases), [
    language,
    phrases
  ]);

  useExperimentalTranslations(overridePhrases, language);

  useEffect(() => {
    polyglot.extend(phrases);
  }, [polyglot, phrases]);

  return (...args) => polyglot.t(...args);
};

export default useFormatter;
