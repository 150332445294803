import checkFeatureIsEnabled from "@template/helpers/checkFeatureIsEnabled";
import { useEffect } from "react";
import experimentalTranslations from "./experimentalTranslations.json";

const useExperimentalTranslations = (overridePhrases, language) => {
  useEffect(() => {
    Object.entries(experimentalTranslations[language] || {}).forEach(
      async ([experiment, overrides]) => {
        if (await checkFeatureIsEnabled(experiment)) {
          overridePhrases(overrides);
        }
      }
    );
  }, [overridePhrases, language]);
};

export default useExperimentalTranslations;
