import { adobeSdkProvider } from "./adobeSdkProvider";
import { mapToOverrides } from "./mapToOverrides";
import {
  ADOBE_LINK_OBJECT_DO_NOT_WAIT,
  ADOBE_LINK_TYPE_CUSTOM_LINK
} from "./constants";

export const trackLink = ({ name, data }) => {
  const adobeSdk = adobeSdkProvider();

  Object.assign(adobeSdk, data);

  const overrides = mapToOverrides(data);

  adobeSdk.tl(
    ADOBE_LINK_OBJECT_DO_NOT_WAIT,
    ADOBE_LINK_TYPE_CUSTOM_LINK,
    name,
    overrides
  );
};
