/* eslint-disable jsx-a11y/no-redundant-roles */
import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames/bind";
import { genderPropType, FEMALE, DEFAULT } from "../../../state/modules/layout";
import { navigationPropType, PRIMARY } from "../../../state/modules/navigation";
import SideNavigationContainer from "../SideNavigationContainer";
import {
  globalEventHandler,
  events,
  keys,
  focusFloorNavigationFor
} from "../../../globalEventHandler";
import styles from "./index.css";

const cx = classnames.bind(styles);

const isCurrentGender = (gender, currentGender) =>
  gender === currentGender || (gender === FEMALE && currentGender === DEFAULT);

class SidePrimaryNavigation extends Component {
  constructor() {
    super();
    this.selectFirstNavigationItem = this.selectFirstNavigationItem.bind(this);
  }

  /* istanbul ignore next - focus is not supported in jsdom  */
  selectFirstNavigationItem(selectedGender) {
    if (selectedGender === this?.props?.forGender) {
      this.firstItem?.node?.focus();
    }
  }

  /* istanbul ignore next - focus is not supported in jsdom  */
  handleKeyDown(event) {
    if (event.key === keys.tab && event.originalEvent.shiftKey) {
      if (this?.firstItem === document.activeElement) {
        event.originalEvent.preventDefault();
        focusFloorNavigationFor(this.props.forGender);
      }
    }
  }

  componentDidMount() {
    this.removeFloorSelectedEventHandler = globalEventHandler.addListener(
      events.focusFirstPrimaryNavigationItem,
      this.selectFirstNavigationItem
    );

    this.keyDownEventListener = globalEventHandler.addListener(
      events.keyDown,
      this.handleKeyDown
    );
  }

  componentWillUnmount() {
    this.removeFloorSelectedEventHandler &&
      this.removeFloorSelectedEventHandler.remove();
    this.keyDownEventListener && this.keyDownEventListener.remove();
  }

  /* istanbul ignore next - focus is not supported in jsdom  */
  saveNavigationItem = component => {
    if (typeof this.firstItem === "undefined") {
      this.firstItem = component;
    }
  };

  // will use item.display.mobileDisplayLayout to decide the type of the container
  renderContainer = item => (
    <SideNavigationContainer
      key={item.id}
      item={item}
      setNavActiveItem={this.props.setNavActiveItem}
      saveNavigationItem={this.saveNavigationItem}
      sideNavLevel={PRIMARY}
    />
  );

  render() {
    const {
      forGender: gender,
      currentGender,
      navActiveItem,
      items: groupNavigationItems
    } = this.props;

    const visible = isCurrentGender(gender, currentGender);
    const accessible = !navActiveItem && visible;

    return (
      <nav
        className={cx(styles.container, {
          [styles.container__hidden]: !visible
        })}
        aria-hidden={accessible ? null : true}
        data-testid="sidePanel-itemsList"
        role="tabpanel"
        aria-labelledby={`${gender}-floor`}
      >
        <ul className={styles.containerList} role="list">
          {groupNavigationItems.map(this.renderContainer)}
        </ul>
      </nav>
    );
  }
}

SidePrimaryNavigation.propTypes = {
  forGender: genderPropType.isRequired,
  currentGender: genderPropType.isRequired,
  items: PropTypes.arrayOf(navigationPropType).isRequired,
  setNavActiveItem: PropTypes.func.isRequired,
  navActiveItem: PropTypes.string
};

export default SidePrimaryNavigation;
