import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./index.css";

const Icon = ({ icon, size, ...props }) => {
  return (
    <span
      {...props}
      className={classnames(
        icon,
        styles.container,
        styles[size],
        styles.widgetIcon
      )}
    />
  );
};

Icon.defaultProps = {
  size: "medium"
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["small", "medium"]),
  type: PropTypes.string
};

export default Icon;
