import { getKeyedCookieValue } from "@src/client/context/cookies";
import {
  RSFT_WELCOME_MESSAGE_COOKIE_NAME,
  RSFT_CAPPED_PAGE_COUNT_KEY
} from "@template/constants";

const getRsftWelcomeMessageCappedPageCount = () => {
  const rsftWelcomeMessageCappedPageCount = getKeyedCookieValue(
    RSFT_WELCOME_MESSAGE_COOKIE_NAME,
    RSFT_CAPPED_PAGE_COUNT_KEY
  );

  return rsftWelcomeMessageCappedPageCount
    ? parseInt(rsftWelcomeMessageCappedPageCount)
    : 0;
};

export default getRsftWelcomeMessageCappedPageCount;
