import { connect } from "react-redux";
import SearchDropdown from "./SearchDropdown";
import { bindActionCreators } from "redux";
import { clearRecentSearches } from "../../state/modules/search";

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ clearRecentSearches }, dispatch);
}

export default connect(null, mapDispatchToProps)(SearchDropdown);
