import PropTypes from "prop-types";
import classnames from "classnames/bind";
import {
  FLOOR_NAV_DATA_TESTID,
  FLOOR_NAV_MEN_FLOOR_ID,
  FLOOR_NAV_WOMEN_FLOOR_ID
} from "./constants";
import styles from "./FloorNavigation.css";

const cx = classnames.bind(styles);

const LargeFloorNavigation = ({
  formatTranslation,
  isMensFloorSelected,
  isWomensFloorSelected,
  menStoreUrl,
  womenStoreUrl
}) => {
  return (
    <ul
      className={cx(styles.container, styles.container__large)}
      data-testid={FLOOR_NAV_DATA_TESTID}
    >
      <li>
        <a
          aria-current={isWomensFloorSelected ? "page" : null}
          className={cx(styles.button, {
            [styles.selected]: isWomensFloorSelected
          })}
          data-testid={FLOOR_NAV_WOMEN_FLOOR_ID}
          href={womenStoreUrl}
          id={FLOOR_NAV_WOMEN_FLOOR_ID}
        >
          {formatTranslation("header_women_floor")}
        </a>
      </li>
      <li>
        <a
          aria-current={isMensFloorSelected ? "page" : null}
          className={cx(styles.button, {
            [styles.selected]: isMensFloorSelected
          })}
          data-testid={FLOOR_NAV_MEN_FLOOR_ID}
          href={menStoreUrl}
          id={FLOOR_NAV_MEN_FLOOR_ID}
        >
          {formatTranslation("header_men_floor")}
        </a>
      </li>
    </ul>
  );
};

LargeFloorNavigation.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  isMensFloorSelected: PropTypes.bool.isRequired,
  isWomensFloorSelected: PropTypes.bool.isRequired,
  menStoreUrl: PropTypes.string.isRequired,
  womenStoreUrl: PropTypes.string.isRequired
};

export default LargeFloorNavigation;
