import {
  getTemplateVersion,
  getKeyStoreDataversion
} from "@asosteam/asos-web-store-presentation-node";

import {
  getStoreCode,
  getKeyStoreDataversion as getKeyStoreDataversionFromState
} from "../../template/state/modules/regionalStore";

import { getKeyStoreDataversion as getKeyStoreDataversionFromManifest } from "@src/helpers/manifest";

export const getAllKeyStoreDataversions = (state, manifest) => {
  return {
    inRollback: !manifest.useCurrentKeyStoreDataversion,
    templateValue: getKeyStoreDataversionFromState(state),
    cookieValue: getKeyStoreDataversion(),
    manifestValue: getKeyStoreDataversionFromManifest(manifest)
  };
};

export const getAllSiteChromeVersions = (state, manifest) => {
  const storeCode = getStoreCode(state).toLowerCase();
  const liveObject = manifest.siteChromeVersion[storeCode];

  return {
    inRollback: !liveObject.useCurrent,
    templateValue: state.version,
    cookieValue: getTemplateVersion(storeCode),
    manifestValue: liveObject.useCurrent
      ? liveObject.current
      : liveObject.previous
  };
};

export const getSiteChromeVersionMappingsFromCookie = manifest =>
  Object.keys(manifest.siteChromeVersion).reduce((mappings, storeCode) => {
    const templateVersion = getTemplateVersion(storeCode);

    if (templateVersion) {
      mappings[storeCode] = templateVersion;
    }

    return mappings;
  }, {});

export const getStoreCodeToSiteChromeVersionMappings = manifest => {
  const mappingsFromManifestNotInRollback = Object.keys(
    manifest.siteChromeVersion
  ).reduce((mappings, storeCode) => {
    const mapping = manifest.siteChromeVersion[storeCode];

    if (mapping.useCurrent) {
      mappings[storeCode] = mapping.current;
    }

    return mappings;
  }, {});

  const mappingsFromCookie = getSiteChromeVersionMappingsFromCookie(manifest);

  const mappingsFromManifestInRollback = Object.keys(
    manifest.siteChromeVersion
  ).reduce((mappings, storeCode) => {
    const mapping = manifest.siteChromeVersion[storeCode];

    if (!mapping.useCurrent) {
      mappings[storeCode] = mapping.previous;
    }

    return mappings;
  }, {});

  return Object.assign(
    mappingsFromManifestNotInRollback,
    mappingsFromCookie,
    mappingsFromManifestInRollback
  );
};
