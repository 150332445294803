import {
  BAG_ERROR,
  DISMISS_BAG_ERROR,
  FINISH_REMOVE_BAG_ITEM,
  SET_BAG,
  SET_LOADING,
  START_REMOVE_BAG_ITEM
} from "./constants";

export {
  BAG_ERROR,
  BAG_ITEM_DELETE_TRANSITION_END,
  DISMISS_BAG_ERROR,
  FINISH_REMOVE_BAG_ITEM,
  SET_BAG,
  SET_LOADING,
  START_CHECKOUT,
  START_REMOVE_BAG_ITEM
} from "./constants";

export { deleteBagItem, startCheckout } from "./actions";

export {
  getBag,
  getLoading,
  getBagItems,
  getBagSummary,
  getTotalPrice,
  getTotalPriceText,
  getTotalQuantity,
  displayReservationMessage,
  bagError,
  getSellerChanged,
  getContainsHotProduct,
  dismissBagError,
  getUpdated,
  bagItemDeleteTransitionEnd
} from "./selectors";

const initialState = {
  summary: {
    totalPrice: {}
  },
  loading: true
};

export const reduce = (state = initialState, action) => {
  switch (action.type) {
    case SET_BAG: {
      const { bag, messages, sellerChanged, containsHotProduct } =
        action.payload;

      return {
        ...state,
        ...bag,
        messages,
        sellerChanged,
        containsHotProduct,
        loading: false,
        bagError: false
      };
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case START_REMOVE_BAG_ITEM: {
      return {
        ...state
      };
    }
    case BAG_ERROR: {
      return {
        ...state,
        bagError: true,
        loading: false
      };
    }
    case DISMISS_BAG_ERROR: {
      return {
        ...state,
        bagError: false
      };
    }
    case FINISH_REMOVE_BAG_ITEM: {
      return {
        ...state,
        lastItemRemoved: action.itemId
      };
    }
    default:
      return state;
  }
};
