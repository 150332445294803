import PropTypes from "prop-types";
import classnamesBind from "classnames/bind";
import styles from "./deferred.css";

const classnames = classnamesBind.bind(styles);

const PageOverlay = ({ onClickDelegate, modalClassnames }) => (
  <div
    data-testid="modal-page-overlay"
    className={classnames(styles.pageOverlay, {
      ...modalClassnames
    })}
    aria-hidden="true"
    onClick={onClickDelegate}
  />
);

PageOverlay.propTypes = {
  onClickDelegate: PropTypes.func.isRequired,
  modalClassnames: PropTypes.object.isRequired
};

export default PageOverlay;
