import classnames from "classnames";
import { navigationPropType } from "@template/state/modules/navigation";
import NavAnchor from "@template/components/Navigation/NavAnchor";
import styles from "../index.css";

const cx = classnames.bind(styles);

const TextList = ({ item }) => {
  return (
    <ul aria-labelledby={item.id} className={styles.textList}>
      {item.items.map((navItem, index) => (
        <li key={navItem.id}>
          <NavAnchor
            className={cx(styles.textLink, {
              [styles.textLink__sale]: navItem.largeScreenStyleType === "sale",
              [styles.textLink__bold]:
                navItem.largeScreenStyleType === "premium"
            })}
            item={navItem}
            data-testid="text-link"
            data-first={item.isFirstColumn && index === 0 ? true : null}
            data-last={
              item.isLastColumn && index === item.items.length - 1 ? true : null
            }
          >
            {navItem.label.substring(0, 32)}
          </NavAnchor>
        </li>
      ))}
    </ul>
  );
};

TextList.propTypes = {
  item: navigationPropType.isRequired
};

export default TextList;
