import { hydrate } from "react-dom";
import { asosGlobalNamespace } from "../template/constants";
import {
  logCookieDataAsCustomAttributes,
  logCookieDataAsPageAction
} from "./cookieAnalysis";
import addIpVersionToNewRelic from "./addIpVersionToNewRelic";
import { bootstrapNavigationBroker } from "./navigationBroker";

document.addEventListener("DOMContentLoaded", function () {
  addIpVersionToNewRelic();
});

import {
  updateStateWithClientData,
  ModalContentContainer,
  MainContentContainer
} from "./Root";

bootstrapNavigationBroker();

window.addEventListener("load", logCookieDataAsCustomAttributes);

window[asosGlobalNamespace].eventBus.addListener(
  "sdk-ready",
  logCookieDataAsPageAction
);

document.addEventListener("DOMContentLoaded", function () {
  const modalContentElement = document.getElementById("chrome-modal-content");
  const mainContentElement = document.getElementById("chrome-main-content");
  hydrate(<MainContentContainer />, mainContentElement);
  hydrate(<ModalContentContainer />, modalContentElement);

  window[asosGlobalNamespace].eventBus.emit("hydrate-ready");
  updateStateWithClientData();
});
