import {
  GET_SUBSCRIPTIONS,
  SET_SUBSCRIPTIONS_FETCHING_STATUS
} from "./constants";

export { getSubscriptionsHasResponded, hasSubscriptions } from "./selectors";
export { getSubscriptionsFromSDK } from "./actions";

const initialState = {
  fetchingStatus: null,
  subscriptions: null
};

export const reduce = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS:
      return {
        ...state,
        fetchingStatus: action.payload.fetchingStatus,
        subscriptions: action.payload.subscriptions
      };
    case SET_SUBSCRIPTIONS_FETCHING_STATUS:
      return {
        ...state,
        fetchingStatus: action.payload
      };
    default:
      return state;
  }
};
