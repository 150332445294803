export const SET_BAG = "SET_BAG";

export const START_REMOVE_BAG_ITEM = "START_REMOVE_BAG_ITEM";
export const FINISH_REMOVE_BAG_ITEM = "FINISH_REMOVE_BAG_ITEM";
export const START_CHECKOUT = "START_CHECKOUT";

export const BAG_ITEM_DELETE_TRANSITION_END = "BAG_ITEM_DELETE_TRANSITION_END";
export const BAG_ERROR = "BAG_ERROR";
export const DISMISS_BAG_ERROR = "DISMISS_BAG_ERROR";
export const SET_LOADING = "SET_LOADING";
