/*

Add features to store, waiting until DOM content has loaded to avoid template mismatches.

*/

import { setFeatures as setFeaturesLoaded } from "@template/state/modules/features";

export default (store, emitter) => {
  const dispatchFeaturesLoaded = event => {
    store.dispatch(
      setFeaturesLoaded({
        loadComplete: event === "features-mvt-ready"
      })
    );
  };

  let postHydrate = false;
  let calledBeforeHydrate = false;
  const pendingCalls = [];

  emitter.addListener("hydrate-ready", () => {
    postHydrate = true;
    if (calledBeforeHydrate && pendingCalls.length) {
      pendingCalls.forEach(fn => fn());
    }
  });

  return event => {
    if (postHydrate) {
      dispatchFeaturesLoaded(event);
    } else {
      calledBeforeHydrate = true;
      pendingCalls.push(() => dispatchFeaturesLoaded(event));
    }
  };
};
