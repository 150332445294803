import PropTypes from "prop-types";
import LinkGroup from "./LinkGroup";
import CountrySelectorButton from "../CountrySelectorButton";
import InternationalSites from "./InternationalSites";
import styles from "./Links.css";
import { MEDIUM_VIEWPORT, LARGE_VIEWPORT } from "../../state/modules/layout";
import { ANALYTICS_POSITION_FOOTER } from "../../../helpers/eventing/events";
import { withTranslation } from "../translation";

const Links = ({ items: groupItems, isAccordion, formatTranslation }) => (
  <div className={styles.container}>
    <div className={styles.body} data-testid="footer-links-group">
      {groupItems.map(groupItem => (
        <LinkGroup
          key={groupItem.id}
          groupItem={groupItem}
          isAccordion={isAccordion}
        />
      ))}
      <div className={styles.group}>
        <h3 className={styles.header}>
          {formatTranslation("country_selector_button_heading")}
        </h3>
        <CountrySelectorButton
          withLabels={true}
          position={ANALYTICS_POSITION_FOOTER}
        />
        <InternationalSites viewable={[MEDIUM_VIEWPORT, LARGE_VIEWPORT]} />
      </div>
    </div>
  </div>
);

Links.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  isAccordion: PropTypes.bool,
  formatTranslation: PropTypes.func.isRequired
};

Links.defaultProps = {
  isAccordion: false
};

export default withTranslation(Links);
