import { useContext } from "react";
import { node } from "prop-types";
import { navigationPropType } from "@template/state/modules/navigation";
import {
  trackPageInteraction,
  getGender,
  ANALYTICS_INTERACTION_CLICK,
  ANALYTICS_POSITION_HEADER,
  ANALYTICS_CONTEXT
} from "@src/helpers/eventing/events";
import { addAnalyticsDataToLink } from "@template/helpers/addAnalyticsDataToLink";
import { NavigationContext } from "../NavigationContext";
import { fullPageNavigationDecider } from "@src/client/navigationBroker";

const NavAnchor = ({ item, children, ...restOfProps }) => {
  const closeNavigationMenu = useContext(NavigationContext);
  const target = item.linkType === "external" ? "_blank" : null;
  const href = addAnalyticsDataToLink(item);

  const handleClick = event => {
    const { gender, label } = item;

    trackPageInteraction({
      context: ANALYTICS_CONTEXT.openNav,
      interaction: ANALYTICS_INTERACTION_CLICK,
      elementText: label,
      properties: {
        positionOnPage: ANALYTICS_POSITION_HEADER,
        gender: getGender(gender)
      }
    });

    fullPageNavigationDecider(href, event);

    closeNavigationMenu();
  };

  return (
    <a {...restOfProps} onClick={handleClick} href={href} target={target}>
      {children}
    </a>
  );
};

NavAnchor.propTypes = {
  item: navigationPropType.isRequired,
  children: node.isRequired
};

export default NavAnchor;
