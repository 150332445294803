import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getFeaturesLoaded } from "../../../state/modules/features";
import LargeSecondaryNavigation from "./LargeSecondaryNavigation";
import {
  getNavActiveItem,
  setNavActiveItem,
  clearNavActiveItem
} from "../../../state/modules/navigation";
import { getViewport } from "../../../state/modules/layout";

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setNavActiveItem, clearNavActiveItem }, dispatch);

const mapStateToProps = state => {
  return {
    largeNavActiveItemId: getNavActiveItem(state),
    viewport: getViewport(state),
    testIsLoaded: getFeaturesLoaded(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LargeSecondaryNavigation);
