export const MINIMUM_SUGGESTIONS_CHARS = 2;
export const MAXIMUM_RECENT_SEARCHES = 5;

export const REQUEST_SUGGESTIONS = "REQUEST_SUGGESTIONS";
export const RECEIVE_SUGGESTIONS = "RECEIVE_SUGGESTIONS";
export const SUBMIT_SEARCH = "SUBMIT_SEARCH";
export const CLEAR_RECENT_SEARCHES = "CLEAR_RECENT_SEARCHES";
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const CLOSE_SEARCH = "CLOSE_SEARCH";
export const OPEN_SEARCH = "OPEN_SEARCH";
