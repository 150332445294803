import { EventEmitter } from "fbemitter";
import { isWindowDefined } from "@src/helpers/url";

export const globalEventHandler = new EventEmitter();

export const events = {
  focusFirstPrimaryNavigationItem: "focusFirstPrimaryNavigationItem",
  closeSearchOverlay: "closeSearchOverlay",
  focusBackToFloor: "focusBackToFloor",
  keyDown: "keydown",
  click: "click",
  scroll: "scroll",
  resize: "resize"
};

export const keys = {
  tab: "Tab",
  escape: "Escape",
  enter: "Enter",
  left: "ArrowLeft",
  right: "ArrowRight",
  home: "Home",
  end: "End",
  space: " "
};

export const keyCodes = {
  tab: 9,
  escape: 27,
  enter: 13,
  up: 38,
  down: 40,
  space: 32
};

const normaliseKey = event => {
  if (event.key) {
    return event.key;
  }

  switch (event.which) {
    case keyCodes.tab:
      return keys.tab;
    case keyCodes.escape:
      return keys.escape;
  }
};

isWindowDefined() &&
  window.addEventListener(events.keyDown, event =>
    globalEventHandler.emit(events.keyDown, {
      key: normaliseKey(event),
      originalEvent: event
    })
  );

isWindowDefined() &&
  window.addEventListener(events.click, event =>
    globalEventHandler.emit(events.click, event)
  );

isWindowDefined() &&
  window.addEventListener(events.scroll, () =>
    globalEventHandler.emit(events.scroll)
  );

isWindowDefined() &&
  window.addEventListener(events.resize, () =>
    globalEventHandler.emit(events.resize)
  );

export const focusPrimaryNavigationFor = gender =>
  globalEventHandler.emit(events.focusFirstPrimaryNavigationItem, gender);

export const focusFloorNavigationFor = gender =>
  globalEventHandler.emit(events.focusBackToFloor, gender);

export const closeSearchOverlay = () =>
  globalEventHandler.emit(events.closeSearchOverlay);
