import { createSelector } from "reselect";
import { SUCCESS, FAILED } from "./constants";

const getAudienceState = state => state.audience;

const getAudienceSegmentsItems = createSelector(
  getAudienceState,
  state => state.segments
);

const getFetchingStatus = createSelector(
  getAudienceState,
  state => state.fetchingStatus
);

export const getAudienceHasResponded = createSelector(
  getFetchingStatus,
  fetchingStatus => [SUCCESS, FAILED].includes(fetchingStatus)
);

export const getAudienceSegments = createSelector(
  getAudienceSegmentsItems,
  segments => segments
);
