import PropTypes from "prop-types";
import styles from "./deferred.css";
import { withTranslation } from "@template/components/translation";
import ErrorPage from "./ErrorPage";
import CloseButton from "./CloseButton";
import {
  ProgressIndicator,
  SIZE_MEDIUM,
  SIZE_LARGE
} from "@asosteam/asos-web-component-library-progress-indicator";
import { SMALL_VIEWPORT } from "@template/state/modules/layout";

const ContentLoadingOverlay = ({
  showError,
  contentReady,
  closeButtonClickDelegate,
  moveFocusToCloseButton,
  formatTranslation,
  viewport
}) => {
  const showContentLoadingOverlay = !contentReady || showError;

  const progressIndicatorSize =
    viewport === SMALL_VIEWPORT ? SIZE_LARGE : SIZE_MEDIUM;

  return (
    showContentLoadingOverlay && (
      <div className={styles.container}>
        <CloseButton
          moveFocusToCloseButton={moveFocusToCloseButton}
          closeButtonClickDelegate={closeButtonClickDelegate}
        />
        {!showError ? (
          <div className={styles.progressIndicatorContainer}>
            <ProgressIndicator
              size={progressIndicatorSize}
              progressMessage={formatTranslation("accessibility_label_loading")}
            />
          </div>
        ) : (
          <ErrorPage />
        )}
      </div>
    )
  );
};

ContentLoadingOverlay.propTypes = {
  showError: PropTypes.bool.isRequired,
  contentReady: PropTypes.bool.isRequired,
  closeButtonClickDelegate: PropTypes.func.isRequired,
  moveFocusToCloseButton: PropTypes.bool.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  viewport: PropTypes.string.isRequired
};

export default withTranslation(ContentLoadingOverlay);
