// notification types
export const MINIBAG = "miniBag";
export const INFO_BANNER = "infoBanner";
export const NOTIFICATION_TYPES = [MINIBAG, INFO_BANNER];

// message types

export const ALERT_TYPE = "alert";
export const ERROR_TYPE = "error";
export const SUCCESS_TYPE = "success";
export const PROMOTION_TYPE = "promotion";

// messages

export const ERROR_MESSAGE = "item_add_bag_error";
export const DEFAULT_MESSAGE = "minibag_reservation_message";
export const HOT_PRODUCT_MESSAGE = "generic_hot_item_minibag";
export const SELLER_CHANGED_MESSAGE = "dtc_atb_reservation_sellerchangemessage";

// action constants
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
