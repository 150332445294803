import { connect } from "react-redux";
import {
  termLongEnoughToSearch,
  getSuggestionsLength
} from "@template/state/modules/search";
import SearchResultsCountAnnouncer from "./SearchResultsCountAnnouncer";

const mapStateToProps = (state, props) => {
  return {
    shouldAnnounce: props.shouldAnnounce && termLongEnoughToSearch(state),
    count: getSuggestionsLength(state)
  };
};

export default connect(mapStateToProps)(SearchResultsCountAnnouncer);
