import clientConfig from "../../template/clientConfig";

export const getConfiguration = ({ authClient }) => {
  const config = {
    getIdentitySdkDelegate: async () => authClient
  };

  if (clientConfig.isLocal()) {
    return {
      ...config,
      bagApiUrl: "/api"
    };
  }

  return {
    ...config,
    xSiteOrigin: clientConfig.getXSiteOrigin()
  };
};
