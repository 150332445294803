import { createSelector } from "reselect";
import { SUCCESS, FAILED } from "./constants";

const getSubscriptionsState = state => state.subscriptions;

const getSubscriptionItems = createSelector(
  getSubscriptionsState,
  state => state.subscriptions
);

const getFetchingStatus = createSelector(
  getSubscriptionsState,
  state => state.fetchingStatus
);

export const getSubscriptionsHasResponded = createSelector(
  getFetchingStatus,
  fetchingStatus => [SUCCESS, FAILED].includes(fetchingStatus)
);

export const hasSubscriptions = createSelector(
  getSubscriptionItems,
  subscriptions => subscriptions?.length > 0
);
