import {
  ERROR_TYPE,
  INFO_BANNER,
  ERROR_MESSAGE,
  SET_NOTIFICATION
} from "@template/state/modules/notifications";

const subscribeToErrorCallback =
  ({ dispatch }) =>
  ({ containsHotProduct, initiator }) => {
    if (containsHotProduct && initiator === "bagSdk") {
      dispatch({
        type: SET_NOTIFICATION,
        payload: {
          notificationType: INFO_BANNER,
          notificationData: {
            isVisible: true,
            message: ERROR_MESSAGE,
            type: ERROR_TYPE
          }
        }
      });
    }
  };

export default subscribeToErrorCallback;
