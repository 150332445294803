export const optimizelyActivator = () => {
  const OPTIMIZELY_SCRIPT_CONTENT_WEB =
    "https://cdn.optimizely.com/js/19181552552.js";

  const OPTIMIZELY_SCRIPT_UNLOCKED_MODAL_ONLY =
    "https://cdn.optimizely.com/public/19065870423/s/unlocked_page.js";

  const path = window.location.pathname;
  const optimizelyWebContentEnabled =
    !/\/(bag\/?$|saved-lists\/$|search\/?|cat\/|gift-vouchers\/?$|see-similar\/|shared-board\/)/i.test(
      path
    );

  const script = document.createElement("script");
  script.src = optimizelyWebContentEnabled
    ? OPTIMIZELY_SCRIPT_CONTENT_WEB
    : OPTIMIZELY_SCRIPT_UNLOCKED_MODAL_ONLY;
  script.defer = true;

  document.body.appendChild(script);
};
