import { applyMiddleware } from "redux";
import { autoRehydrate } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import * as api from "../../api";
import {
  asosGlobalNamespace,
  sdkGlobalNamespace
} from "../../template/constants";
import clientConfig from "../clientConfig";

import { actionDeferralMiddleware } from "./middleware/actionDeferral";
import bagMiddleware from "./middleware/bag";
import infoBannerMiddleware from "./middleware/infoBanner";

const enhancers = () =>
  composeWithDevTools({ name: "SiteChrome" })(
    applyMiddleware(
      actionDeferralMiddleware, //must go first!
      thunk.withExtraArgument({
        api,
        get identity() {
          return window[asosGlobalNamespace][sdkGlobalNamespace].identity;
        }
      }),
      ...(clientConfig.isServer() ? [] : [bagMiddleware, infoBannerMiddleware])
    ),
    autoRehydrate()
  );

export default enhancers;
