import { setKeyedCookieValue } from "@src/client/context/cookies";
import {
  RSFT_WELCOME_MESSAGE_COOKIE_NAME,
  RSFT_USER_TOOK_ACTION_ON_WELCOME_MESSAGE_KEY
} from "@template/constants";

const setRsftWelcomeMessageClosed = () => {
  setKeyedCookieValue(
    RSFT_WELCOME_MESSAGE_COOKIE_NAME,
    RSFT_USER_TOOK_ACTION_ON_WELCOME_MESSAGE_KEY,
    true,
    null
  );
};

export default setRsftWelcomeMessageClosed;
