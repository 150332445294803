import { Component } from "react";
import PropTypes from "prop-types";
import CSSTransition from "react-transition-group/CSSTransition";
import styles from "./Fade.css";
import BlockScroll from "@src/helpers/blockScroll";
class Fade extends Component {
  constructor() {
    super();
    this.blockScroll = new BlockScroll();
  }

  handleEnter = () => {
    this.blockScroll.enable();
  };

  handleExit = () => {
    const { onExit } = this.props;

    this.blockScroll.disable();
    onExit();
  };

  render() {
    const { isActive, children } = this.props;

    return (
      <CSSTransition
        in={isActive}
        mountOnEnter={true}
        unmountOnExit={true}
        timeout={500}
        onEnter={this.handleEnter}
        onExited={this.handleExit}
        classNames={{
          enter: styles.fade__entered,
          enterActive: styles.fade__entering,
          exit: styles.fade__exited,
          exitActive: styles.fade__exiting
        }}
      >
        <div className={styles.fade}>{children}</div>
      </CSSTransition>
    );
  }
}

Fade.defaultProps = {
  isActive: false,
  onExit: () => {}
};

Fade.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.node,
  onExit: PropTypes.func
};

export default Fade;
