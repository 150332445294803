import { getCookieValue } from "@src/client/context/cookies";
import {
  STC_COOKIE_BANNER_EXPERIMENTS,
  OPTANON_ALERT_BOX_CLOSED_COOKIE
} from "./constants";
import isFeatureEnabled from "@template/helpers/checkFeatureIsEnabled";

const getOneTrustScriptId = async (
  domainScriptId,
  domainScriptIdExperiment
) => {
  const optanonAlertBoxClosedCookies = getCookieValue(
    OPTANON_ALERT_BOX_CLOSED_COOKIE
  );

  if (optanonAlertBoxClosedCookies) {
    return domainScriptId;
  }

  const isExperiment = await isFeatureEnabled(
    STC_COOKIE_BANNER_EXPERIMENTS,
    true
  );

  return isExperiment ? domainScriptIdExperiment : domainScriptId;
};

export default getOneTrustScriptId;
