const getAlternateUrls = () =>
  Array.from(
    document.querySelectorAll(`link[rel="alternate"][hreflang]`)
  ).reduce(
    (alternateUrls, { hreflang, href }) => ({
      ...alternateUrls,
      [hreflang]: href
    }),
    {}
  );

export default getAlternateUrls;
