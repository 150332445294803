import {
  ALERT_TYPE,
  SUCCESS_TYPE,
  HOT_PRODUCT_MESSAGE,
  SELLER_CHANGED_MESSAGE
} from "./constants";

export const getNotificationData = ({
  sellerChanged,
  containsHotProduct,
  itemType = "Product"
}) => {
  const mapProductToMessage = {
    Product: "reservation_message",
    Subscription: "subscription_confirmation_message",
    Voucher: "voucher_confirmation_message"
  };

  if (sellerChanged) {
    return {
      message: SELLER_CHANGED_MESSAGE,
      type: SUCCESS_TYPE
    };
  }

  if (containsHotProduct) {
    return {
      message: HOT_PRODUCT_MESSAGE,
      type: ALERT_TYPE
    };
  }

  return {
    message: `minibag_${mapProductToMessage[itemType]}`,
    type: SUCCESS_TYPE
  };
};
