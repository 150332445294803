import { emitConsentChangedEvent } from "./emitConsentChangedEvent";
import { setupConsentChangedListener } from "./setupConsentChangedListener";
import { observePreferenceCenterMutations } from "./observePreferenceCenterMutations";
import { logOneTrustData } from "./logOneTrustData";
import { trackAnalytics } from "./trackAnalytics";

export const handleSdkLoad = store => () => {
  window.OptanonWrapper = undefined;
  observePreferenceCenterMutations(store);
  setupConsentChangedListener(emitConsentChangedEvent);
  emitConsentChangedEvent();
  logOneTrustData();
  trackAnalytics();
};
