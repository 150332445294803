import { adobeAccountProvider } from "./adobeAccountProvider";

let adobeSdk;

export const adobeSdkProvider = () => {
  if (!adobeSdk) {
    const adobeAccount = adobeAccountProvider();
    adobeSdk = window.s_gi(adobeAccount);
  }

  return adobeSdk;
};
