import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "@template/components/translation";
import CountrySelectorButton from "../CountrySelectorButton";
import { ANALYTICS_POSITION_HEADER } from "../../../helpers/eventing/events";
import styles from "./MiscBar.css";
import { getMarketplaceUrl } from "../../helpers/externalLinks";
import { withParams } from "../../../helpers/queryString";

class MiscBar extends Component {
  marketplaceUrl = () => {
    return withParams(getMarketplaceUrl(), {
      ctaref: `Global nav`
    });
  };

  render() {
    const { formatTranslation, defaultStoreUrl } = this.props;

    return (
      <div className={styles.container} data-testid="topbar">
        <div className={styles.body}>
          <ul className={styles.navigation}>
            <li>
              <a
                href={`${this.marketplaceUrl()}`}
                data-testid="marketplace"
                className={styles.link}
              >
                {formatTranslation("miscbar_marketplace")}
              </a>
            </li>
            <li>
              <a
                href={defaultStoreUrl.concat(
                  formatTranslation("miscbar_helplink")
                )}
                data-testid="help"
                className={styles.link}
              >
                {formatTranslation("miscbar_help")}
              </a>
            </li>
            <li>
              <CountrySelectorButton position={ANALYTICS_POSITION_HEADER} />
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

MiscBar.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  defaultStoreUrl: PropTypes.string.isRequired
};

export default withTranslation(MiscBar);
