import { siteChromeNavigationStoreKey, expirationInMs } from "./constants";
import { fetchClientNavigation } from "@src/api";
import { updateNavigationTree } from "@src/template/state/modules/navigation";
import { getOrSetInLocalStorageWithExpiryAsync } from "@src/helpers/webStorage";
import getFeature from "@template/helpers/getFeature";
import getFeatureVariables from "@template/helpers/getFeatureVariables";
import { noticeError } from "@src/helpers/monitoring";

async function getVariantKey() {
  const STC_NAV_LINKS = "stc-nav-links";
  const feature = await getFeature(STC_NAV_LINKS);

  if (feature?.isEnabled()) {
    const { variantKey } = await getFeatureVariables(STC_NAV_LINKS);
    return variantKey;
  }

  return undefined;
}

const getCountryData = store => {
  const {
    regionalStore: {
      countryCode,
      defaultLanguage,
      storeUrls,
      keyStoreDataversion
    },
    layout: { platform }
  } = store.getState();

  return [
    defaultLanguage,
    countryCode,
    storeUrls.default,
    platform,
    keyStoreDataversion
  ];
};

export const reloadNavigation = async store => {
  const variantKey = await getVariantKey();
  const [language, countryCode, storeUrl, platform, ksdvFromRegionalStore] =
    getCountryData(store);

  const fetchClientNavigationWrapper = async (
    countryCode,
    keyStoreDataversion,
    language
  ) => {
    const value = await fetchClientNavigation({
      country: countryCode,
      lang: language,
      storeUrl,
      keyStoreDataversion,
      platform,
      variantKey
    });
    const timeToLiveMs = expirationInMs;

    return { value, timeToLiveMs };
  };

  try {
    const navData = await getOrSetInLocalStorageWithExpiryAsync(
      {
        cacheKeyPrefix: siteChromeNavigationStoreKey,
        returnCachedOnDelegateFailure: true,
        removeOtherCacheItems: true,
        localStorageValueDelegate: fetchClientNavigationWrapper
      },
      countryCode,
      ksdvFromRegionalStore,
      language
    );

    store.dispatch(updateNavigationTree(navData));
  } catch (error) {
    noticeError(error);
  }
};
