import {
  LOG_IN_USER,
  SET_USER_FETCHING_STATUS,
  SET_USER_PROFILE
} from "./constants";

export { SUCCESS, FAILED, FETCHING } from "./constants";
export {
  getCustomerId,
  getFirstName,
  getIdentityHasResponded,
  isLoggedIn
} from "./selectors";
export {
  setLoggedInState,
  setUserFetchingState,
  setUserProfile
} from "./actions";

const initialState = {
  fetchingStatus: null,
  isLoggedIn: false,
  profile: {}
};

export const reduce = (state = initialState, action) => {
  switch (action.type) {
    case LOG_IN_USER:
      return {
        ...state,
        isLoggedIn: action.payload
      };
    case SET_USER_PROFILE:
      return {
        ...state,
        profile: action.payload
      };
    case SET_USER_FETCHING_STATUS:
      return {
        ...state,
        fetchingStatus: action.payload
      };
    default:
      return state;
  }
};
