import {
  LOG_IN_USER,
  SET_USER_FETCHING_STATUS,
  SET_USER_PROFILE
} from "./constants";

export const setLoggedInState = loggedInState => ({
  type: LOG_IN_USER,
  payload: loggedInState
});

export const setUserProfile = profile => ({
  type: SET_USER_PROFILE,
  payload: profile
});

export const setUserFetchingState = status => ({
  type: SET_USER_FETCHING_STATUS,
  payload: status
});
