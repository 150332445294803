import { setCustomAttributeOnNextRequest } from "@src/helpers/monitoring";

export const checkForMismatch = ({
  inRollback,
  cookieValue,
  manifestValue
}) => {
  if (!manifestValue) {
    return false;
  }

  const thereIsAMismatch = inRollback && cookieValue !== manifestValue;

  return thereIsAMismatch;
};

/* Note that we only set the cookie when not in rollback if the cookie doesn't exist.
This is so that we don't force the user on to a new experience during the same session. */
export const shouldSetCookie = ({ inRollback, cookieValue }) => {
  return inRollback || !cookieValue;
};

export const correctUndefinedCookie = (
  versionData,
  setCookie,
  customAttributeName
) => {
  if (versionData.cookieValue === "undefined") {
    setCustomAttributeOnNextRequest(customAttributeName, "true");

    setCookie();
    return true;
  }

  return false;
};

export function checkForMismatchAndSetCookie(versionData, setCookie) {
  if (shouldSetCookie(versionData)) {
    setCookie();
  }

  return checkForMismatch(versionData);
}
