/* eslint-disable jsx-a11y/label-has-for */
import { Component } from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";
import classnames from "classnames";
import styles from "./index.css";

const cx = classnames.bind(styles);

class IconButton extends Component {
  icon = () => (
    <Icon
      style={this.props.iconStyles || {}}
      icon={this.props.icon}
      size={this.props.size}
      type={this.props.icontype}
    />
  );

  classes = () => cx(styles.button, this.props.className);

  button = ({ refCallback, ...props }) => (
    <button
      {...props}
      type="button"
      ref={refCallback}
      className={this.classes()}
    >
      {this.icon()}
      {this.props.children}
    </button>
  );

  label = props => (
    <label {...props} className={this.classes()}>
      {this.icon()}
    </label>
  );

  link = props => (
    <a {...props} className={this.classes()}>
      {this.icon()}
      {this.props.children}
    </a>
  );

  render() {
    const p = Object.assign({}, this.props);

    if (p.iconStyles) {
      delete p.iconStyles;
    }
    switch (this.props.type) {
      case "a":
        return this.link(p);
      case "label":
        return this.label(p);
      case "button":
      default:
        return this.button(p);
    }
  }

  static propTypes = {
    icon: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["a", "button", "label"]),
    icontype: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    iconStyles: PropTypes.object,
    size: PropTypes.oneOf(["small", "medium"]),
    refCallback: PropTypes.func
  };

  static defaultProps = {
    type: "button"
  };
}

export default IconButton;
