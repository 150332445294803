import sdkInstanceProvider from "@src/client/sdk/sdkInstanceProvider";
import { initialise } from "@asosteam/asos-web-event-tracking-bridge";
import { eventBus } from "@src/helpers/eventing/events";
import { GTM_ID } from "./constants";

const initialiseEventTrackingBridge = () => {
  const featuresDelegate = sdkInstanceProvider("features");
  const { s: adobeDelegate, newrelic: newrelicDelegate } = window;

  initialise({
    adobeDelegate: () => adobeDelegate,
    eventBusDelegate: () => eventBus,
    featuresDelegate: () => featuresDelegate,
    gtmId: GTM_ID,
    newrelicDelegate: () => newrelicDelegate
  });
};

export default initialiseEventTrackingBridge;
