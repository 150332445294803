import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  closeCountrySelector,
  getCountrySelectorCountry,
  getCountrySelectorError,
  getCountrySelectorLoading,
  getCountrySelectorVisibility,
  getHasSetCurrencyError,
  getHasVoucherError,
  getReferrer,
  setCountry,
  updatePreferences
} from "@template/state/modules/countrySelector";
import {
  getCountryCode,
  getStoreCode,
  getSiteId,
  getCurrency,
  getDefaultLanguage,
  getCountryName,
  getDefaultSizeSchema
} from "@template/state/modules/regionalStore";
import {
  focusLastActiveElement,
  getViewport
} from "@template/state/modules/layout";
import { getTotalQuantity } from "@template/state/modules/bag";
import CountrySelector from "./CountrySelector";

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onClose: closeCountrySelector,
      onCountryChange: setCountry,
      onSubmit: updatePreferences,
      focusLastActiveElement
    },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    country: getCountrySelectorCountry(state),
    error: getCountrySelectorError(state),
    hasSetCurrencyError: getHasSetCurrencyError(state),
    hasVoucherError: getHasVoucherError(state),
    isLoading: getCountrySelectorLoading(state),
    isOpen: getCountrySelectorVisibility(state),
    referrer: getReferrer(state),
    noOfProductsInBag: getTotalQuantity(state),
    regionalStore: {
      countryCode: getCountryCode(state),
      storeCode: getStoreCode(state),
      siteId: getSiteId(state),
      currency: getCurrency(state),
      language: getDefaultLanguage(state),
      countryName: getCountryName(state),
      sizeSchema: getDefaultSizeSchema(state)
    },
    viewport: getViewport(state)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelector);
