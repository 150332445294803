import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getViewport, viewportPropType } from "@template/state/modules/layout";

function withViewport(WrappedComponent) {
  const ComposedComponent = ({ viewable = [], viewportCategory, ...rest }) => {
    if (!viewable.length || viewable.includes(viewportCategory)) {
      return <WrappedComponent isVisible viewable={viewable} {...rest} />;
    }

    return null;
  };

  ComposedComponent.displayName = `withViewport(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;

  ComposedComponent.propTypes = {
    viewable: PropTypes.arrayOf(viewportPropType),
    viewportCategory: viewportPropType.isRequired
  };

  function mapStateToProps(state) {
    return {
      viewportCategory: getViewport(state)
    };
  }

  return connect(mapStateToProps)(ComposedComponent);
}

export default withViewport;
