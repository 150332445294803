import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "@template/components/translation";
import { BURGER_MENU_BUTTON_DATA_TEST_ID } from "./constants";
import styles from "./Burger.css";
import Icon from "../Icon";

class Burger extends Component {
  constructor(props) {
    super(props);
    this.openSidePanel = this.openSidePanel.bind(this);
  }
  static propTypes = {
    openSidePanel: PropTypes.func.isRequired,
    isSidePanelOpen: PropTypes.bool.isRequired,
    isSearchVisible: PropTypes.bool.isRequired,
    formatTranslation: PropTypes.func.isRequired,
    addLastFocusElement: PropTypes.func.isRequired
  };

  saveRef = component => (this.component = component);

  openSidePanel() {
    this.props.openSidePanel();
    this.props.addLastFocusElement(
      `[data-testid="${BURGER_MENU_BUTTON_DATA_TEST_ID}"]`
    );
  }

  render() {
    const { formatTranslation } = this.props;
    return (
      <button
        className={styles.burger}
        onClick={this.openSidePanel}
        ref={this.saveRef}
        aria-label={formatTranslation("burger_open_navigation_label")}
        tabIndex={this.props.isSidePanelOpen ? -1 : null}
        data-testid={BURGER_MENU_BUTTON_DATA_TEST_ID}
      >
        <Icon icon={styles.icon} />
      </button>
    );
  }
}

export default withTranslation(Burger);
