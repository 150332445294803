import { getDefaultStoreUrl } from "../../../modules/regionalStore";

const withProductLink = defaultStoreUrl => item =>
  item.variantId
    ? {
        ...item,
        productUrl: `${defaultStoreUrl}prd/${item.productId}#variantId-${item.variantId}`
      }
    : item;

export default function bagWithProductLinks(bag, getState) {
  const state = getState();
  const defaultStoreUrl = getDefaultStoreUrl(state);
  return {
    ...bag,
    items: bag.items.map(withProductLink(defaultStoreUrl))
  };
}
