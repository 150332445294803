import classnames from "classnames";
import { navigationPropType } from "@template/state/modules/navigation";
import { getBackgroundImageUrl } from "@template/helpers/navigationBackgroundImageResolver";
import NavAnchor from "@template/components/Navigation/NavAnchor";
import LazyImage from "@template/components/LazyImage";
import { BACKGROUND_LOADING_COLORS } from "../../../LazyImage/constants";
import styles from "./index.css";

const cx = classnames.bind(styles);

const MarketingImage = ({ item }) => (
  <ul
    aria-labelledby={item.id}
    className={cx(styles.container, {
      [styles.container__column]: item.largeScreenColumnSpan === 1
    })}
  >
    {itemList(item)}
  </ul>
);

const truncatedLabel = (item, largeScreenColumnSpan) =>
  largeScreenColumnSpan === 3
    ? item.label.substring(0, 26)
    : item.label.substring(0, 36);

const itemList = ({
  items,
  largeScreenColumnSpan,
  isFirstColumn,
  isLastColumn
}) =>
  items.map((item, index) => {
    const labelClasses = {
      [styles[`label__${item.largeScreenStyleType}`]]: true
    };

    const thumbnailGradientType = (numberOfItems, numberOfCols) => {
      const type = numberOfItems / numberOfCols;
      return type > 2 ? "horizontal" : "vertical";
    };

    return (
      <li key={item.id} className={styles.marketingItem}>
        <NavAnchor
          item={item}
          className={styles.item}
          data-testid="marketing-image"
          data-first={isFirstColumn && index === 0 ? true : null}
          data-last={isLastColumn && index === items.length - 1 ? true : null}
        >
          <LazyImage
            alt={item.largeScreenStyleType === "noTitle" ? item.label : ""}
            src={getBackgroundImageUrl(item.largeScreenImageUrl, 320)}
            className={styles.image}
            tag={"img"}
            loadingColor={BACKGROUND_LOADING_COLORS.silverForDesktop}
            style={{
              minHeight: "100px",
              transition: "height 0.2s"
            }}
          />

          <div
            className={cx(
              styles.thumbnail,
              styles[
                thumbnailGradientType(items.length, largeScreenColumnSpan)
              ],
              styles[item.largeScreenStyleType]
            )}
          />

          {item.largeScreenStyleType !== "noTitle" ? (
            <span className={cx(styles.label, labelClasses)}>
              {truncatedLabel(item, largeScreenColumnSpan)}
            </span>
          ) : null}
        </NavAnchor>
      </li>
    );
  });

MarketingImage.propTypes = {
  item: navigationPropType.isRequired
};

export default MarketingImage;
