import PropTypes from "prop-types";

import { LARGE_VIEWPORT } from "@template/state/modules/layout/constants";
import FloorNavigation from "@template/components/FloorNavigation";
import Burger from "@template/components/Burger";
import Widgets from "@template/components/Widgets";
import SearchForm from "@template/components/SearchForm";

import HomeLogo from "./HomeLogo";
import styles from "./index.css";

const Header = ({ clearNavActiveItem }) => (
  <div className={styles.container} onMouseEnter={clearNavActiveItem}>
    <div className={styles.body} data-testid="header">
      <div className={styles.leftBody}>
        <Burger />
        <HomeLogo />
        <FloorNavigation viewable={[LARGE_VIEWPORT]} />
        <SearchForm />
      </div>
      <Widgets />
    </div>
  </div>
);

Header.propTypes = {
  clearNavActiveItem: PropTypes.func.isRequired
};

export default Header;
