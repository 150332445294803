import { GET_SEGMENTS, SET_SEGMENTS_FETCHING_STATUS } from "./constants";

export { getAudienceHasResponded, getAudienceSegments } from "./selectors";
export { getAudiencesFromSDK } from "./actions";

const initialState = {
  fetchingStatus: null,
  segments: null
};

export const reduce = (state = initialState, action) => {
  switch (action.type) {
    case GET_SEGMENTS:
      return {
        ...state,
        fetchingStatus: action.payload.fetchingStatus,
        segments: action.payload.segments
          .map(({ segmentId }) => segmentId)
          .join(",")
      };
    case SET_SEGMENTS_FETCHING_STATUS:
      return {
        ...state,
        fetchingStatus: action.payload
      };
    default:
      return state;
  }
};
