import { gregorianEaster } from "date-easter";
import { handleEventDate } from "./handleEventDate";
import {
  EASTER,
  DAYBEFORE,
  FIRSTDAY,
  LASTDAY,
  DAYAFTER
} from "./eventConstants";

const todaysDate = new Date();
const currentYear = todaysDate.getUTCFullYear();

export const getEasterDate = year => {
  const getDate = date => [date.day, date.month];
  return getDate(gregorianEaster(year));
};

export const getEasterEventDateRange = easterDate => {
  const getExtendedDays = ([day, month], dateType) => {
    const eventDateRange = new Date();
    eventDateRange.setDate(day);
    eventDateRange.setMonth(month - 1);
    const delta = {
      [DAYBEFORE]: -3,
      [FIRSTDAY]: -2,
      [LASTDAY]: 2,
      [DAYAFTER]: 3
    }[dateType];

    const addDays = (date, days) => date.setDate(date.getDate() + days);
    addDays(eventDateRange, delta);

    return handleEventDate(eventDateRange);
  };

  return {
    name: EASTER,
    [DAYBEFORE]: getExtendedDays(easterDate, [DAYBEFORE]),
    [FIRSTDAY]: getExtendedDays(easterDate, [FIRSTDAY]),
    [LASTDAY]: getExtendedDays(easterDate, [LASTDAY]),
    [DAYAFTER]: getExtendedDays(easterDate, [DAYAFTER])
  };
};

export const getEasterEventDateRangeCurrentYear = getEasterEventDateRange(
  getEasterDate(currentYear)
);
