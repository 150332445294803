import { useContext } from "react";
import context from "../translation/context";

const withTranslation = Component => {
  const ComposedComponent = ({ ...props }) => {
    const formatTranslation = useContext(context);

    return <Component {...{ ...props, formatTranslation }} />;
  };

  ComposedComponent.displayName = `withTranslation(${
    Component.displayName || Component.name || "Component"
  })`;
  ComposedComponent.WrappedComponent = Component;

  return ComposedComponent;
};

export default withTranslation;
