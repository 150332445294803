const PROMISE_TIMEOUT = 5000;
const DEFAULT_ERROR = "Bag SDK not responding";

const wrapPromiseWithTimeout = (
  promise,
  ms = PROMISE_TIMEOUT,
  error = DEFAULT_ERROR
) =>
  new Promise((resolve, reject) => {
    promise.then(resolve).catch(reject);
    setTimeout(() => {
      reject(new Error(error));
    }, ms);
  });

export default wrapPromiseWithTimeout;
