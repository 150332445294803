import PropTypes from "prop-types";
import styles from "./index.css";
import { withTranslation } from "@template/components/translation";

const USSalesTax = ({ countryCode, formatTranslation }) =>
  countryCode === "US" ? (
    <small className={styles.USSalesTax}>
      {formatTranslation("minibag_subtotal_ussalestax")}
    </small>
  ) : null;

USSalesTax.propTypes = {
  countryCode: PropTypes.string.isRequired,
  formatTranslation: PropTypes.func.isRequired
};

export default withTranslation(USSalesTax);
