import { setCookieValue, removeCookie } from "@src/client/context/cookies";
import { OPTANON_CONSENT_COOKIE, oneYearInDays } from "./constants";
import getCookieDomains from "./getCookieDomains";

const handleOptanonConsentCookies = (
  optanonConsentCookies,
  optanonAlertBoxClosedCookies
) => {
  const domains = getCookieDomains();
  if (optanonConsentCookies.length === 2) {
    optanonConsentCookies.sort((cookie1, cookie2) => {
      const cookieDate1 = cookie1.match(/datestamp=(.*\))/)?.[1];
      const cookieDate2 = cookie2.match(/datestamp=(.*\))/)?.[1];

      return (
        new Date(cookieDate2?.replaceAll("+", " ")) -
        new Date(cookieDate1?.replaceAll("+", " "))
      );
    });
  }

  domains.forEach(domain => {
    removeCookie(OPTANON_CONSENT_COOKIE, domain);
  });

  if (optanonAlertBoxClosedCookies.length) {
    setCookieValue(OPTANON_CONSENT_COOKIE, optanonConsentCookies[0], {
      expires: oneYearInDays,
      domain: domains[0]
    });
  }
};

export default handleOptanonConsentCookies;
