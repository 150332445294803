import {
  getGeoCountry,
  getBrowseCountry
} from "@asosteam/asos-web-store-presentation-node";
import { getParamValue } from "../../helpers/url";

export default function templateMatchesCountry(contextObject) {
  if (getParamValue("browseCountry")) {
    return true;
  }

  if (userHasAPreferredCountry()) {
    return true;
  }

  if (!getGeoCountry()) {
    return true;
  }

  if (!contextObject || !contextObject.browseCountry) {
    return true;
  }

  if (templateMatchesGeolocation(contextObject.browseCountry)) {
    return true;
  }

  return false;
}

const userHasAPreferredCountry = () => getBrowseCountry();
const templateMatchesGeolocation = templateCountry =>
  getGeoCountry() === templateCountry;
