import { trackOneTrustEvents } from "@src/helpers/eventing/events.js";
import { getIsCookieBannerShowing } from "./getIsCookieBannerShowing";

const REJECT_ALL = "reject all";
const ALLOW_ALL = "allow all";
const ALLOW_SELECTED_ONLY = "allow selected only";

const getConsentStates = () => ({
  customerPreferenceCookies: window.OnetrustActiveGroups.includes("C0003"),
  targetingOrAdvertisingCookies: window.OnetrustActiveGroups.includes("C0004")
});

const addClickEventTrackingHandler = (button, consentStatus) => {
  button?.addEventListener("click", () => {
    trackOneTrustEvents({
      consentStatus,
      ...getConsentStates()
    });
  });
};

export const trackAnalytics = () => {
  // Cookie Banner is conditionally in the DOM
  if (getIsCookieBannerShowing()) {
    const thatsOkButton = document.getElementById(
      "onetrust-accept-btn-handler"
    );

    const rejectAllCookieBannerButton = document.getElementById(
      "onetrust-reject-all-handler"
    );

    addClickEventTrackingHandler(thatsOkButton, ALLOW_ALL);
    addClickEventTrackingHandler(rejectAllCookieBannerButton, REJECT_ALL);
  }

  // Preference Center is currently always in the DOM
  const allowAllButton = document.getElementById(
    "accept-recommended-btn-handler"
  );
  const allowSelectedOnlyButton = document.querySelector(
    ".save-preference-btn-handler"
  );
  const rejectAllPreferenceCentreButton = document.querySelector(
    ".ot-pc-refuse-all-handler"
  );

  addClickEventTrackingHandler(allowSelectedOnlyButton, ALLOW_SELECTED_ONLY);
  addClickEventTrackingHandler(allowAllButton, ALLOW_ALL);
  addClickEventTrackingHandler(rejectAllPreferenceCentreButton, REJECT_ALL);
};
