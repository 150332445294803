import SearchForm from "./SearchForm";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  submitSearch,
  loadSuggestions,
  getVisibility,
  closeSearch,
  getResultVisibility,
  setSearchTerm,
  getSearchTerm,
  getSearchItems,
  openSearch,
  isSuggestions
} from "@template/state/modules/search";
import {
  getViewport,
  focusLastActiveElement,
  getLastActiveElements
} from "@template/state/modules/layout";
import {
  getDefaultStoreUrl,
  getSearchUrl
} from "@template/state/modules/regionalStore";

function mapStateToProps(state) {
  return {
    isVisible: getVisibility(state),
    hasVisibleResults: getResultVisibility(state),
    viewport: getViewport(state),
    term: getSearchTerm(state),
    searchUrl: getSearchUrl(state),
    storeUrl: getDefaultStoreUrl(state),
    lastActiveElements: getLastActiveElements(state),
    items: getSearchItems(state),
    isSuggestions: isSuggestions(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onSearch: submitSearch,
      loadSuggestions: loadSuggestions,
      setSearchTerm: setSearchTerm,
      closeSearch: closeSearch,
      openSearch: openSearch,
      focusLastActiveElement: focusLastActiveElement
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
