import { useSelector, useDispatch } from "react-redux";
import { getOtherSites } from "@template/state/modules/regionalStore";
import InternationalSites from "./InternationalSites";
import { openCountrySelector } from "@template/state/modules/countrySelector";

const ConnectedInternationalSites = ownProps => {
  const otherSites = useSelector(getOtherSites);
  const dispatch = useDispatch();
  const onSiteClicked = countryCode =>
    dispatch(openCountrySelector(countryCode));

  return (
    <InternationalSites
      {...{
        ...ownProps,
        onSiteClicked,
        otherSites
      }}
    />
  );
};

export default ConnectedInternationalSites;
