import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./index.css";
import { withTranslation } from "@template/components/translation";
import {
  trackPageInteraction,
  ANALYTICS_INTERACTION_CLICK,
  ANALYTICS_CONTEXT
} from "../../../helpers/eventing/events";
import CountryFlag from "../CountryFlag";

const CountrySelectorButton = ({
  formatTranslation,
  countryFlag,
  countryName,
  withLabels,
  position,
  openCountrySelector,
  closeSidePanel
}) => {
  const handleClick = () => {
    closeSidePanel(); //@TODO: this should be handled in middleware
    openCountrySelector();
    trackPageInteraction({
      context: ANALYTICS_CONTEXT.openCountryModal,
      interaction: ANALYTICS_INTERACTION_CLICK,
      properties: {
        positionOnPage: position
      }
    });
  };

  return (
    <div
      className={classnames(styles.container, {
        [styles.withLabels]: withLabels
      })}
      data-testid="country-selector"
    >
      <button
        className={styles.button}
        data-testid="country-selector-btn"
        type="button"
        onClick={handleClick}
        aria-label={`${formatTranslation(
          "country_selector_button_locationtext"
        )} ${countryName} ${formatTranslation(
          "country_selector_button_change_button"
        )}`}
      >
        {withLabels && (
          <span className={styles.locationText}>
            {formatTranslation("country_selector_button_locationtext")}
          </span>
        )}
        <CountryFlag {...{ imageUrl: countryFlag, name: countryName }} />
        {withLabels && (
          <div className={styles.changeLabel}>
            <span className={styles.pipe} aria-hidden="true" />
            <span>
              {formatTranslation("country_selector_button_change_button")}
            </span>
          </div>
        )}
      </button>
    </div>
  );
};

CountrySelectorButton.defaultProps = {
  withLabels: false
};

CountrySelectorButton.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  countryFlag: PropTypes.string.isRequired,
  countryName: PropTypes.string.isRequired,
  openCountrySelector: PropTypes.func.isRequired,
  withLabels: PropTypes.bool,
  position: PropTypes.string.isRequired,
  closeSidePanel: PropTypes.func.isRequired
};

export default withTranslation(CountrySelectorButton);
