import PropTypes from "prop-types";
import styles from "./index.css";
import {
  viewportPropType,
  SMALL_VIEWPORT,
  MEDIUM_VIEWPORT
} from "../../../state/modules/layout";

const maxProvidersFor = viewport => (viewport === MEDIUM_VIEWPORT ? 7 : 10);

const PaymentOptions = ({ paymentProviders, viewport }) =>
  viewport !== SMALL_VIEWPORT && (
    <ul className={styles.paymentOptions}>
      {paymentProviders.slice(0, maxProvidersFor(viewport)).map(provider => (
        <li key={provider.name} className={styles.provider}>
          <img src={provider.logo} alt={provider.label} />
        </li>
      ))}
    </ul>
  );

PaymentOptions.propTypes = {
  paymentProviders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired
    })
  ).isRequired,
  viewport: viewportPropType
};

export default PaymentOptions;
