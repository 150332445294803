import { useState } from "react";
import FocusLock, { InFocusGuard } from "react-focus-lock";
import publicModals from "./PublicModals";

const Modals = () => {
  const [modalsState, setModalsState] = useState(
    publicModals.reduce(
      (acc, [modalName]) => ({ ...acc, [modalName]: { isOpen: false } }),
      {}
    )
  );

  const updateModalState = modalName => isOpen => {
    if (!isOpen || !isAnyModalOpen) {
      setModalsState(prev => {
        return {
          ...prev,
          [modalName]: {
            isOpen
          }
        };
      });
    } else {
      throw new Error("There's a modal already open");
    }
  };

  const isAnyModalOpen = Object.values(modalsState).some(
    ({ isOpen }) => isOpen
  );

  return (
    <FocusLock disabled={!isAnyModalOpen} noFocusGuards>
      <InFocusGuard>
        {publicModals.map(([modalName, Component]) => (
          <Component
            key={modalName}
            isOpen={modalsState[modalName].isOpen}
            setIsOpen={isOpen => updateModalState(modalName)(isOpen)}
          />
        ))}
      </InFocusGuard>
    </FocusLock>
  );
};

export default Modals;
