import { generateNlid } from "./generateNlid";
import { getNlid } from "./getNlid";

export const processLink = ({ link, gender, section, container, label }) => {
  const url = new URL(link);
  const hash = new URLSearchParams(url.hash.slice(1));
  const nlid = generateNlid({
    existingNlid: getNlid({ url, hash }),
    gender,
    section,
    container,
    label
  });

  if (!url.origin.includes("marketplace")) {
    hash.set("nlid", nlid);
    url.searchParams.delete("nlid");
  } else {
    url.searchParams.set("nlid", nlid);
    hash.delete("nlid");
  }

  url.hash = hash.toString();

  return url
    .toString()
    .replace(/%20/g, "+")
    .replace(/%7C/g, "|")
    .replace(/%2C/g, ",")
    .replace(/%3A/g, ":")
    .replace(/%3F/g, "?")
    .replace(/%3D/g, "=");
};
