import { connect } from "react-redux";
import MiniBag from "./MiniBag";
import {
  getBagItems,
  getLoading,
  bagItemDeleteTransitionEnd
} from "../../state/modules/bag";
import {
  getDefaultStoreUrl,
  getDefaultLanguage
} from "../../state/modules/regionalStore";

const mapStateToProps = state => {
  return {
    defaultStoreUrl: getDefaultStoreUrl(state),
    items: getBagItems(state),
    lang: getDefaultLanguage(state),
    loading: getLoading(state)
  };
};

const mapDispatchToProps = {
  bagItemDeleteTransitionEnd
};

export default connect(mapStateToProps, mapDispatchToProps)(MiniBag);
