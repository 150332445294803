import { adobeSdkProvider } from "../adobeSdkProvider";
import { trackLink } from "../trackLink";
import {
  ADOBE_PAGE_NAME_KEY,
  ADOBE_EVENTS_KEY,
  ADOBE_EVENT_COOKIE_CONSENT_CLICK,
  ADOBE_GENERIC_ACTION_KEY,
  ADOBE_COOKIE_CONSENT_KEY,
  ADOBE_LINK_NAME_COOKIE_CONSENT_SUBMISSION
} from "../constants";

export const trackCookieConsentBannerClick = function ({
  consentStatus,
  customerPreferenceCookies,
  targetingOrAdvertisingCookies
}) {
  const adobeSdk = adobeSdkProvider();

  const data = {
    [ADOBE_GENERIC_ACTION_KEY]: `${adobeSdk[ADOBE_PAGE_NAME_KEY]}|cookie consent banner|Click`,
    [ADOBE_COOKIE_CONSENT_KEY]: `consentStatus:${consentStatus}|customerPreferenceCookies:${customerPreferenceCookies}|targetingOrAdvertisingCookies:${targetingOrAdvertisingCookies}`,
    [ADOBE_EVENTS_KEY]: ADOBE_EVENT_COOKIE_CONSENT_CLICK
  };

  trackLink({ name: ADOBE_LINK_NAME_COOKIE_CONSENT_SUBMISSION, data });
};
