export const trimAndSortConsentCategoryIds = consentCategoryIds => {
  if (!consentCategoryIds) {
    return null;
  }

  return consentCategoryIds
    .split(",")
    .filter(x => x)
    .sort()
    .join(",");
};
