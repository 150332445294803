import logger from "../helpers/logger";
import { storeUrlsForAllFloors, DEFAULT } from "../helpers/storeUrls";
import { getNavigationUrl } from "./apiUrls";
import { mapNavigationState } from "./navigation/mapNavigationState";
import { validateMappedNavigation } from "./navigation/navigationUtils";
import request from "./request";

const fetchNavigation = async (
  {
    country,
    lang,
    dateTime,
    includeDraft,
    showTestContent,
    platform,
    storeUrl,
    variantKey,
    keyStoreDataversion,
    opts = {}
  } = {},
  previewMode
) => {
  const url = getNavigationUrl(
    {
      country,
      lang,
      keyStoreDataversion,
      dateTime,
      includeDraft,
      showTestContent,
      variantKey
    },
    previewMode
  );

  if (!storeUrl && country) {
    storeUrl = await storeUrlsForAllFloors(country, keyStoreDataversion);
    storeUrl = storeUrl[DEFAULT];
  }

  logger.info("Fetching navigation API from", url);
  const navigation = await request(url, opts);
  const mappedNavigation = {
    countryCode: country,
    tree: mapNavigationState(
      navigation.navigation,
      null,
      null,
      platform,
      storeUrl
    ),
    footer: mapNavigationState(
      navigation.footer,
      null,
      null,
      platform,
      storeUrl
    )
  };

  if (!validateMappedNavigation(mappedNavigation)) {
    throw new Error("Nav API response not valid");
  }

  return mappedNavigation;
};

export default fetchNavigation;
