import {
  getKeyStoreDataversion,
  setKeyStoreDataversion
} from "@asosteam/asos-web-store-presentation-node";

import {
  asosGlobalNamespace,
  manifestWindowKey
} from "@src/template/constants";

import { getKeyStoreDataversion as getKeyStoreDataversionFromManifest } from "@src/helpers/manifest";

import {
  setCustomAttributeOnNextRequest,
  finishSettingCustomAttributeFromPreviousRequest as completeLogging
} from "@src/helpers/monitoring";

const loggingAttributeNameForCorrectedKSDVCookie =
  "InvalidKeyStoreDataversionCookieWasCorrected";

export const correctInvalidKeyStoreDataversion = () => {
  const manifest = window[asosGlobalNamespace][manifestWindowKey];

  const keyStoreDataversionFromCookie = getKeyStoreDataversion();
  const keyStoreDataversionFromManifest =
    getKeyStoreDataversionFromManifest(manifest);

  if (keyStoreDataversionFromCookie != keyStoreDataversionFromManifest) {
    setKeyStoreDataversion(keyStoreDataversionFromManifest);
    setCustomAttributeOnNextRequest(
      loggingAttributeNameForCorrectedKSDVCookie,
      JSON.stringify({
        from: keyStoreDataversionFromCookie,
        to: keyStoreDataversionFromManifest
      })
    );

    window.location.reload();
  }
};

export const finishSettingCustomAttributeFromPreviousRequest = () => {
  completeLogging(loggingAttributeNameForCorrectedKSDVCookie);
};
