import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideNavigationContainer from "./SideNavigationContainer";
import { setNavActiveItem } from "@template/state/modules/navigation";
import { addLastFocusElement } from "@template/state/modules/layout";

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { setNavActiveItem, addLastFocusElement },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(SideNavigationContainer);
