import PropTypes from "prop-types";
import {
  UPDATE_VIEWPORT_CATEGORY,
  SMALL_VIEWPORT,
  MEDIUM_VIEWPORT,
  LARGE_VIEWPORT,
  SET_GENDER,
  OPEN_SIDE_PANEL,
  CLOSE_SIDE_PANEL,
  MALE,
  FEMALE,
  DEFAULT,
  SET_GEO_COUNTRY,
  REMOVE_LAST_ACTIVE_ELEMENT,
  ADD_LAST_ACTIVE_ELEMENT,
  CLEAR_LAST_ACTIVE_ELEMENTS,
  TOGGLE_MAIN_APP_ARIA_HIDDEN
} from "./constants";
import {
  OPEN_SEARCH,
  CLOSE_SEARCH
} from "@template/state/modules/search/constants";
import {
  OPEN_COUNTRY_SELECTOR,
  CLOSE_COUNTRY_SELECTOR
} from "@template/state/modules/countrySelector/constants";

export {
  MALE,
  FEMALE,
  DEFAULT,
  SMALL_VIEWPORT,
  MEDIUM_VIEWPORT,
  LARGE_VIEWPORT,
  SECURE
} from "./constants";

export {
  setGender,
  openSidePanel,
  closeSidePanel,
  publishGeoCountry,
  addLastFocusElement,
  updateViewportCategory,
  focusLastActiveElement,
  toggleMainAppAriaHidden
} from "./actions";

export {
  isFemale,
  isMale,
  hasGender,
  isSidePanelOpen,
  getViewport,
  getCurrentGender,
  getPlatform,
  getLastActiveElements,
  getGeoCountry
} from "./selectors";

export const genderPropType = PropTypes.oneOf([MALE, FEMALE, DEFAULT]);
export const viewportPropType = PropTypes.oneOf([
  SMALL_VIEWPORT,
  MEDIUM_VIEWPORT,
  LARGE_VIEWPORT
]);

const initialState = {
  viewportCategory: SMALL_VIEWPORT,
  isSidePanelOpen: false,
  cookieBannerType: undefined,
  gender: DEFAULT,
  platform: "desktop",
  lastActiveElements: []
};

const validateViewport = (state, viewportCategory) =>
  [SMALL_VIEWPORT, MEDIUM_VIEWPORT, LARGE_VIEWPORT].includes(viewportCategory)
    ? viewportCategory
    : state.viewportCategory;

export const reduce = (state = initialState, action) => {
  let lastFocusElementIndex;
  switch (action.type) {
    case UPDATE_VIEWPORT_CATEGORY:
      return {
        ...state,
        viewportCategory: validateViewport(
          state,
          action.payload.viewportCategory
        )
      };
    case SET_GENDER:
      return {
        ...state,
        gender: action.payload
      };
    case TOGGLE_MAIN_APP_ARIA_HIDDEN: {
      const { isMainAppAriaHidden } = action.payload;

      return {
        ...state,
        isMainAppAriaHidden
      };
    }
    case OPEN_SIDE_PANEL:
      return {
        ...state,
        isSidePanelOpen: true,
        isMainAppAriaHidden: true
      };
    case CLOSE_SIDE_PANEL:
      return {
        ...state,
        isSidePanelOpen: false,
        isMainAppAriaHidden: false
      };
    case SET_GEO_COUNTRY:
      return {
        ...state,
        geoCountry: action.payload
      };
    case OPEN_SEARCH: {
      if (action.payload.isModal) {
        return {
          ...state,
          isMainAppAriaHidden: true
        };
      }

      return state;
    }
    case CLOSE_SEARCH: {
      if (action.payload.isModal) {
        return {
          ...state,
          isMainAppAriaHidden: false
        };
      }

      return state;
    }
    case OPEN_COUNTRY_SELECTOR:
      return {
        ...state,
        isMainAppAriaHidden: true
      };
    case CLOSE_COUNTRY_SELECTOR:
      return {
        ...state,
        isMainAppAriaHidden: false
      };
    case ADD_LAST_ACTIVE_ELEMENT:
      return {
        ...state,
        lastActiveElements: [...state.lastActiveElements, action.activeElement]
      };
    case CLEAR_LAST_ACTIVE_ELEMENTS:
      lastFocusElementIndex = state.lastActiveElements.indexOf(
        action.forceElementFocus
      );

      return {
        ...state,
        lastActiveElements:
          lastFocusElementIndex > 0
            ? state.lastActiveElements.slice(0, lastFocusElementIndex)
            : []
      };
    case REMOVE_LAST_ACTIVE_ELEMENT:
      if (
        action.lastActiveElement ===
        state.lastActiveElements[state.lastActiveElements.length - 1]
      )
        return {
          ...state,
          lastActiveElements: [...state.lastActiveElements.slice(0, -1)]
        };
      return state;
  }

  return state;
};
