import { createSelector } from "reselect";
import { DISMISS_BAG_ERROR, BAG_ITEM_DELETE_TRANSITION_END } from "./";

export const getBag = state => state.bag;

export const getSellerChanged = createSelector(
  getBag,
  bag => bag?.sellerChanged
);

export const getContainsHotProduct = createSelector(
  getBag,
  bag => bag?.containsHotProduct
);

export const getLoading = createSelector(getBag, bag => bag?.loading);

export const getBagItems = createSelector(getBag, bag => bag?.items);

export const getBagSummary = createSelector(getBag, bag => bag?.summary);

export const getTotalPrice = createSelector(
  getBagSummary,
  bagSummary => bagSummary?.totalPrice || {}
);

export const getTotalPriceText = createSelector(
  getTotalPrice,
  totalPrice => totalPrice?.text
);

export const getTotalQuantity = createSelector(
  getBagSummary,
  bagSummary => bagSummary?.totalQuantity
);

export const displayReservationMessage = createSelector(
  getBag,
  bag => bag?.displayReservationMessage
);

export const bagError = createSelector(getBag, bag => bag?.bagError);

export const dismissBagError = () => {
  return dispatch => {
    dispatch({
      type: DISMISS_BAG_ERROR
    });
  };
};

export const getUpdated = createSelector(getBag, bag => bag?.isUpdated);

// why is this in here? ⬇️
export const bagItemDeleteTransitionEnd = itemId => dispatch =>
  dispatch({
    type: BAG_ITEM_DELETE_TRANSITION_END,
    itemId
  });
