import { asosGlobalNamespace } from "../../../constants";

export const emitContentTargetingEvent = data => {
  if (window[asosGlobalNamespace].contentTargeting) {
    window[asosGlobalNamespace].contentTargeting.emit(data);
  }
};

export const emitNewRelicEvent = data => {
  if (window.NREUM) {
    window.NREUM.addPageAction("globalBannerApiError", data);
  }
};
