export const STPATRICKS = "stPatricks";
export const PRIDE = "pride";
export const HALLOWEEN = "halloween";
export const CHRISTMAS = "christmas";
export const VALENTINES = "valentines";
export const EASTER = "easter";
export const DAYBEFORE = "dayBefore";
export const FIRSTDAY = "firstDay";
export const LASTDAY = "lastDay";
export const DAYAFTER = "dayAfter";
