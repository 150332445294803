import getFeature from "@template/helpers/getFeature";

const isFeatureEnabled = async (featureName, forceTracking) => {
  const feature = await getFeature(featureName);

  return feature
    ? forceTracking
      ? feature.isEnabled(forceTracking)
      : feature.isEnabled()
    : false;
};

export default isFeatureEnabled;
