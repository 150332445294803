import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import DebounceTimer from "./DebounceTimer";
import styles from "./index.css";

const InfoBannerControl = ({
  children,
  cosmeticStyles,
  forceHide,
  hasShownCallback,
  shouldShow,
  shouldAutoHide
}) => {
  const [isBouncing, setIsBouncing] = useState(false);
  const [isHiding, setIsHiding] = useState(false);
  const [inTheDom, setInTheDom] = useState(false);

  const timerRef = useRef(null);
  const prevShouldShow = useRef(false);

  const setupTimer = () => {
    if (!timerRef.current) {
      timerRef.current = new DebounceTimer({
        callback: () => setIsHiding(true)
      });
    }

    timerRef.current.start();
  };

  const removeTimer = () => {
    timerRef.current?.stop();
    timerRef.current = null;
  };

  const shouldBounce =
    !isHiding && (!!timerRef.current?.isTimerRunning() || !shouldAutoHide);

  useEffect(() => {
    if (shouldShow) {
      setInTheDom(true);
    }
  }, [shouldShow]);

  useEffect(() => {
    if (shouldShow && !prevShouldShow.current) {
      if (shouldAutoHide) {
        setupTimer();
      }

      if (shouldBounce) {
        setIsBouncing(true);
      } else if (isHiding) {
        setIsHiding(false);
      }

      hasShownCallback();

      prevShouldShow.current = true;
    } else {
      prevShouldShow.current = false;
    }
  }, [shouldShow, shouldAutoHide, shouldBounce, isHiding, hasShownCallback]);

  useEffect(() => {
    if (forceHide) {
      setIsHiding(true);
    }

    return () => {
      removeTimer();
    };
  }, [forceHide]);

  const finishedMoving = () => {
    if (isHiding) {
      setInTheDom(false);
      setIsHiding(false);
    }
  };

  const finishedBouncing = () => setIsBouncing(false);

  const wrapperClassnames = cx(styles.infoBannerWrapper, {
    [styles.forceHide]: forceHide,
    [styles.hide]: isHiding && !isBouncing,
    [styles.show]: !isHiding
  });

  const contentClassnames = cx(styles.infoBanner, cosmeticStyles.background, {
    [styles.bounce]: isBouncing
  });

  return (
    inTheDom && (
      <div className={wrapperClassnames} onAnimationEnd={finishedMoving}>
        <div className={contentClassnames} onAnimationEnd={finishedBouncing}>
          {children}
        </div>
      </div>
    )
  );
};

InfoBannerControl.propTypes = {
  cosmeticStyles: PropTypes.exact({
    background: PropTypes.string
  }),
  hasShownCallback: PropTypes.func.isRequired,
  shouldAutoHide: PropTypes.bool,
  shouldShow: PropTypes.bool,
  children: PropTypes.node,
  forceHide: PropTypes.bool
};

InfoBannerControl.defaultProps = {
  shouldAutoHide: true
};

export default InfoBannerControl;
