import styles from "./Widgets.css";
import stylesDeferred from "./deferred.css";

export const MY_ACCOUNT_CHRISTMAS_FILLED = {
  type: "accountChristmasFilled",
  icon: stylesDeferred.widgetMyAccountChristmasLoggedIn
};
export const MY_ACCOUNT_CHRISTMAS_UNFILLED = {
  type: "accountChristmasUnfilled",
  icon: stylesDeferred.widgetMyAccountChristmasLoggedOut
};
export const MY_ACCOUNT_EASTER_FILLED = {
  type: "accountEasterFilled",
  icon: stylesDeferred.widgetMyAccountEasterLoggedIn
};
export const MY_ACCOUNT_EASTER_UNFILLED = {
  type: "accountEasterUnfilled",
  icon: stylesDeferred.widgetMyAccountEasterLoggedOut
};
export const MY_ACCOUNT_HALLOWEEN_FILLED = {
  type: "accountHalloweenFilled",
  icon: stylesDeferred.widgetMyAccountHalloweenLoggedIn
};
export const MY_ACCOUNT_HALLOWEEN_UNFILLED = {
  type: "accountHalloweenUnfilled",
  icon: stylesDeferred.widgetMyAccountHalloweenLoggedOut
};
export const MY_ACCOUNT_UNFILLED = {
  type: "accountUnfilled",
  icon: styles.widgetMyAccountLoggedOut
};
export const MY_ACCOUNT_FILLED = {
  type: "accountFilled",
  icon: stylesDeferred.widgetMyAccountLoggedIn
};
export const MY_ACCOUNT_STPATRICKS_FILLED = {
  type: "accountStPatricksFilled",
  icon: stylesDeferred.widgetMyAccountStPatricksLoggedIn
};
export const MY_ACCOUNT_STPATRICKS_UNFILLED = {
  type: "accountStPatricksUnfilled",
  icon: stylesDeferred.widgetMyAccountStPatricksLoggedOut
};
export const SAVED_ITEMS_FILLED = {
  type: "heartFilled",
  icon: stylesDeferred.widgetSavedItemsFilled
};
export const SAVED_ITEMS_UNFILLED = {
  type: "heartUnfilled",
  icon: styles.widgetSavedItemsUnfilled
};
export const SAVED_ITEMS_VALENTINES_FILLED = {
  type: "savedValentinesFilled",
  icon: stylesDeferred.widgetSavedItemsValentinesFilled
};
export const SAVED_ITEMS_VALENTINES_UNFILLED = {
  type: "savedValentinesUnfilled",
  icon: stylesDeferred.widgetSavedItemsValentinesUnfilled
};
export const SAVED_ITEMS_PRIDE_FILLED = {
  type: "heartPrideFilled",
  icon: stylesDeferred.widgetSavedItemsPrideFilled
};
export const BAG_UNFILLED = {
  type: "bagUnfilled",
  icon: styles.widgetBagUnfilled
};
export const BAG_FILLED = {
  type: "bagFilled",
  icon: stylesDeferred.widgetBagFilled
};
