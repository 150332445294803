import clientConfig from "@template/clientConfig";
const clientVariables = clientConfig.get();

export const withParams = (url, params = {}) => {
  const parsedUrl =
    typeof window === "undefined"
      ? new URL(url)
      : new URL(url, window.location.origin);

  Object.keys(params).forEach(key => {
    parsedUrl.searchParams.set(key, params[key]);
  });

  return parsedUrl.toString();
};

export const setLocalXSiteOrigin = () => {
  if (clientConfig.isLocal()) {
    document.cookie = `x-site-origin=${clientVariables.API_XSITEORIGIN_BASE}`;
  }
};
