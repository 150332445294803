import { useEffect, useState } from "react";
import loadSecureScriptContainer from "./loadSecureScriptContainer";
import { useSelector } from "react-redux";
import { getFeaturesLoaded } from "@template/state/modules/features";
import getFeature from "@template/helpers/getFeature";

const SecureScriptContainer = () => {
  const { featuresLoaded = false } = useSelector(state => {
    return {
      featuresLoaded: getFeaturesLoaded(state)
    };
  });

  const [featEnabled, setFeatEnabled] = useState(false);

  useEffect(() => {
    const getFeatureEnabled = async () => {
      const feature = await getFeature("ssc-browse");
      setFeatEnabled(feature.isEnabled());
    };
    if (featuresLoaded) {
      getFeatureEnabled();
    }
  }, [featuresLoaded]);

  useEffect(() => {
    if (featEnabled && featuresLoaded) {
      loadSecureScriptContainer();
    }
  }, [featuresLoaded, featEnabled]);

  return null;
};

export default SecureScriptContainer;
