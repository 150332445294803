import PropTypes from "prop-types";
import classnames from "classnames";
import IconButton from "../IconButton";
import { withTranslation } from "@template/components/translation";
import styles from "./index.css";

const ariaLiveLabels = {
  alert: "assertive",
  error: "assertive",
  promotion: "polite",
  success: "polite"
};

const Notification = ({
  children,
  formatTranslation,
  hasIcon = true,
  onClose,
  type
}) => {
  return (
    <div
      aria-live={ariaLiveLabels[type]}
      className={classnames({
        [styles.notification]: true,
        [styles[type]]: true,
        [styles.hasBackground]: true,
        [styles.hasIcon]: hasIcon
      })}
      data-testid="notification"
    >
      <div className={styles.text}>{children}</div>
      {onClose && (
        <IconButton
          aria-label={formatTranslation(
            "minibag_dismiss_notification_btn_text"
          )}
          icon={styles.close}
          onClick={onClose}
          size="small"
        />
      )}
    </div>
  );
};

Notification.propTypes = {
  children: PropTypes.node.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  hasIcon: PropTypes.bool,
  type: PropTypes.oneOf(["alert", "error", "promotion", "success"]).isRequired,
  onClose: PropTypes.func
};

export default withTranslation(Notification);
