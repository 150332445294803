import { noticeError, addPageAction } from "@src/helpers/monitoring";

export const adobeDataLeakDebugger = s => {
  try {
    s.lastTrackingCallVars = {};

    s.registerPreTrackCallback(() => {
      s.lastTrackingCallVars = {};
      s.linkTrackVars.split(",").forEach(varKey => {
        s.lastTrackingCallVars[varKey] = s[varKey];
      });
    });

    window.addEventListener("load", () => {
      try {
        const trackingAlreadyFired = s.account && window["s_i_" + s.account];

        if (trackingAlreadyFired) {
          const currentValue = s.eVar9;
          const previousValue =
            s.lastTrackingCallVars && s.lastTrackingCallVars.eVar9;

          if (previousValue !== currentValue) {
            s.linkTrackEvents = "";
            s.linkTrackVars = s.apl(s.linkTrackVars, "eVar9", ",", 1);
            s.tl(true, "o", "experiment tracking");

            addPageAction("adobeLeakedData", {
              pageName: s.pageName,
              ...s.lastTrackingCallVars
            });
          }
        }
      } catch (error) {
        noticeError(error, { errorSource: "adobeDebugging" });
      }
    });
  } catch (error) {
    noticeError(error, { errorSource: "adobeDebugging" });
  }
};
