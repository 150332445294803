import sdkInstanceProvider from "@src/client/sdk/sdkInstanceProvider";

const getFeature = async featureName => {
  const featuresSdk = await sdkInstanceProvider("features");

  const { success } = await featuresSdk.onReady();

  if (!success) {
    return null;
  }

  return featuresSdk.getFeature(featureName);
};

export default getFeature;
