import { createElement, Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames/bind";
import styles from "./index.css";

const cx = classnames.bind(styles);

const Button = ({
  children,
  hasInvertedSize,
  href,
  theme,
  type,
  truncateText,
  ...rest
}) => {
  const tag = href ? "a" : "button";
  const className = cx("button", {
    button__hasInvertedSize: hasInvertedSize,
    button__isPrimary: theme === "primary",
    button__isSecondary: theme === "secondary",
    button__isTertiary: theme === "tertiary",
    button__isGreen: theme === "green"
  });

  const content = (
    <Fragment>
      <span
        className={cx("content", {
          content__truncateText: truncateText
        })}
      >
        {children}
      </span>
    </Fragment>
  );

  return createElement(tag, { ...rest, className, href, type }, content);
};

Button.propTypes = {
  children: PropTypes.node,
  hasInvertedSize: PropTypes.bool,
  href: PropTypes.string,
  theme: PropTypes.oneOf(["primary", "secondary", "tertiary", "green"]),
  type: PropTypes.string,
  truncateText: PropTypes.bool
};

Button.defaultProps = {
  type: "button",
  truncateText: false
};

export default Button;
