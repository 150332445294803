import PropTypes from "prop-types";
import { withTranslation } from "@template/components/translation";
import styles from "./index.css";
import GermanVATIncluded from "./GermanVATIncluded";
import USSalesTax from "./USSalesTax";

const SubTotal = ({ text, formatTranslation, countryCode, storeCode }) => (
  <div className={styles.subTotalContainer}>
    <p>
      {formatTranslation("minibag_subtotal_title")}
      <USSalesTax countryCode={countryCode} />
    </p>
    <div data-test-id="miniBagSubTotal">
      {text}
      <GermanVATIncluded storeCode={storeCode} />
    </div>
  </div>
);

SubTotal.defaultProps = {
  text: null
};

SubTotal.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  text: PropTypes.string,
  countryCode: PropTypes.string.isRequired,
  storeCode: PropTypes.string.isRequired
};

export default withTranslation(SubTotal);
