import { useEffect } from "react";

export const useEventListener = (eventName, callback) => {
  useEffect(() => {
    window.addEventListener(eventName, callback);
    return () => window.removeEventListener(eventName, callback);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
